import React from "react";
import {Link, useParams, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import countryFile from './Country';
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'
import checkNumber from './CheckMobileNumber';
import striptags from 'striptags';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default function CollegeDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [college, setCollege] = useState([])
    const [specialisation, setSpecialisation] = useState([])
	const [allspecialisations, setSpecialisations] = useState([])
    const [meta, setMeta] = useState('')
	const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
	const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
	const [mobile, setMobileValue] = useState([]);
	const [specs, setSpecs] = useState([]);

	const location = useLocation();
	//destructuring pathname from location
	const { pathname } = location;
	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");  

	function selectCountry (event) {
		setStateValue([])
		const state_arr = countryFile.s_a[event.key].split("|"); 
		const stateArray = [];
		stateArray.push({
		  value: '', 
		  label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
		  stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
		  });
		}
		setStates(stateArray)
		var Country = event.value;
		let check = checkNumber.checkNumber(Country,mobile);
		if(check === 2){
			setMobileValue('')
		}
	  }

	  function updateMobile(value){
		setMobileValue(value)
	  }
	  
	  function selectRegion (val) {
		if(val.value !== '' && val.value !== null){
		  setStateValue(val)
		} else {
		  setStateValue([])
		}
	  }

    useEffect(()=>{
        fetchProduct(params.id);
		const countryArray = [];
        // console.log(countryFile);
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
		// document.getElementsByClassName('.popup-youtube, .popup-vimeo').magnificPopup({
		// 	//disableOn: 700,
		// 	type: 'iframe',
		// 	mainClass: 'mfp-fade',
		// 	removalDelay: 100,
		// 	preloader: true,
		// 	fixedContentPos: false
		//   });
    },[params.id])
    const fetchProduct = async () => {
		setIsLoading(true)
        await axios.get(`/specialisation-page-data/${params.id}`).then(({data})=>{
			setSpecialisation(data.specialisation)
			setSpecialisations(data.specialisations)
			setSpecs(data.specs)
          if(data.specialisation.seo){
            setMeta(data.specialisation.seo)
          } else {
            setMeta('<meta/>')
          }
		  setIsLoading(false)
        }).catch(({response:{data}})=>{
			setIsLoading(false)
          Swal.fire({
            text:'Error!.',
            icon:"error"
          })
        })
      }

     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      function reviewDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = today.getMonth();
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ '-' +month+'-' +year;
      }

      function enquirySubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            jQuery('.EnquiryMobile').val('');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 202){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

      function submitReview(event){
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/post-college-review', formData)
        .then(({data})=>{
            event.target.reset()
			jQuery('#addReview').modal('hide');
            fetchProduct()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }
  return (
	!isLoading ? 
	college!==null?
    	<div>
            <Helmet>
				<titlte>{specialisation.title}</titlte>
				{parse(meta)}
				<link rel="stylesheet" href="/assets/css/specialisation-details.css"></link>
            </Helmet>
			<div className="single-courses-area">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 sidebar-left">
                            <div className="single-curses-contert">
                            <h2>{specialisation.category}</h2>
                            <div className="details-img-bxo">
                                <img src={`/storage/Specialisation/${specialisation.image}`} alt={specialisation.category} title={specialisation.category} className="img-responsive"/>
                            </div>
                            <div className="description-content">
                                <div dangerouslySetInnerHTML={{ __html: specialisation.description }} />
                                <hr/>
                                <div className="career-opp">
                                    <h3>Career opportunities</h3>
                                    <div id="viewMore" className="row">
                                    {specs && specs.length && specs.map((spec, index) => {
                                        return (
                                            <div class="col-xs-4 col-md-4 col-lg-2">
                                                <img class="career-logo img-responsive" src={`/storage/${spec.similar.logo}`}/>
                                            </div>
                                        );
                                    })}
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            </div>
                        </div>
                        <div className="col-sm-4 sidebar-right">
                            <div className="sidebar-content blog_1">
                            <div className="single-courses_v">
                                <div className="sidebar-content single-courses-area" style={{width:'100%',padding:'20px 0'}}>
                                    <div className="single-curses-contert">
                                        <div className="leave_a_comment">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3 className="text-center">Apply now</h3>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                    <form onSubmit={enquirySubmit} className="page-enquiry-form">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                <label className="input-label">name</label>
                                                                <input type="text" placeholder="Enter your name" id="name" name="name" required/>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                <label className="input-label">E-mail</label>
                                                                <input type="email" placeholder="Enter your E-mail" name="email" required/>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                <label className="input-label">Phone</label>
                                                                <input type="text" placeholder="Enter your Phone number" value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" name="phone" required/>
                                                                </div>
                                                                <div className="col-xs-12">
																	<label className="input-label">Country</label>
																	<Select classNamePrefix="CountrySelect"  options={countries} name="country" className="select-country"  placeholder="Select Country" required onChange={selectCountry} />
																</div>
																<div className="col-xs-12">
																	<label className="input-label">State</label>
																	<Select classNamePrefix="StateSelect" options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={selectRegion} />
																</div>
                                                                <div className="clearfix"></div>
                                                                <div className="col-sm-12">
                                                                <div className="text-center">
                                                                    <button type="submit">Submit</button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="row blog-area">
                                <div className="blog_side-right">
                                    <div className="col-sm-12 recent-post-01" style={{paddingTop:'15px'}}>
                                        <h3>Other Specialisations</h3>
                                        <div className="row">
                                        {allspecialisations && allspecialisations.length && allspecialisations.map((allspecialisation, index) => {
                                            return (
                                                <div className="col-sm-12 recent-single">
                                                    <div className="recent-content-item">
                                                        <div className="img-box">
                                                            <Link to={`/specialisations/${allspecialisation.slug}`}>
                                                                <img src={`/storage/Specialisation/${allspecialisation.image2}`} alt={allspecialisation.category} className="lozad" data-loaded="true"/>
                                                            </Link>
                                                        </div>
                                                        <div className="recent-text pull-right mt-20">
                                                            <Link to={`/specialisations/${allspecialisation.slug}`}>{allspecialisation.category}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	:
	<h1> Can't connect to server ! </h1>
	:
	<div className="row">
		<div className="col-md-12 text-center">
			<ThreeDots
			visible={true}
			height="50"
			width="50"
			color="#black"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{textAlign:'center', display:'block'}}
			wrapperclassName="loader"/>
		</div>
  </div>
  )
};

// export default NotificationDetails;