import React from "react";
import {Link, useParams, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import countryFile from './Country';
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'
import checkNumber from './CheckMobileNumber';
import striptags from 'striptags';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default function CollegeDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [college, setCollege] = useState([])
    const [exam, setExam] = useState([])
	  const [allexams, setExams] = useState([])
    const [meta, setMeta] = useState('')
	  const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
	  const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
	  const [mobile, setMobileValue] = useState([]);
	  const [specs, setSpecs] = useState([]);

	const location = useLocation();
	//destructuring pathname from location
	const { pathname } = location;
	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");  

	function selectCountry (event) {
		setStateValue([])
		const state_arr = countryFile.s_a[event.key].split("|"); 
		const stateArray = [];
		stateArray.push({
		  value: '', 
		  label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
		  stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
		  });
		}
		setStates(stateArray)
		var Country = event.value;
		let check = checkNumber.checkNumber(Country,mobile);
		if(check === 2){
			setMobileValue('')
		}
	  }

	  function updateMobile(value){
		setMobileValue(value)
	  }
	  
	  function selectRegion (val) {
		if(val.value !== '' && val.value !== null){
		  setStateValue(val)
		} else {
		  setStateValue([])
		}
	  }

    useEffect(()=>{
        fetchProduct(params.id);
		const countryArray = [];
        // console.log(countryFile);
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
		
		// document.getElementsByClassName('.popup-youtube, .popup-vimeo').magnificPopup({
		// 	//disableOn: 700,
		// 	type: 'iframe',
		// 	mainClass: 'mfp-fade',
		// 	removalDelay: 100,
		// 	preloader: true,
		// 	fixedContentPos: false
		//   });
    },[params.id])
    const fetchProduct = async () => {
		setIsLoading(true)
        await axios.get(`/exam-page-data/${params.id}`).then(({data})=>{
          setExam(data.exam)
			    setExams(data.exams)
          if(data.exam.seo){
            setMeta(data.exam.seo)
          } else {
            setMeta('<meta/>')
          }
		  setIsLoading(false)
        }).catch(({response:{data}})=>{
			setIsLoading(false)
          Swal.fire({
            text:'Error!.',
            icon:"error"
          })
        })
      }

     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      function reviewDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = today.getMonth();
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ '-' +month+'-' +year;
      }

      function enquirySubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            jQuery('.EnquiryMobile').val('');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

      function submitReview(event){
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/post-college-review', formData)
        .then(({data})=>{
            event.target.reset()
			jQuery('#addReview').modal('hide');
            fetchProduct()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }
  return (
	!isLoading ? 
	college!==null?
    	<div>
          <Helmet>
				      <titlte>{exam.exam_name}</titlte>
				      {parse(meta)}
				      <link rel="stylesheet" href="/assets/css/exam.css"></link>
          </Helmet>
          <div className="single-courses-area">
            <div className="container">
                <div className="row">
                  <div className="col-sm-8 sidebar-left">
                      <div className="single-curses-contert">
                        <h2>{exam.exam_name}</h2>
                        <div className="details-img-bxo">
                            <img src={`/storage/Entrance_Exam/${exam.image}`} alt="" className="img-responsive"/>
                        </div>
                        <div className="description-content">
                            <div dangerouslySetInnerHTML={{ __html: exam.exam_details }} />
                        </div>
                        <hr/>
                        <div className="leave_a_comment">
                            <div className="row">
                              <div className="col-sm-12">
                                  <h3>Apply Now</h3>
                                  <div className="row">
                                    <div className="col-sm-12">
                                        <form onSubmit={enquirySubmit}>
                                          <div className="form-group">
                                              <div className="row">
                                                <div className="col-sm-6">
                                                    <label className="input-label">name</label>
                                                    <input type="text" placeholder="Enter your name" id="name" name="name" required/>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="input-label">E-mail</label>
                                                    <input type="email" placeholder="Enter your E-mail" name="email" required/>
                                                </div>
                                                <div className="col-sm-12">
                                                    <label className="input-label">Phone</label>
                                                    <input type="text" placeholder="Enter your Phone number" name="phone" required/>
                                                </div>
                                                <input type="hidden" name="country" value="India"/>
                                                <input type="hidden" name="state" value="Karnataka"/>
                                                <input type="hidden" name="specialisation" value="MBA"/>
                                                <div className="col-sm-12">
                                                    <label className="input-label">Message</label>
                                                    <textarea placeholder="Type your comment"  name="message" required></textarea>
                                                </div>
                                                <div className="col-sm-12">
                                                    <button type="submit">Submit</button>
                                                </div>
                                              </div>
                                          </div>
                                        </form>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-4 sidebar-right">
                      <div className="sidebar-content blog_1">
                        <div className="row blog-area">
                            <div className="blog_side-right">
                              <div className="col-sm-12 recent-post-01" style={{paddingTop:'15px'}}>
                                  <h3>Related Exams</h3>
                                  <div className="row">
                                  {allexams && allexams.length > 0 && allexams.map((allexam, index) => {
                                    return (
                                      <div className="col-sm-12 recent-single" key={`car${index}`}>
                                          <div className="recent-content-item">
                                            <div className="img-box">
                                              <Link href={`/exams/${allexam.slug}`}>
                                                <img src={`/storage/Entrance_Exam/${allexam.image2}`} alt=""/>
                                              </Link>
                                            </div>
                                            <div className="recent-text pull-right mt-30">
                                                <Link to={`/exams/${allexam.slug}`}>{allexam.exam_name}</Link>
                                            </div>
                                          </div>
                                      </div>
                                    );
                                  })}
                                  </div>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
		</div>
	:
	<h1> Can't connect to server ! </h1>
	:
	<div className="row">
		<div className="col-md-12 text-center">
			<ThreeDots
			visible={true}
			height="50"
			width="50"
			color="#black"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{textAlign:'center', display:'block'}}
			wrapperclassName="loader"/>
		</div>
  </div>
  )
};

// export default NotificationDetails;