import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';

export default class About extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          reviews:[]
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/about-page-data')
                      .then(res=>{
                          this.setState({
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates
                          },()=>{
                            // console.log(this.state)
                          })
                      })
                      .catch(err=>{
                          console.log(err);
                      })
      }

    render(){
        const {notifications}=this.state;
        const {reviews}=this.state;
        return (
          <div>
            <Helmet>
                <title>List of Colleges Offering Direct Admission for MBA in Bangalore</title>
                <meta name="description" content="Get the best list of Top Management Colleges in Bangalore. Choose your College & Apply for Direct MBA Admission in Bangalore MBA Colleges. Contact us!"/>
                <meta name="keywords" content="mba colleges, mba colleges in Bangalore, mba admission in top colleges, top mba colleges & specialisations, best management colleges in bangalore, Best business schools in Bangalore, list of mba colleges in Bangalore, ranking of business schools in bangalore, bangalore top mba colleges"/>
                {/* <link rel="stylesheet" href="/assets/css/About.css"></link> */}
            </Helmet>
            <section className="courses-02">
                <div className="container">
                    <div className="row courses-r-margin-bottom">
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box" style={{minHeight:'238px'}}>
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/ifim">
                                    <div className="figure-img">
                                        <img src="https://www.galaxyeduworld.com/storage/college_page/5dc3d8f2414d6_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/ifim">IFIM College</a></h3>
                                    <p className="data-comment"> Electronic City Phase I, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box" style={{minHeight:'238px'}}>
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/acharya-institute-of-management-and-sciences">
                                    <div className="figure-img">
                                        <img src="https://www.galaxyeduworld.com/storage/college_page/5dc14464d9139_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/acharya-institute-of-management-and-sciences">AIMS Institutes</a></h3>
                                    <p className="data-comment"> Peenya, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box" style={{minHeight:'238px'}}>
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/dayananda-sagar-university">
                                    <div className="figure-img">
                                        <img src="https://www.galaxyeduworld.com/storage/college_page/5db9940faa2cb_1.JPG" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/dayananda-sagar-university">Dayananda Sagar University</a></h3>
                                    <p className="data-comment"> Kumaraswamy Layout, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/acharya-institute-of-technology">
                                    <div className="figure-img">
                                        <img src="https://www.galaxyeduworld.com/storage/college_page/5dbff13de3e4b_1.JPG" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/acharya-institute-of-technology">Acharya Institute of Technology</a></h3>
                                    <p className="data-comment"> Soladevanahalli, Karnataka</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/new-horizon-college-of-engineering">
                                    <div className="figure-img">
                                        <img src="https://www.galaxyeduworld.com/storage/college_page/5dc023a65b4c7_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/new-horizon-college-of-engineering">New Horizon College</a></h3>
                                    <p className="data-comment"> Marathahalli, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/krupanidhi-group-of-institutions">
                                    <div className="figure-img">
                                        <img src="https://mbaadmissionsinbangalore.com/storage/Colleges/5cdd67a369715_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/krupanidhi-group-of-institutions">Krupanidhi College</a></h3>
                                    <p className="data-comment"> Off Sarjapur Road, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div> */}
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/presidency-university">
                                    <div className="figure-img">
                                        <img src="/storage/colleges/Presidency%20University%201.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/presidency-university">Presidency University</a></h3>
                                    <p className="data-comment"> Yelahanka, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/cmr-university">
                                    <div className="figure-img">
                                        <img src="https://www.galaxyeduworld.com/storage/college_page/5dc171f97e714_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/cmr-university">CMR University</a></h3>
                                    <p className="data-comment"> Off Hennur - Bagalur Main Road, Bangalore</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/east-point-college-of-higher-education">
                                    <div className="figure-img">
                                        <img src="https://www.galaxyeduworld.com/storage/college_page/5dd23ea6bd5d4_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/east-point-college-of-higher-education">East Point College of Higher Education</a></h3>
                                    <p className="data-comment"> Avalahalli, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/t-john-college">
                                    <div className="figure-img">
                                        <img src="https://www.galaxyeduworld.com/storage/college_page/5dc176b3616ad_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/t-john-college">T John College</a></h3>
                                    <p className="data-comment"> Bannerghatta Main Road, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/isbr-business-school-bangalore">
                                    <div className="figure-img">
                                        <img src="/assets/images/isbr-college_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/isbr-business-school-bangalore">ISBR</a></h3>
                                    <p className="data-comment"> Electronics City, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/christ-academy-institute-for-advanced-studies">
                                    <div className="figure-img">
                                        <img src="/assets/images/christ_academy_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/christ-academy-institute-for-advanced-studies">Christ Academy</a></h3>
                                    <p className="data-comment"> Christ Nagar, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box">
                            <div className="single-courses">
                            <figure>
                                <a href="https://galaxyeduworld.com/college/brindavan-college-ug-pg">
                                    <div className="figure-img">
                                        <img src="/assets/images/brindavan_college_1.jpg" alt="" className="lozad img-responsive"/>
                                    </div>
                                </a>
                                <figcaption>
                                    <ul className="list-unstyled">
                                        <li className="rating">
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        <span><i className="fa fa-star-o"></i></span>
                                        </li>
                                    </ul>
                                </figcaption>
                            </figure>
                            <div className="courses-content-box">
                                <div className="courses-content">
                                    <h3><a href="https://galaxyeduworld.com/college/brindavan-college-ug-pg">Brindavan College</a></h3>
                                    <p className="data-comment"> Bagalur Main Road, Bengaluru</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        );
    }
}

// export default Home;