import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, useParams, Switch } from 'react-router-dom'; 
import { useState, useEffect } from 'react';

import Home from "./pages/Home";
import About from "./pages/About";
import Colleges from "./pages/Colleges";
import CollegeDetails from './pages/CollegeDetails';
import ContactUs from "./pages/ContactUs";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import NotificationDetails from "./pages/NotificationDetails";
import ProgramDetails from './pages/ProgramDetails';
import Syllabus from './pages/Syllabus';
import Test from './pages/Test';
import PageNotFound from './pages/PageNotFound';
import Specialisations from './pages/Specialisatinos';
import SpecialisationDetails from './pages/SpecialisationDetails';
import Exam from './pages/ExamDetails';
import FAQ from './pages/FAQs';
import ListOfColleges from './pages/ListOfColleges';
import GalaxyReview from './pages/GalaxyReview';
import Differences from './pages/Differences';

import Navbar from "./Navbar";
import Footer from "./Footer";
import ScrollToTop from "./ScrollTop";


function MyApp() {

    return (
        <>
        <ScrollToTop></ScrollToTop>
        <Navbar />
        <Routes >
                <Route path="*" element={<PageNotFound />}/>
                <Route path="/" element={<Home /> } />
                <Route path="/about-us" element={<About /> } />
                <Route path="/best-mba-colleges-in-bangalore" element={<Colleges /> } />
                <Route path="/colleges/:id" element={<CollegeDetails /> } />
                <Route path="/blogs" element={<Blogs /> } />
                <Route path="/blogs/:id" element={<BlogDetails /> } />
                <Route path="/notifications/:id" element={<NotificationDetails /> } />
                <Route path="/programs/:id" element={<ProgramDetails /> } />
                <Route path="/contact" element={<ContactUs /> } />
                <Route path="/mba-specialisations" element={<Specialisations /> } />
                <Route path="/specialisations/:id" element={<SpecialisationDetails /> } />
                <Route path="/exams/:id" element={<Exam /> } />
                <Route path="/faq" element={<FAQ /> } />
                <Route path="/list-of-colleges-offering-direct-admission-for-mba-in-bangalore" element={<ListOfColleges /> } />
                <Route path="/galaxy-educational-services-reviews" element={<GalaxyReview /> } />
                <Route path="/differences" element={<Differences /> } />
                <Route path="/test" element={<Test /> } />
        </Routes>
        <Footer/>
        </>
    );
}

if (document.getElementById('page')) {
    ReactDOM.render(
        <BrowserRouter>
            <MyApp />
        </BrowserRouter>
            , document.getElementById('page'));
}
// if (document.getElementById('app')) {
//     ReactDOM.render(<MyApp />, document.getElementById('app'));
// }