import React from "react";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

function Footer() {
    const now = new Date();
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className=" col-sm-12 footer-content-box">
                        <div className="col-sm-3 hidden-xs">
                            <img className="img-responsive footer-logo" src="/assets/images/logo.png" alt=""/>
                            <h3>MBA STUDY IN BANGALORE</h3>
                            <p>The Master of Business Administration (MBA) is an internationally-recognized degree designed to develop the skills required for careers in business and management. The value of the MBA, however, is not limited strictly to the business world.</p>
                        </div>
                        
                        <div className="col-sm-3">
                            <h3>LINKS</h3>
                            <ul className="list-unstyled" style={{marginBottom: '20px'}}>
                                <li><Link to="/"><i className="fa fa-angle-right footer-icon"></i> MBA admissions in Bangalore 2023-24</Link></li>
                                <li><Link to="/about-us"><i className="fa fa-angle-right footer-icon"></i> Direct admission in MBA</Link></li>
                                <li><Link to="/best-mba-colleges-in-bangalore"><i className="fa fa-angle-right footer-icon"></i> Top MBA colleges in Bangalore</Link></li>
                                <li><Link to="/mba-specialisations"><i className="fa fa-angle-right footer-icon"></i> MBA Specialisations</Link></li>
                                <li><HashLink smooth to="/#scope"><i className="fa fa-angle-right footer-icon"></i> Career Scope MBA</HashLink></li>
                                <li><Link to="/blogs"><i className="fa fa-angle-right footer-icon"></i> Blogs</Link></li>
                                <li><Link to="/contact"><i className="fa fa-angle-right footer-icon"></i> Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-3">
                            <h3>CONTACT US</h3>
                            <ul className="list-unstyled footer-info" style={{marginBottom: '20px'}}>
                                <li><span><i className="fa fa-fw fa-map-marker footer-icon"></i></span> No. 309, 3rd Floor, Brigade Gardens, Church Street, MG Road, Bangalore, Karnataka, India - 560001</li>
                                <li><span><i className="fa fa-fw fa-phone footer-icon"></i></span> <a href="tel:+918095797575"> +918095797575</a></li>
                                <li><span><i className="fa fa-fw fa-envelope footer-icon"></i></span> <a href="mailto:info@mbaadmissionsinbangalore.com">info@mbaadmissionsinbangalore...</a></li>
                            </ul>
                        </div>
                        
                        <div className="col-sm-3">
                            <h3>FOLLOW US</h3>
                            <ul className="list-unstyled follow-us">
                                <li><a href="https://www.facebook.com/mbastudyinbangalore/" target="_blank" title="Facebook"><i className="fa fa-facebook-square teacher-icon"></i></a></li>
                                <li><a href="" target="_blank" title="Twitter"><i className="fa fa-twitter teacher-icon"></i></a></li>
                                <li><a href="https://instagram.com/studyinbangalorecolleges" target="_blank" title="Instagram"><i className="fa fa-instagram teacher-icon"></i></a></li>
                                <li><a href="https://youtube.com/channel/UChVSKwSYdV_Q48YM8lTmwdw" target="_blank" title="YouTube"><i className="fa fa-youtube-play teacher-icon"></i></a></li>
                            </ul>
                        </div>	
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-bottom-inner">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 footer-no-padding text-center">
                                <p>Copyright &copy; {new Date().getFullYear()} | All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;