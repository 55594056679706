import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import { HashLink } from 'react-router-hash-link';

export default class ContactUs extends Component {
    
    constructor(){
        super();
        this.state={
          blogs:[],
          exams:[],
		  programs:[]
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        let usersData=await axios.get('/contact-page-data')
                        .then(res=>{
                          this.setState({
                            blogs:res.data.blogs,
                            exams:res.data.exams,
							programs:res.data.programs
                          },()=>{
                            // console.log(this.state)
                          })
                        })
                        .catch(err=>{
                          console.log(err);
                        })
      }

	  blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      contactSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
		jQuery("#overlay").fadeIn(300);　
        axios.post('/store-contact-enuiry', formData)
        .then(({data})=>{
            event.target.reset()
			jQuery('.EnquiryMobile').val('');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
			} else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

    render(){
        const {blogs, programs, exams}=this.state;
        return (
          <div>
            <Helmet>
                <title>Which is the Best MBA College in Bangalore | Apply now - Contact us</title>
                <meta name="description" content="Contact us for direct MBA Admission in Bangalore 2021 in Top ranked MBA Colleges in Bangalore. Make a Call on +919916505050."/>
				<meta name="keywords" content="mba admissions in Bangalore, direct admission in top colleges, direct admission for mba  in Bangalore, best mba colleges in Bangalore, top ranked mba colleges in bangalore"/>
				<link rel="stylesheet" href="/assets/css/contact.css"></link>
			</Helmet>
            <section className="contact-area-02">
				<div className="container">
					<div className="row">
						<div className="col-sm-7 contact-info">
							<div className="contact-form">
							<div className="row">
								<div className="col-sm-12 contact-title-btm">
									<h2>Request for a callback</h2>
								</div>
							</div>
							<div className="input-contact-form">
							<div id="contact">
							<form onSubmit={this.contactSubmit}>
								<div className="row">
									<div className="col-sm-12">
										<div className="form-group">
											<input type="text" className="form-control" name="name" placeholder="Name" required/>
										</div>
									</div>
									<div className="col-sm-12">
										<div className="form-group">
											<input type="email" className="form-control" name="email" placeholder="Email" required/>
										</div>
									</div>
									<div className="col-sm-12">
										<div className="form-group">
											<input type="text" className="form-control" name="phone" placeholder="Phone" required/>
										</div>
									</div>  
									<div className="col-sm-12">
										<div className="form-group">
											<input type="text" className="form-control" name="place" placeholder="Place" required/>
										</div>
									</div> 
									<div className="col-sm-12">
										<div className="form-group">
											<textarea className="form-control" rows="3" placeholder="Write your message" name="messages" required></textarea>
										</div>
									</div>  
									<div className="col-sm-12"> 
										<div className="full-width text-center">
											<input value="Submit" type="submit"/>
										</div>
									</div>  
								</div>
							</form>
					</div>                    
							</div>
						</div>
							<div className="contact-title">
								<h2>Contact Info</h2>
							</div>
							<div className="col-sm-12 contact-box">
								<div className="row">
									<div className="col-sm-12 col-md-6 single-address-box">
										<div className="single-address">
											<i className="fa fa-phone"></i>
											<h4>Phone</h4>
											<p>+91 8095797575</p>
										</div>
									</div>  
									<div className="col-sm-12 col-md-6  single-address-box">
										<div className="single-address">
											<i className="fa fa-envelope"></i>
											<h4>Email</h4>
											<p>info@mbaadmissionsinbangalore.com</p>
										</div>
									</div> 
									<div className="col-sm-12 col-md-12 single-address-box">
										<div className="single-address">
											<i className="fa fa-map-marker"></i>
											<h4>Location:</h4>
											<p>No. 309, 3rd Floor, Brigade Gardens, Church Street, MG Road, Bangalore, Karnataka, India - 560001</p>
										</div>
										<hr/>
									</div> 
									
					
								<div className="col-sm-12">
									<div className="map">
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124414.58127439687!2d77.53566825892014!3d12.9746875336382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae176934eef06f%3A0xf570b7d977167ba0!2sMBA%20Colleges%20in%20Bangalore%20-%20MBA%20Admissions%20in%20Bangalore!5e0!3m2!1sen!2sin!4v1594125044882!5m2!1sen!2sin" width="600" height="450" frameBorder="0" style={{border:'0'}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
									</div>
								</div>
								</div>
							</div>                          	                        
						</div>	
						
						<div className="col-sm-4 col-sm-offset-1">
							<div className="sidebar-text-post">
								<div className="">
									<div className="event-01">
									<div className="events-list-03">
									<div className="events-single-box examBox">
										<div className="row">
											<div className="col-sm-12 section-header-box" style={{marginBottom: 0}}>
												<div className="section-header section-header-l">
													<h3>MBA ENTRANCE EXAMS</h3>
												</div>
											</div>
										</div>
										<div className="event-info">
											<ul className="exam-icon-list list-unstyled">
											{exams && exams.length > 0 && exams.map((exam, index) => {
                        						return (
													<li><Link to={`/exams/${exam.slug}`}>{exam.exam_name}</Link></li>
												);
											})}
											</ul>
										</div>
									</div>
									</div>
									</div>
									<div className="textBox" style={{background:'#fdb60e'}}>
										<HashLink smooth to="/#scholarships-for-mba"><p>SCHOLARSHIPS <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></p></HashLink>
									</div>
									<div className="textBox">
										<Link to="/mba-specialisations"><p>TRENDING MBA SPECIALISATIONS <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></p></Link>
									</div>
									<div className="blog_1">
									<div className="blog-area">
									<div className="blog_side-right">
									<div className="recent-post-01">
										<div className="row">
											<div className="col-sm-12 section-header-box">
												<div className="section-header section-header-l">
													<h3>BLOGS</h3>
												</div>
											</div>
										</div>
										<div className="row">
										{blogs && blogs.length > 0 && blogs.map((blog, index) => {
                        					return (
												<div className="col-sm-12 recent-single">
													<div className="recent-content-item">
														<div className="img-box">
															<Link to={`/blogs/${blog.slug}`}>
																<img className="lozad" src={`/storage/blogs/${blog.image2}`} alt={blog.title} data-loaded="true"/>
															</Link>
														</div>
														<div className="recent-text pull-right">
															<Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
															<p>{this.blogDate(new Date(blog.updated_at))}</p>
														</div>
													</div>
												</div>
											);
										})}
										</div>
									</div>
									</div>
									</div>
									</div>
									<div className="event-01">
									<div className="events-list-03">
									<div className="events-single-box examBox">
										<div className="row">
											<div className="col-sm-12 section-header-box" style={{marginBottom: '0'}}>
												<div className="section-header section-header-l">
													<h3>MBA PROGRAMS IN BANGALORE</h3>
												</div>
											</div>
										</div>
										<div className="event-info">
											<ul className="exam-icon-list list-unstyled" style={{maxHeight: '600px'}}>
											{programs && programs.length > 0 && programs.map((program, index) => {
												return (
												<li><Link to={`/programs/${program.slug}`}>{program.program}</Link></li>
												);
											})}
											</ul>
										</div>
									</div>
									</div>
									</div>
									
									</div>
								</div>
						</div>																
					</div>
				</div>
			</section>
        </div>
        );
    }
}

// export default Home;