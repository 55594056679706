import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { HashLink } from 'react-router-hash-link';
import { Swiper, SwiperSlide } from 'swiper/react';
import Helmet from 'react-helmet';
import { Audio } from 'react-loader-spinner'
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import LoaderJS from 'loadjs';

export default class Home extends Component {
    
    constructor(){
        super();
        this.state={
          programs:[],
          mbacolleges:[],
          specialisations:[],
          national:[],
          examState:[],
          institutions:[],
          notifications:[],
          blogs:[],
          isActive:false,

          courses:[],
          countries: [], 
          trendingCourses:[],
          states: [],
          stateValue:'',
          mobile:''
        }
      }

      toggleClass = () => {
        if(this.state.isActive === false){
          this.setState({isActive:true});
        } else {
          this.setState({isActive:false});
          document.getElementById("exams").scrollIntoView({
            behavior: 'smooth'
        });
        }
        // console.log(this.state.isActive);
        // this.setState(!isActive);
      };

      customStyles = () => {
        control: base => ({
          height: 35,
          minHeight: 35
        })
      }

      selectCountry = (event) => {
          const selectedCountryLabel = event.target.value;
          
          this.setState({ 
            stateValue: '',
          });
        
          const selectedCountry = this.state.countries.find(country => country.label === selectedCountryLabel);
        
          if (selectedCountry) {
            const state_arr = countryFile.s_a[selectedCountry.key]?.split("|") || [];
            const stateArray = [];
        
            state_arr.forEach(state => {
              stateArray.push({ value: state, label: state });
            });
        
            this.setState({ 
              states: stateArray 
            });
        
            let check = checkNumber.checkNumber(selectedCountryLabel, this.state.mobile);
            if (check === 2) {
              this.setState({ mobile: '' });
            }
          }
        };
        
        selectRegion = (event) => {
          const selectedStateValue = event.target.value;
          this.setState({ 
            stateValue: { 
              value: selectedStateValue, 
              label: selectedStateValue 
            } 
          });
        };

      
        updateMobile = (value) => {
          this.setState({mobile:value});
        }
     
      async componentDidMount() {
        const countryArray = [];
        var country1 = countryFile.country_arr;
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
        this.getList();
   
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/home-page-data')
            .then(res=>{
                this.setState({
                    notifications:res.data.notifications,
                    blogs:res.data.blogs,
                    // trendingCourses:res.data.trendingcourses,
                    examState:res.data.statesLevel,
                    national:res.data.national,
                    specialisations:res.data.specialisations,
                    programs:res.data.programs,
                    mbacolleges:res.data.mbacolleges,
                    institutions:res.data.institution
                    // colleges:res.data.colleges
                },()=>{
                    // console.log(this.state.examState)
                })
            })
            .catch(err=>{
                console.log(err);
            })
      }

      resetForm(){
        document.getElementById('home-page-middle-form').reset();
      }

      handleSubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            jQuery('.EnquiryMobile').val('');
            jQuery('#enquiry').modal('hide');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

    render(){
        const {programs, mbacolleges, specialisations, national, institutions}=this.state;
        const {examState, notifications, isActive}=this.state;

        const {blogs}=this.state;
        const {courses}=this.state;
        const {colleges}=this.state;
        const {trendingCourses}=this.state;
        const { countries, states, stateValue , mobile} = this.state;
        return (
          <div>
            <Helmet>
                <title>Direct MBA Admission in Bangalore 2024-25 | Study in Top MBA Colleges Bangalore</title> 
                <meta name="description" content="List of Top ranked MBA Colleges in Bangalore with low fees, admission process, MBA & PGDM course. Get Direct MBA Admission in Top 10 Colleges Bangalore 2024-25."/>
                <meta name="keywords" content="mba admissions in bangalore, direct admission for mba in bangalore, mba admission in bangalore 2021, mba admission process in bangalore, mba colleges in bangalore without entrance exam,selection process for mba in bangalore, top management colleges in bangalore, ist of top mba colleges in bangalore in 2024"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta itemprop="name" content="MBA Study"/>
                <meta itemprop="description" content="Check the List of Top ranked MBA Colleges in Bangalore with low fees, admission process, MBA & PGDM course. Get Direct MBA Admission for 2024-25 in Top 10 Colleges Bangalore."/>
                <meta itemprop="image" content="/assets/images/logo.png"/>
                <meta property="og:type" content="article" />
                <meta property="og:image" content="/assets/images/logo.png" />
                <meta property="og:image:secure_url" content="/assets/images/logo.png" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="MBA Study" />

                <meta property="og:url" content="https://www.mbaadmissionsinbangalore.com/"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Direct MBA Admission in Bangalore 2024-25 | Study in Top MBA Colleges Bangalore"/>
                <meta property="og:description" content="Check the List of Top ranked MBA Colleges in Bangalore with low fees, admission process, MBA & PGDM course. Get Direct MBA Admission for 2024-25 in Top 10 Colleges Bangalore."/>
                <meta property="og:image" content="/assets/images/logo.png"/>
   
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content="mbaadmissionsinbangalore.com"/>
                <meta property="twitter:url" content="https://www.mbaadmissionsinbangalore.com/"/>
                <meta name="twitter:title" content="Direct MBA Admission in Bangalore 2024-25 | Study in Top MBA Colleges Bangalore"/>
                <meta name="twitter:description" content="Check the List of Top ranked MBA Colleges in Bangalore with low fees, admission process, MBA & PGDM course. Get Direct MBA Admission for 2024-25 in Top 10 Colleges Bangalore."/>
                <meta name="twitter:image" content="/assets/images/logo.png"/>
            </Helmet>
            <section className="slider-content-bottom">
              <div className="container">
                <div className="row sider-btm-row-inner">
                  <div className="col-sm-4 content_body slide_cont_box_01">
                    <Link to="/best-mba-colleges-in-bangalore">
                      <div className="slider-btm-box btm-item_01">
                        <h3>MBA Colleges</h3>
                        <Link to="/best-mba-colleges-in-bangalore">View list<i className="fa fa-angle-right slider-b-btn-icon"></i></Link>
                      </div>
                    </Link>
                  </div>
            
                  <div className="col-sm-4 content_body slide_cont_box_02">
                    <div className="slider-btm-box btm-item_02">
                      <HashLink smooth to="/#exams"><h3>MBA Entrance Exams</h3></HashLink>
                      <Link to="/differences">Entrance Exam differences<i className="fa fa-angle-right slider-b-btn-icon"></i></Link>
                    </div>
                  </div>
            
                  <div className="col-sm-4 content_body slide_cont_box_03">
                    <div className="slider-btm-box btm-item_03">
                      <Link to="/best-mba-colleges-in-bangalore"><h3>Review & Rating of MBA Colleges in Bangalore</h3></Link>
                    </div>
                  </div>
            
                </div>
              </div>
            </section>	
            <section className="Welcome-area">
              <div className="container">
                  <div className="row">
                    <div className="col-sm-12 Welcome-area-text  wow fadeInUp" style={{visibility: 'visible', animationName: 'fadeInUp'}}>
                        <div className="row">
                          <div className="col-sm-12 section-header-box">
                              <div className="section-header section-header-l">
                                <h2>Why choose MBA?</h2>
                              </div>
                          </div>
                        </div>
                        <p>A Master of Business Administration (MBA) course is an internationally recognized two- years postgraduate degree that is important to develop experience, networks, professional skills like leadership, verbal communication and teamwork and personal skills like time management, hard work, confidence, analytical reasoning and problem-solving skills useful in business and management both in India and abroad. The MBA degree if done in <b>top management colleges in Bangalore</b> will always help the students to build a better future in the management field. Hence, it is always advisable for the aspiring students to join the course in any of the <b>top-ranking MBA colleges in Bangalore</b>.
                        </p>
                        <p>The MBA degree course includes many skill-based programs like internships, workshops, seminars, guest lectures and corporate discussions with industry experts which help to encourage and promote communication, discussions and networking. MBA graduates have an opportunity to constantly build their credibility, public image and reputation so that they can grow into expert leaders with an excellent professional reputation. They have a significant role in the community with the ability to positively influence their colleagues and the society.
                        </p>
                        <p>MBA graduates from <b>top management colleges in Bangalore</b> can have better and multiple opportunities to grow from an employee to an employer – from an Associate in an organization, an Entrepreneur at a new start-up, Manager in a company, to a Business Owner or CEO. Fresh graduates can apply to multiple job roles – both national and international and accelerate and build their careers where they can learn different working styles, experiment with different job roles and earn a competitive and high salary package.
                        </p>
                        <p>The MBA course is flexible with full-time, part-time and executive programs without any age limit. The MBA course curriculum has a wide scope with unique specializations like Aviation Business Management, Banking Finance and Insurance, Business Management, Digital Marketing, Healthcare Analytics, Healthcare Management, Hospitality Management, Human Resources Management, International Business, Lean Operations and Systems, Logistics and Supply Chain Management, Luxury Management, Operations Management, Pharma Business Management, Sports Management, Startups and SMEs Management, etc. The course is important to gain skills needed to adapt to the dynamic global changes in different business sectors.
                        </p>
                        <div className="row">
                          <div className="col-sm-12 section-header-box">
                              <div className="section-header section-header-l">
                                <h2>MBA Programs in Bangalore</h2>
                              </div>
                          </div>
                        </div>
                        <p>MBA Programmes have different specialisations and some of the top management programmes in the <b>top MBA colleges in Bangalore</b> are:</p>
                        <div className="row text-left mbaList">
                        {programs && programs.length > 0 && programs.map((program, index) => {
                          return (
                            <div className="col-sm-4" key={`p${index}`}>
                                <ul className="exam-icon-list list-unstyled">
                                  {program && program.length > 0 && program.map((program1, index2) => {
                                    return (
                                      <li  key={`p1${index2}`}><Link to={`/programs/${program1.slug}`}>{program1.program}</Link></li>
                                    )
                                  })}
                                </ul>
                            </div>
                          )
                        })}
                        </div>
                        <p style={{fontSize:'18px',color:'#000',display:'inline-block',borderBottom:'1px dashed #03b044',paddingBottom: '5px',marginBottom: '20px',textAlign: 'left'}}><b>Duration</b></p>
                        <p style={{marginBottom: '15px'}}>The MBA courses are offered in different modes such as Regular MBA, Executive MBA and Part-time MBA. The regular MBA courses offered under Bangalore University, VTU, Deemed Universities and Private Universities are for 2 years. The Executive MBA is offered for 1-year for the working professionals and the Part-Time MBA is offered for 1 to 1.5 Years.</p>
                        <p style={{fontSize:'18px',color:'#000',display:'inline-block',borderBottom:'1px dashed #03b044',paddingBottom: '5px',marginBottom: '20px',textAlign: 'left'}}><b>Eligibility criteria for MBA Direct Admission 
                          </b></p>
                        <p>The eligibility criteria for <b>top MBA colleges in Bangalore</b> are:</p>
                        <ol className="eligMBA text-left" style={{paddingLeft: '20px'}}>
                          <li>The aspiring students should have completed 3-years/ 4-years Bachelor's Degree from a recognized university with minimum 50% aggregate (or equivalent CGPA) and 45% marks in case of Karnataka ST/SC/Cat-I candidates.</li>
                          <li>Reputed colleges prefer candidates who have qualified in any one of the entrance exams valid in the respective colleges in Karnataka.</li>
                          <li>Some colleges prefer candidates for admission with work experience.</li>
                          <li>The students can even take <b>direct admissions to MBA in Bangalore</b> to the <b>top business schools in Bangalore</b> by contacting us.</li>
                        </ol>
                        <p>Get the complete details of top MBA colleges in Bangalore, Eligibility criteria for MBA, ranking and placement of each college, a range of specialisation subjects and its scope and much more.</p>
                        <div className="hidden-xs">
                          <div className="container">
                              <div className="row inspiration-full-box">
                                <div className="col-md-12">
                                    <div className="text-center">
                                      <a className="btnPGDM" href="https://www.pgdmcollegesinbangalore.com" target="_blank">Click to know about PGDM Programs in Bangalore</a>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </section>
            <div className="home_version_02" id="scholarships-for-mba">
              <section className="about-area">
                <div className="overlay-bg">
                  <div className="container post-1">
                    <div className="row post_left-side">
                      <div className="col-sm-8 description-content eligibility wow fadeIn" style={{visibility: 'visible', animationName: 'fadeIn'}}>
                          <div className="row">
                            <div className="col-sm-5">
                              <div className="section-header-box">
                              <div className="section-header section-header-l">
                                <h2>Scholarships for MBA</h2>
                              </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <p style={{fontSize:'15px',display:'inline-block',borderBottom:'1px dashed #03b044'}}><b>Types of Scholarships</b></p>
                          <p>An MBA scholarship helps to ease the financial burden of candidates who want to pursue an MBA from a prestigious university. These MBA scholarships are available for Indian students who wish to pursue their dream of studying in top MBA universities and B-Schools in India and abroad.
                          </p>
                          <p>Different types of MBA scholarships and financial aid are offered to Indian students who wish to pursue MBA in India and abroad:
                          </p>
                          <div className="text-left">
                            <div className="">
                              <ul>
                                <li><strong>Merit-based Scholarships</strong> &ndash; based on excellent academic record</li>
                                <li><strong>Merit-cum-Aptitude-based Scholarships</strong> &ndash; based on excellent academic record and individual aptitude</li>
                                <li><strong>Need-based Scholarships and Fellowships</strong> &ndash; usually for candidates from low income families</li>
                                <li><strong>Gender-based Scholarships</strong> &ndash; usually for female students</li>
                                <li><strong>External Scholarships</strong> &ndash; sponsored by renowned companies and organizations</li>
                                <li><strong>International Scholarships</strong> &ndash; provided by international MBA universities</li>
                                <li><strong>National Scholarships</strong> &ndash; provided by national MBA universities</li>
                                <li><strong>State Scholarships</strong> &ndash; provided by the state government</li>
                                <li><strong>Private Scholarships</strong> &ndash; provided by private MBA universities</li>
                                <li><strong>Fee Waivers</strong> &ndash; usually a specific percentage, from 5% to 100%, of the total fees is waived off</li>
                                <li><strong>Teaching Assistantship</strong> &ndash; through part-time teaching jobs in the university</li>
                                <li><strong>Summer Fellowships</strong> &ndash; summer internship opportunities to obtain practical exposure and income</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
				      </section>		
            </div>
            <section className="scholarshipFAQs">
              <div className="container">
                  <div className="row ">
                    <div className="col-sm-12 scholarshipFAQsCont">
                        <div className="FAQsSec single-courses_v">
                          <div className="single-courses-area" style={{padding:0}}>
                              <div className="single-curses-contert">
                                <div className="curriculum-text-box">
                                    <div className="curriculum-section">
                                      <div className="panel-group" id="accordion1">
                                          <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                  <a data-toggle="collapse" data-parent="#accordion1" href="#schoFAQ1">
                                                  HOW TO AVAIL SCHOLARSHIPS?</a>
                                                </p>
                                            </div>
                                            <div id="schoFAQ1" className="panel-collapse collapse in">
                                                <div className="panel-body">
                                                  <p>It is important to begin research about scholarship application dates and deadlines at the earliest. Steps to avail a scholarship are:
                                                  </p>
                                                  <ol className="text-left">
                                                      <li>There are two main categories of scholarships &ndash; Government and Private Scholarships. It is very important to choose which type of scholarship you want to avail before proceeding with applications.</li>
                                                      <li>Research and monitor regularly about the announcement of scholarship exams notifications and details, application deadlines and eligibility.</li>
                                                      <li>Find out which Scholarships Exam eligibility criteria is matching with your academic qualifications.</li>
                                                      <li>Fill the Application Form without missing the last date.</li>
                                                      <li>Submit the requested important documents like previous academic transcripts, age and address proof, certificates of participation in sports and extra-curricular activities, proof of parent&rsquo;s income (if applicable), statement of purpose, etc.</li>
                                                      <li>Prepare well for competitive scholarship examinations and answer each question very carefully.</li>
                                                      <li>Attend mock tests if possible.</li>
                                                      <li>Some universities also conduct an additional Personal Interview round after the examination so it is good to be prepared.</li>
                                                      <li><strong>If you are applying to a university or B-School abroad, check for visa requirements.</strong></li>
                                                  </ol>
                                                </div>
                                            </div>
                                          </div>
                                          <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                  <a data-toggle="collapse" data-parent="#accordion1" href="#schoFAQ2">
                                                  HOW TO PREPARE FOR SCHOLARSHIP EXAMINATIONS?</a>
                                                </p>
                                            </div>
                                            <div id="schoFAQ2" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                  <p>There are many <b>top-ranking MBA colleges in Bangalore</b> that offer different scholarship schemes for the aspiring students. The process of securing a scholarship is very competitive because there are many applicants but limited scholarship offers. To get a scholarship the candidate should stand out from others. This can be achieved by:
                                                  </p>
                                                  <ul>
                                                      <li>Early organization, strategy, planning, time management and preparation</li>
                                                      <li>Review the mode, duration, syllabus, marking scheme and examination pattern of the entrance exams</li>
                                                      <li>Stay up-to-date with the latest news and current affairs of national and international significance</li>
                                                      <li>Brush up on General Knowledge topics</li>
                                                      <li>Prepare for Logical Reasoning questions with continuous practice</li>
                                                      <li>Build Vocabulary, Comprehension and Grammar</li>
                                                      <li>Practice basic Mathematics and memorize formulae</li>
                                                      <li>Hard work and determination are essential during preparation</li>
                                                      <li>Avoid distractions like TV, music and video games</li>
                                                      <li>If additional assistance and guidance is required, join an educational advisory centre like Galaxy Educational Services, one of Bangalore&rsquo;s best academic advisory and counselling centres</li>
                                                      <li>Prepare for the four main categories of questions usually asked in competitive examinations &ndash; Mathematical Skills, Intelligence &amp; Critical/ Logical Reasoning, Verbal Ability &amp; Language Comprehension and General Awareness</li>
                                                  </ul>
                                                </div>
                                            </div>
                                          </div>
                                          <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                  <a data-toggle="collapse" data-parent="#accordion1" href="#schoFAQ3">
                                                  EXAMPLES OF MBA SCHOLARSHIPS AND EXAMINATIONS</a>
                                                </p>
                                            </div>
                                            <div id="schoFAQ3" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                  <p><b><u>INTERNATIONAL LEVEL</u></b></p>
                                                  <ul>
                                                      <li>Graduate Management Admission Test (GMAT) &ndash; for MBA Scholarship</li>
                                                      <li>Harvard Business School (USA)</li>
                                                      <li>Stanford Reliance Dhirubhai Fellowships for Indian students (USA)</li>
                                                      <li>Eiffel Scholarships (France)</li>
                                                      <li>University of Queensland MBA Student Scholarship (Australia)</li>
                                                      <li>Oxford and Cambridge Society of India (OCSI) Scholarships (UK)</li>
                                                      <li>International Institute for Management Development (IMD) MBA Scholarship (Switzerland)</li>
                                                  </ul>
                                                  <p><b><u>NATIONAL LEVEL</u></b></p>
                                                  <ul>
                                                      <li>Aditya Birla Group Scholarship</li>
                                                      <li>Merit-cum-Means Scholarship Scheme (by Ministry of Minority Affairs, Government of India) for Professional and Technical Courses for the Minority community</li>
                                                      <li>IIM Exam &ndash; for MBA Scholarships in IIMs across India</li>
                                                      <li>AIMS Test for Management Admissions (ATMA)</li>
                                                      <li>Common Management Admission Test (CMAT)</li>
                                                      <li>Management Aptitude Test (MAT)</li>
                                                      <li>Indian School of Business Merit Waiver</li>
                                                      <li>Cognizant Technology Solutions - B&rsquo;izard Scholar</li>
                                                      <li>McKinsey Emerging Scholars Program</li>
                                                      <li>Assam Foundation of North America Academics Scholarship</li>
                                                      <li>SBI Youth for India Fellowship</li>
                                                      <li>India Global Alliance Scholarships</li>
                                                      <li>Government Finance Officers Association (GFOA) Scholarships</li>
                                                  </ul>
                                                  <p><b><u>STATE – KARNATAKA</u></b></p>
                                                  <ul>
                                                      <li>Karnataka Post Graduate Common Entrance Test (PGCET) – for MBA Scholarship</li>
                                                      <li>Karnataka Management Aptitude Test (KMAT)</li>
                                                      <li>Prathibha Merit Scholarship</li>
                                                  </ul>
                                                  <p><b><u>BANGALORE</u></b></p>
                                                  <ul>
                                                      <li>XAT &ndash; Xavier&rsquo;s Aptitude Test for XIME</li>
                                                      <li>JET &ndash; Jain Entrance Test</li>
                                                      <li>RUASAT &ndash; MS Ramaiah University of Applied Sciences Admission Test</li>
                                                      <li>IFIM Business School Entrance Exam</li>
                                                      <li>NMAT Exam &ndash; for MBA Scholarship in Narsee Monjee Institute of Management Studies (NMIMS)</li>
                                                      <li>Symbiosis National Aptitude Test (SNAP) &ndash; for MBA Scholarship in Symbiosis Institute of Business Management (SIBM)</li>
                                                      <li>St. Joseph&rsquo;s Institute of Management Entrance Exam</li>
                                                      <li>Christ University Entrance Test (CUET) &ndash; for MBA Scholarship in CHRIST (Deemed to be University)</li>
                                                      <li>PES Scholastic Aptitude Test (PESSAT) &ndash; for MBA Scholarship in PES University</li>
                                                      <li>Galaxy Admission Test (GAT) &ndash; for MBA Scholarship</li>
                                                  </ul>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </section>
            <section className="list-options-area">
              <div className="container">
                  <div className="row">
                    <div className="col-sm-6">
                        <div className="single-courses_v">
                          <div className="single-courses-area" style={{padding:'0'}}>
                              <div className="single-curses-contert">
                                <div className="curriculum-text-box">
                                    <div className="curriculum-section">
                                      <div className="panel-group" id="accordion">
                                          <div className="panel panel-default show" itemScope itemType="https://schema.org/ItemList">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                                  <i itemProp="name" style={{fontStyle: 'normal'}}>List of Top MBA Colleges in Bangalore</i></a>
                                                </p>
                                            </div>
                                            <div id="collapse1" className="panel-collapse collapse in">
                                                <div className="panel-body">
                                                  <ul className="college-icon-list list-unstyled scrollable-menu">
								                                  <meta itemProp="itemListOrder" content="Descending"/>
                                                  {mbacolleges && mbacolleges.length > 0 && mbacolleges.map((mbacollege, index) => {
                        			                      return (
                                                      <span key={`mba${mbacollege.id}`}>
                                                        <li>
                                                          <b><Link to={`/colleges/${mbacollege.slug}`}>
                                                            <span itemProp="itemListElement">{mbacollege.college}</span></Link>
                                                          </b>
                                                        </li>
                                                        <br/>
                                                      </span>
                                                    );
                                                  })}
                                                  </ul>
                                                </div>
                                            </div>
                                          </div>
                                          <div className="panel panel-default" itemScope itemType="https://schema.org/ItemList">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse2" className="collapsed">
                                                  <i itemProp="name" style={{fontStyle: 'normal'}}>MBA Specialisations in Bangalore</i></a>
                                                </p>
                                            </div>
                                            <div id="collapse2" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                <ul className="college-icon-list list-unstyled scrollable-menu">
                                                  <meta itemProp="itemListOrder" content="Descending"/>
                                                  {specialisations && specialisations.length > 0 && specialisations.map((specialisation, index) => {
                        			                      return (
                                                      <span key={`spa${specialisation.id}`}>
                                                        <li>
                                                          <b><Link to={`/specialisations/${specialisation.slug}`}>
                                                          <span itemProp="itemListElement">{specialisation.category}</span></Link></b>
                                                        </li><br/>
                                                      </span>
                                                    );
                                                  })}
										    								        </ul>
                                                </div>
                                            </div>
                                          </div>
                                          <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse3" className="collapsed">
                                                  MBA Universities in Bangalore</a>
                                                </p>
                                            </div>
                                            <div id="collapse3" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                  <div className="scrollable-menu">
                                                      <p style={{fontSize:'18px',color:'#000',display:'inline-block',borderBottom:'1px dashed #03b044',paddingBottom: '5px',marginBottom: '20px',textAlign: 'left'}}>1. Government Universities</p>
                                                      <p><b><u>Bangalore University</u></b></p>
                                                      <p>Bangalore University (BU) is a public state university located in Bengaluru, Karnataka, India. It was Established in 1964 and the university is a part of the Association of Indian Universities (AIU), Association of Commonwealth Universities (ACU) and affiliated by University Grants Commission (UGC). BU has 684 affiliated colleges in Karnataka and is known as one of the largest universities of Asia.</p>
                                                      <p><b><u>VTU</u></b></p>
                                                      <p>Visvesvaraya Technological University (VTU) is a collegiate public state university in Karnataka State of the Republic of India, established by the Government of Karnataka in 1988. VTU is one of the largest universities in India with 218 colleges affiliated to it.</p>
                                                      <p style={{fontSize:'18px',color:'#000',display:'inline-block',borderBottom:'1px dashed #03b044',paddingBottom: '5px',marginBottom: '20px',textAlign: 'left'}}>2. Deemed / Private University</p>
                                                      <p>Deemed university, or deemed-to-be-university/ Private University  is an accreditation awarded to higher educational institutions in India. These Universities in India are recognized by the University Grants Commission (UGC), which draws its power from the University Commission Act, 1956.</p>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div className="col-sm-6 welcome-img home_version_04">
                        <img src="/assets/images/mba-apply.jpg" alt="admission for mba in bangalore" className="lozad img-responsive"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                        <div className="event-01" id="exams">
                          <div className="events-list-03 ">
                              <div className="events-single-box">
                                <div className="row">
                                    <div className="col-sm-12 event-info home-page-exams">
                                      <div className="row">
                                          <div className="col-sm-12 section-header-box">
                                            <div className="section-header">
                                                <h2 style={{color: '#fff'}}>MBA Entrance Exams</h2>
                                            </div>
                                          </div>
                                      </div>
                                      <div id="viewMore" className={isActive ? '' : 'exam-closed'}>
                                      <div className="row">
                                          <div className="col-xs-12 col-lg-4">
                                            <div className="examBox">
                                              <h4 style={{color: '#ff870d'}}>National Level Exams</h4>
                                              {national && national.length > 0 && national.map((nationa, index) => {
                        			                  return (
                                                  <div className="col-lg-6 col-sm-4 col-xs-6" key={`nle${index}`}>
                                                    <ul className="exam-icon-list list-unstyled">
                                                    {nationa && nationa.length > 0 && nationa.map((nation, index) => {
                        			                        return (
                                                        <li key={`nas${nation.id}`}><Link to={`/exams/${nation.slug}`}>{nation.exam_name}</Link></li>
                                                      );
                                                    })}
                                                    </ul>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="col-xs-12 col-lg-4" style={{borderLeft: '1px solid #54b245', borderRight: '1px solid #54b245'}}>
                                            <div className="examBox">
                                              <h4 style={{color: '#ff870d'}}>State Level Exams</h4>
                                              {examState && examState.length > 0 && examState.map((examState1, index) => {
                        			                  return (
                                                  <div className="col-lg-6 col-sm-4 col-xs-6" key={`sle1${index}`}>
                                                    <ul className="exam-icon-list list-unstyled">
                                                    {examState1 && examState1.length > 0 && examState1.map((examStatea, index2) => {
                        			                        return (
                                                        <li key={`sle${examStatea.id}`}><Link to={`/exams/${examStatea.slug}`}>{examStatea.exam_name}</Link></li>
                                                      );
                                                    })}
                                                    </ul>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="col-xs-12 col-lg-4">
                                          <div className="examBox">
										                        <h4 style={{color: '#ff870d'}}>Institution Level Exams</h4>
                                            {institutions && institutions.length > 0 && institutions.map((institution, index) => {
                        			                  return (
                                                <div className="col-lg-6 col-sm-4 col-xs-6" key={`ile1${index}`}>
                                                  <ul className="exam-icon-list list-unstyled">
                                                  {institution && institution.length > 0 && institution.map((institution1, index2) => {
                        			                      return (
                                                      <li key={`ile${institution1.id}`}><Link to={`/exams/${institution1.slug}`}>{institution1.exam_name}</Link></li>
                                                    );
                                                  })}
                                                  </ul>
                                                </div>
																				        );
                                            })}
																				    </div>
                                          </div>
                                          
                                      </div>
                                      </div>
                                      <button id="showmore-button-viewMore" onClick={this.toggleClass} className="showmore-button">{isActive ? 'View less' : 'View more'}</button>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </section>
            <div className="home_version_02">
              <section className="register-area">
                  <div className="overlay-bg">
                    <div className="container">
                        <div className="row">
                          <div className="col-sm-5">
                              <div className="row">
                                <div className="form-full-box">
                                    <div className="header-box-top">
                                      <div className="col-sm-12 section-header-box">
                                          <div className="section-header">
                                            <h2>Apply Now</h2>
                                          </div>
                                      </div>
                                      <p>Apply now to get admission for MBA...</p>
                                    </div>
                                    <form onSubmit={this.handleSubmit} >
                                      <div className="register-form">
                                          <div className="row">
                                            <div className="col-xs-12">
                                                <div className="form-group">
                                                  <input className="form-control" name="name" placeholder="Name" required type="text"/>
                                                  <input type="hidden" name="source" value="Home"/>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="form-group">
                                                  <input className="form-control" name="email" placeholder="Email" required type="email"/>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="form-group">
                                                  <input className="form-control EnquiryMobile" name="phone" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} placeholder="Phone" required="" type="text" required/>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="form-group">
                                                  <select className="form-control SpecialSelect" name="specialisation" required>
                                                      <option value="">Select Specialisation</option>
                                                      {specialisations && specialisations.length > 0 && specialisations.map((specialisation, index) => {
                        			                          return (
                                                          <option key={index} value={specialisation.category}>{specialisation.category}</option>
                                                        )
                                                        })}
                                                  </select>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="form-group">
                                                  <select className="form-control SpecialSelect" name="country" required onChange={this.selectCountry}>
                                                      <option value="">Select Country</option>
                                                      {this.state.countries.map((country, index) => (
                                                        <option key={index} value={country.label}>{country.label}</option>
                                                      ))}
                                                  </select>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="form-group">
                                                  <select className="form-control SpecialSelect" name="state" value={this.state.stateValue?.value || ''} required onChange={this.selectRegion}>
                                                      <option value="">Select State</option>
                                                      {this.state.states && this.state.states.map((state, index) => (
                                                        <option key={index} value={state.value}>{state.label}</option>
                                                      ))}
                                                  </select>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xs-12 register-btn-box">
                                                <button className="register-btn" type="submit">Submit</button>
                                            </div>
                                          </div>
                                      </div>
                                    </form>
                                </div>
                              </div>
                          </div>
                          <div className="col-sm-4 col-sm-offset-1">
                              <div className="notifications" id="notifications">
                                <div className="row">
                                    <div className="col-sm-12 section-header-box">
                                      <div className="section-header section-header-l">
                                          <h2>NOTIFICATIONS</h2>
                                      </div>
                                    </div>
                                </div>
                                <div className="blog_1">
                                    <div className="blog-area">
                                      <div className="blog_side-right">
                                          <div className="recent-post-01 scrollable-notification">
                                            <div className="row">
                                            {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                        			                return (
                                                <div className="col-sm-12 recent-single" key={`n${notification.id}`}>
                                                  <div className="recent-content-item">
                                                    <div className="img-box">
                                                      <Link to={`/notifications/${notification.slug}`}>
                                                        <img className="lozad" src={`/storage/notifications/${notification.image2}`} alt={notification.title} data-loaded="true"/>
                                                      </Link>
                                                    </div>
                                                    <div className="recent-text pull-right">
                                                      <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                                      <p>{this.notificationDate(new Date(notification.updated_at))}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                             })}
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </section>
            </div>
            <section className="specializations-area">
              <div className="container">
                  <div className="row">
                    <div className="col-sm-8">
                        <div className="row">
                          <div className="col-sm-12 section-header-box">
                              <div className="section-header">
                                <h3>TRENDING MBA SPECIALISATIONS</h3>
                              </div>
                          </div>
                        </div>
                        <div className="home_version_03">
                          <div className="Courses-area" id="courses">
                              <div className="specialization-content">
                              <div className="courses-wrapper">
                              <Swiper init="false" className="specialisation-swiper" modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} spaceBetween={1} simulateTouch={false} onSwiper={(swiper) => console.log('')} onSlideChange={() => console.log('')} loop={true}  cssMode={true} mousewheel={false} autoplay={{delay: 3000, disableOnInteraction: false}} navigation={{ nextEl: ".arrow-next", prevEl: ".arrow-prev" }} breakpoints= {{ 640: { slidesPerView: 1, pagination: true,}, 1024: { slidesPerView: 3,}}}>
                              {specialisations && specialisations.length > 0 && specialisations.map((specialisation, index) => {
                        			  return (
                                  <SwiperSlide key={`spa${specialisation.id}`}>
                                  <div className="col-sm-12">
                                    <div className="single-courses">
                                      <figure>
                                        <div className="figure-img">
                                          <img src={`/storage/Specialisation/${specialisation.image}`} alt="direct mba admission in bangalore" className="lozad img-responsive"/></div>
                                        <figcaption>
                                          <div><a href="#apply-now" data-toggle="modal" className="read_more-btn">Apply Now</a></div>
                                        </figcaption>
                                      </figure>
                                      <div className="courses-content-box">
                                        <div className="courses-content">
                                          <h3><Link to={`/specialisations/${specialisation.slug}`}>{specialisation.category}</Link></h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  </SwiperSlide>
                                );
                              })}
                              </Swiper>
                              </div>
                              <div className="arrow">
                              <button className="arrow-prev"><i className="fa fa-angle-left"></i></button>
                              <button className="arrow-next"><i className="fa fa-angle-right"></i></button>
                              </div>
                                <div className="btn-more text-center">
                                    <Link to="/mba-specialisations"><button className="readmore">View all</button></Link>
                                </div>
                                <div className="owl-theme">
                                    <div className="owl-controls">
                                      <div className="custom-nav owl-nav"></div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div className="col-sm-4 ">
                        <div className="row">
                          <div className="col-sm-12 section-header-box">
                              <div className="section-header">
                                <h3>BLOGS</h3>
                              </div>
                          </div>
                        </div>
                        <div className="blog_1">
                          <div className="blog-area">
                              <div className="blog_side-right">
                                <div className="recent-post-01">
                                    <div className="row">
                                    {blogs && blogs.length > 0 && blogs.map((blog, index) => {
                                      return (
                                      <div className="col-sm-12 recent-single" key={`bl${blog.id}`}>
                                        <div className="recent-content-item">
                                          <div className="img-box">
                                            <Link to={`/blogs/${blog.slug}`}>
                                              <img className="lozad" src={`/storage/blogs/${blog.image2}`} alt={blog.title} data-loaded="true"/>
                                            </Link>
                                          </div>
                                          <div className="recent-text pull-right">
                                          <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                                            <p>{this.notificationDate(new Date(blog.updated_at))}</p>
                                          </div>
                                        </div>
                                      </div>
                                      )
                                    })}
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </section>
            <section className="list-options-area" style={{background: '#efefef'}}>
              <div className="container">
                  <div className="row">
                    <div className="col-sm-6 welcome-img home_version_04">
                        <img src="/assets/images/mba-index-side.jpg" alt="admission for mba in bangalore" className="lozad img-responsive" style={{width: '450px',margin: '20px auto'}}/>
                    </div>
                    <div className="col-sm-6">
                        <div className="text-center info-sectionText">
                          <h3>GET TO KNOW ABOUT COLLEGES OFFERING DIRECT ADMISSION FOR MBA IN BANGALORE</h3>
                          <div className="btn-more"><Link className="readmore btn btn-success" to="/best-mba-colleges-in-bangalore">Read more...</Link></div>
                        </div>
                    </div>
                  </div>
              </div>
            </section>
            <section className="instraction-area">
              <div className="container">
                <div className="row inspiration-full-box">
                  <div className="col-md-12">
                    <div className="text-center">
                        <h3>DIRECT ADMISSION FOR PGDM IN BANGALORE</h3>
                      <a className="btnPGDM" href="https://www.pgdmcollegesinbangalore.com" target="_blank">Click here</a>
                    </div>
                  </div>
                </div>
              </div>
          </section>
          <section className="Welcome-area" id="scope" style={{paddingTop:0}}>
            <div className="container">
                <div className="row">
                  <div className="col-sm-12 Welcome-area-text scopeMBA">
                      <div className="row">
                        <div className="col-sm-12 section-header-box">
                            <div className="section-header section-header-l">
                              <h2>Career scope of MBA</h2>
                            </div>
                        </div>
                      </div>
                      <p>An MBA degree is a global portal not only to careers in business, but also to careers in various diverse fields like accounts, education, accounts, events, fashion, finance, hospitality, human resources (HR), industrialization, information technology (IT), logistics, marketing, tourism and operations. The students who pursue MBA from a college that is in the <b>list of top management colleges in Bangalore</b> can expect excellent career opportunities and better pay scales soon after they complete their post graduation.</p>
                      <p>A management trainee/professional is equipped with all the basic and advanced skills required for strategy design, resource management, process execution and compliance. The possession of skills like leadership, decision-making, creativity, strategy, proficiency and interpersonal communication make it easy for a candidate to reach exponential peaks quickly and profitably.</p>
                      <p>Students from any educational background with an undergraduate or postgraduate degree in any field can apply for an MBA course. The two main criteria for selection of a course are minimum aggregate or GPA and successful qualification of entrance-level exam (national, general or state- specific).</p>
                      <p>Popular reasons why students want to pursue an MBA are high pay packages, a desire to get quick promotions and to climb to the top in the company hierarchy. However, long term reasons include the desire to have a progressive career, to obtain a different and innovative business outlook and personal branding in order to become an entrepreneur.</p>
                      <p>An MBA degree is one of the main job providers in the world. A diverse range of careers is available with varied scope after completion of an MBA course. Career domains range from government, public, private and non-profit sectors and include industries, factories, hospitals, corporate companies, universities and institutions.</p>
                      <p>This business sector is one of the highest paying in the professional world. Highly skilled, qualified and enthusiastic candidates are entitled to earn very handsome salary packages with exciting increments based on performance and delivery.</p>
                      <p>A diverse range of jobs are available in multiple industrial sectors for successful MBA candidates. There are many opportunities in prime and renowned companies in different domains with attractive salaries.</p>
                      <p>With multiple changes in the global market, there is a heavy demand for MBA graduates who are equipped and prepared to face the challenges that accompany competitive globalization. With India’s exponential growth and prominence, strategic planners and leaders are the need of the hour to forecast, monitor, analyze, manage and regulate the economic market with the ability to take advantage of available opportunities and maximize resources profitably.</p>
                  </div>
                </div>
            </div>
          </section>
          <section className="Welcome-area" id="placements" style={{paddingTop:'0',background: '#efefef'}}>
            <div className="container">
                <div className="row">
                  <div className="col-sm-12 Welcome-area-text placementsCont">
                      <div className="row">
                        <div className="col-sm-12 section-header-box">
                            <div className="section-header section-header-l">
                              <h2>MBA Placements and Salary Packages</h2>
                            </div>
                        </div>
                      </div>
                      <p>An MBA degree is a popular professional degree that guarantees placement with attractive salary packages. The Top MBA Colleges in Bangalore provide and ensure placement opportunities in different employment sectors like:</p>
                      <ul>
                        <li>Government</li>
                        <li>Private</li>
                        <li>MNCs</li>
                        <li>Start-ups</li>
                        <li>Small and Medium Enterprises (SMEs)</li>
                        <li>Companies abroad</li>
                      </ul>
                      <hr/>
                      <p>The salary prospects are different for different candidates based on personal and professional achievements and skills. Some important factors are:</p>
                      <ul>
                        <li><b>Academic background, performance and record</b> – an excellent academic record ensures a very good salary.</li>
                        <li><b>Candidate profile</b> – a smart, logical and alert candidate has potential to earn very well.</li>
                        <li><b>Type of Business School</b> – graduation from an A+ category Business School like Indian Institute of Management (IIM) in Ahmedabad/Kolkata/Bangalore, Faculty of Management Studies (FMS), Indian Institute of Foreign Trade (IIFT), Xavier School of Management (XLRI) and S. P. Jain Institute of Management and Research ensures a very good salary package.</li>
                        <li>Additional certification courses related to latest subjects ensure more practical exposure and skill enhancement that guarantees a high salary.</li>
                        <li><b>Government and Private-level job selection examinations and personal interviews</b> – this proves that the candidates are high performers and are eligible for high salary packages.</li>
                        <li><b>Type of MBA Specialization</b> – the salary package varies with the course specialization. Specializations like Marketing, Finance, Logistics, Operations, Human Resources, Aviation and others provide very good salary packages depending on the candidate’s potential.</li>
                        <li><b>Type of Company</b> – the type of recruiting company is also an important factor. A well established and internationally reputed company will offer a better salary package than a small-scale company.</li>
                        <li><b>Previous work experience</b> – prior work experience and work-related knowledge is an add-on that ensures a very good salary.</li>
                        <li><b>Skills</b> – candidates with managerial, communication, logical, leadership, decision- making, planning, critical- thinking, presentation and organizing skills  guarantee a very good salary.</li>
                        <li><b>Adaptability to new Technology</b> – candidates with the ability to adapt and learn with new and advanced technology have an opportunity to earn very good salary packages.</li>
                      </ul>
                      <br/>
                      <p>The average salary for management candidates is Rs. 8.5 Lakhs per annum. Fresh management graduates can get job roles as Trainees and Associates with a starting salary of Rs. 3 Lakhs per annum. With increased work experience, fast learning potential, high productivity and excellent performance, candidates can get job roles as Managers, CEOs and Directors with an increase in salary without any limitation.</p>
                      <p>There is an increased demand for qualified managers and leaders which has resulted in an increase in MBA placements. With global economic progress, an increased number of international firms are interested in qualified Indian employees.</p>
                  </div>
                </div>
            </div>
          </section>
          <div className="home_version_03">
            <section className="partners-area">
              <div className="container">	
                <div className="row">
                  <div className="col-sm-12 section-header-box">
                    <div className="section-header">
                      <h2>Top MBA Recruitement companies</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="recruit-content">
                    <div className="row text-center">
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/amazon.jpg" alt="amazon"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/apple.jpg" alt="apple"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/deloitte.jpg" alt="deloitte"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/facebook.jpg" alt="facebook"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/genixo.jpg" alt="genixo"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/icici.jpg" alt="icici"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/infosys.jpg" alt="infosys"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/jpmorgan.jpg" alt="jpmorgan"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/microsoft.jpg" alt="microsoft"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/waltdesney.jpg" alt="waltdesney"/></div>
                      <div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/images/brands/wipro.jpg" alt="wipro"/></div>	
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        );
    }
}

// export default Home;