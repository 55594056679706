import React from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import { HashLink } from 'react-router-hash-link';
import countryFile from './Country';
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'
import checkNumber from './CheckMobileNumber';

export default function ProgramDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [program, setProgram] = useState([])
    const [programs, setPrograms] = useState([])
    const [meta, setMeta] = useState('')
	const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
	const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
	const [mobile, setMobileValue] = useState([]);
	const [blogs, setBlogs] = useState([]);
	const [notifications, setNotifications] = useState([]);

	function selectCountry (event) {
		setStateValue([])
		const state_arr = countryFile.s_a[event.key].split("|"); 
		const stateArray = [];
		stateArray.push({
		  value: '', 
		  label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
		  stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
		  });
		}
		setStates(stateArray)
		var Country = event.value;
		let check = checkNumber.checkNumber(Country,mobile);
		if(check === 2){
			setMobileValue('')
		}
	}
	  
	function selectRegion (val) {
		if(val.value !== '' && val.value !== null){
		  setStateValue(val)
		} else {
		  setStateValue([])
		}
	}

	function updateMobile(value){
		setMobileValue(value)
	}

    useEffect(()=>{
        fetchProduct(params.id);
		const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
		// const meta = document.createElement('meta');
		// meta.setAttribute('name', 'some-meta-tag-name');
		// meta.setAttribute('value', 'some meta tag value');
		// const head = document.querySelector('head');
		// head.insertBefore(meta, head.firstChild);
    },[params.id])
    const fetchProduct = async () => {
		setIsLoading(true)
        await axios.get(`/program-page-data/${params.id}`).then(({data})=>{
		    setProgram(data.program)
            setPrograms(data.programs)
			setNotifications(data.notifications)
			if(data.program.seo_meta){
				setMeta(data.program.seo_meta)
			} else {
				setMeta('<meta/>')
			}
			setIsLoading(false)
          // console.log(data);
        }).catch(({response:{data}})=>{
			setIsLoading(false)
			Swal.fire({
				text:'Error!.',
				icon:"error"
			})
        })
      }
     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      function commentDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ '-' +month+'-' +year;
      }

      function enquirySubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
		jQuery('.EnquiryMobile').val('');
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
			jQuery('.EnquiryMobile').val('');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

  return (
	!isLoading ? 
	program!==null?
    	<div>
            <Helmet prepend>
				<title>{program.seo_title}</title>
				{parse(meta)}
				<link rel="stylesheet" href="/assets/css/programdetails.css"></link>
            </Helmet>
            <div className="single-courses-area">
				<div className="container">
					<div className="row">
						<div className="col-sm-8 sidebar-left">
							<div className="single-curses-contert description-content">
							<div className="row">
								<div className="col-sm-10">
									<div className="section-header-box">
										<div className="section-header section-header-l">
										<h2>Overview</h2>
										</div>
									</div>
								</div>
							</div>
							<div dangerouslySetInnerHTML={{ __html: program.content }} />
							<div className="aboutBox">
								<div className="row">
									<div className="col-sm-10">
										<div className="section-header-box">
										<div className="section-header section-header-l">
											<h3>Eligibility</h3>
										</div>
										</div>
									</div>
								</div>
								<div dangerouslySetInnerHTML={{ __html: program.eligibility }} />
							</div>
							<div className="row">
								<div className="col-sm-10">
									<div className="section-header-box">
										<div className="section-header section-header-l">
										<h3>List of {program.program} Colleges in Bangalore</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="progClgList">
								<div dangerouslySetInnerHTML={{ __html: program.colleges }} />
							</div>
							</div>
						</div>
						<div className="col-sm-4 single-courses_v">
							<div className="blog_1">
							<div className="sidebar-content single-courses-area" style={{width:'100%',padding:'20px 0'}}>
								<div className="single-curses-contert" style={{background: '#1b5167'}}>
									<h3 className="text-center" style={{color: '#fff',paddingTop: '20px',marginTop: '0'}}>APPLY NOW</h3>
									<div className="leave_a_comment">
										<div className="row">
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-12">
													<form onSubmit={enquirySubmit} className="page-enquiry-form">
														<div className="form-group">
															<div className="row">
																<div className="col-sm-12">
																	<label className="input-label">name</label>
																	<input type="text" placeholder="Enter your name" id="name" name="name" required/>
																</div>
																<div className="col-sm-12">
																	<label className="input-label">E-mail</label>
																	<input type="email" placeholder="Enter your E-mail" name="email" required/>
																</div>
																<div className="col-sm-12">
																	<label className="input-label">Phone</label>
																	<input type="text" value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" placeholder="Enter your Phone number" name="phone" required/>
																</div>
																<div className="col-xs-12">
																	<label className="input-label">Country</label>
																	<Select classNamePrefix="CountrySelect"  options={countries} name="country" className="select-country"  placeholder="Select Country" required onChange={selectCountry} />
																</div>
																<div className="col-xs-12">
																	<label className="input-label">State</label>
																	<Select classNamePrefix="StateSelect" options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={selectRegion} />
																</div>
																<div className="col-sm-12">
																	<div className="text-center">
																	<button type="submit">Submit</button>
																	</div>
																</div>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
										</div>
									</div>
								</div>
							</div>
							</div>
							<div className="sidebar-content single-courses-area" style={{width:'100%',padding:'0 0 20px 0'}}>
							<div className="single-curses-contert">
								<div className="leave_a_comment" style={{marginTop: '0'}}>
									<div className="notifications">
										<div className="row">
										<div className="col-sm-12 section-header-box">
											<div className="section-header section-header-l">
												<h2>NOTIFICATIONS</h2>
											</div>
										</div>
										</div>
										<div className="blog_1">
										<div className="blog-area">
											<div className="blog_side-right">
												<div className="recent-post-01">
													<div className="row">
													{notifications && notifications.length > 0 && notifications.map((notification, index) => {
                                                  		return (
															<div className="col-sm-12 recent-single" key={`n${notification.id}`}>
																<div className="recent-content-item">
																	<div className="img-box">
																		<Link to={`/notifications/${notification.slug}`}>
																			<img class="lozad" src={`/storage/notifications/${notification.image2}`} alt={notification.title} data-loaded="true"/>
																		</Link>
																	</div>
																	<div className="recent-text pull-right">
																		<Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
																		<p>{blogDate(new Date(notification.updated_at))}</p>
																	</div>
																</div>
															</div>
														);
                                                	})}
													</div>
												</div>
											</div>
										</div>
										</div>
									</div>
								</div>
							</div>
							</div>
							<div className="textBox" style={{background:'#fdb60e'}}>
							<a href="https://www.pgdmcollegesinbangalore.com" target="_blank">
								<p>DIRECT ADMISSIOM FOR PGDM IN BANGALORE <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></p>
							</a>
							</div>
							<div className="event-01">
							<div className="events-list-03">
								<div className="events-single-box examBox">
									<div className="row">
										<div className="col-sm-12 section-header-box" style={{marginBottom: '0'}}>
										<div className="section-header section-header-l">
											<h3>MBA PROGRAMS IN BANGALORE</h3>
										</div>
										</div>
									</div>
									<div className="event-info">
										<ul className="exam-icon-list list-unstyled">
											{programs && programs.length > 0 && programs.map((program, index) => {
												return (
												<li><Link to={`/programs/${program.slug}`}>{program.program}</Link></li>
												);
											})}
										</ul>
									</div>
								</div>
							</div>
							</div>
							<div className="textBox" style={{background:'#54b145',marginBottom: 0}}>
							<p><i className="fa fa-phone" aria-hidden="true"></i> HELPLINE: <a href="tel:+918095797575">+918095797575</a></p>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
	:
	<h1> Can't connect to server ! </h1>
	:
	<div className="row">
		<div className="col-md-12 text-center">
			<ThreeDots
			visible={true}
			height="50"
			width="50"
			color="#black"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{textAlign:'center', display:'block'}}
			wrapperclassName="loader"
			/>
		</div>
  </div>
  )
};

// export default NotificationDetails;