import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';

export default class Differences extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          reviews:[]
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/about-page-data')
                      .then(res=>{
                          this.setState({
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates
                          },()=>{
                            // console.log(this.state)
                          })
                      })
                      .catch(err=>{
                          console.log(err);
                      })
      }

    render(){
        const {notifications}=this.state;
        const {reviews}=this.state;
        return (
          <div>
            <Helmet>
                <title>Difference between Entrance Exams | CAT MAT CMAT</title>
                <meta name="description" content="Difference between Entrance Exams, Find out all differences between CAT MAT CMAT XAT etc..."/>
                <meta name="keywords" content="mba cat mat difference, mba colleges questions, mba admission frequently asked questions, top mba colleges & exams"/>
                <link rel="stylesheet" href="/assets/css/differences.css"></link>
            </Helmet>
            <section className="courses-02">
                
            </section>
        </div>
        );
    }
}

// export default Home;