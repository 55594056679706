import React from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'

export default function NotificationDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [notification, setNotification] = useState([])
    const [comments, setComments] = useState([])
    const [otherNotifications, setOtherNotifications] = useState([])
    const [meta, setMeta] = useState('')
    const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
    const [mobile, setMobileValue] = useState([]);
    const [exams, setExams] = useState([])

    function selectCountry (event) {

      let testParent =  event;
      setStateValue([])
      const state_arr = countryFile.s_a[event.key].split("|"); 
      const stateArray = [];
      stateArray.push({
        value: '', 
        label: 'Select'
      });
      for (var i = 0; i < state_arr.length; i++) {
        stateArray.push({
        value: state_arr[i], 
        label: state_arr[i]
        });
      }
      setStates(stateArray)
      var Country = event.value;
      let check = checkNumber.checkNumber(Country,mobile);
      if(check === 2){
        setMobileValue('')
      }
    }

    function updateMobile(value){
      setMobileValue(value)
    }
      
    function selectRegion (val) {
      if(val.value !== '' && val.value !== null){
        setStateValue(val)
      } else {
        setStateValue([])
      }
    }
  

    useEffect(()=>{
        fetchProduct(params.id);
        const countryArray = [];
        // console.log(countryFile);
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
    },[params.id])
    const fetchProduct = async () => {
        setIsLoading(true)
        await axios.get(`/notification-page-data/${params.id}`).then(({data})=>{
          setNotification(data.notification)
          setComments(data.comments)
          if(data.notification.seo){
            setMeta(data.notification.seo)
          } else {
            setMeta('<meta/>')
          }
          setOtherNotifications(data.otherNotifications)
          setExams(data.exams)
          // console.log(data);
          setIsLoading(false)
        }).catch(({response:{data}})=>{
          setIsLoading(false)
          Swal.fire({
            text:'Error!.',
            icon:"error"
          })
        })
      }
     function getDay(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date;
      }

      function getMonth(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month;
      }

      function commentDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ '-' +month+'-' +year;
      }

      function notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ ' ' +month+', ' +year;
      }

      function enquirySubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        jQuery('.EnquiryMobile').val('');
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

      function submitComment(event){
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-comment', formData)
        .then(({data})=>{
            event.target.reset()
            fetchProduct()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }
  return (
    !isLoading ? 
    notification!==null?
        <div>
            <Helmet>
                    <title>{notification.seotitle}</title>
                    {parse(meta)}
                    <meta property="og:image" content={`/storage/notifications/${notification.image}`} />
                    <meta property="og:image:secure_url" content={`/storage/notifications/${notification.image}`} />
                    <meta property="og:image:width" content="750" />
                    <meta property="og:image:height" content="351" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description" content={notification.title} />
                    <meta name="twitter:title" content={notification.title} />
                    <meta name="twitter:site" content="@galaxyeduworld" />
                    <meta name="twitter:image" content={`/storage/notifications/${notification.image}`} />
                    <meta name="twitter:image:alt" content={notification.title} />
                    <meta name="twitter:creator" content="@galaxyeduworld" />
                    <link rel="stylesheet" href="/assets/css/notification-details.css"></link>
            </Helmet>
            <div className="post_1_area">
              <div className="container">
                  <div className="row">
                    <div className="col-sm-8 post_left-side">
                        <div className="row">
                          <div className="col-sm-12">
                              <div className="post-img-box">
                                <img src={`/storage/notifications/${notification.image}`} alt={notification.title} className="img-responsive"/>
                              </div>
                          </div>
                          <div className="col-sm-12">
                              <div className="description-content">
                                <div className="description-heading">
                                    <div className="time">
                                      <span>{getDay(new Date(notification.updated_at))}</span>
                                      <span>{getMonth(new Date(notification.updated_at))}</span>
                                    </div>
                                    <h3>{notification.title}</h3>
                                </div>
                                <div className="description-text">
                                    <div className="row">
                                      <div className="col-sm-12">
                                          <div className="description-text-right text-left">
                                            <div dangerouslySetInnerHTML={{ __html: notification.content }} />
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className="col-md-12 comments" id="comments">
                              <div className="row">
                                <h3>Comments</h3>
                                {comments && comments.length > 0 && comments.map((comment, index) => {
                                  return (
                                    <div className="col-sm-12 comment-single-item" key={`ci${comment.id}`}>
                                        <div className="col-sm-1 img-box">
                                          <img src="/assets/images/avatar.jpg" alt="" className="img-circle"/>
                                        </div>
                                        <div className="col-sm-11 comment-left-bar">
                                          <div className="comment-text">
                                              <ul className="list-unstyled comment-author-box">
                                                <li> 
                                                    <span className="name">{comment.name}</span> 
                                                    <span>{commentDate(new Date(comment.created_at))}</span>
                                                </li>
                                              </ul>
                                              <p>{comment.comment}</p>
                                          </div>
                                        </div>
                                    </div>
                                  );
                                })}
                              </div>
                          </div>
                          <div className="col-sm-12">
                              <div className="leave-comment-box">
                                <div className="comment-respond">
                                    <div className="comment-reply-title">
                                      <h3>Leave a Reply</h3>
                                    </div>
                                    <div className="comment-form">
                                      <form onSubmit={submitComment}>
                                          <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                  <textarea className="form-control" name="comment" rows="5" placeholder="Type your comments" required></textarea>
                                                  <input type="hidden" name="comment_id" value={notification.id}/>
                                                  <input type="hidden" name="page" value="2"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                  <input type="text" name="name" className="form-control" placeholder="Name" required/>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                  <input type="email" name="email" className="form-control" placeholder="Email address" required/>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                  <input type="text" name="phone" className="form-control" placeholder="Phone number" required/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="full-width">
                                                  <input value="Submit"  type="submit"/>
                                                </div>
                                            </div>
                                          </div>
                                      </form>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="sidebar-text-post">
                          <div className="row">
                              <div className="col-sm-12">
                                <div className="event-01">
                                    <div className="events-list-03">
                                      <div className="events-single-box examBox">
                                          <div className="row">
                                            <div className="col-sm-12 section-header-box" style={{marginBottom: '0'}}>
                                                <div className="section-header section-header-l">
                                                  <h4><i className="fa fa-calendar" aria-hidden="true"></i> UPCOMING EXAMS & EVENTS</h4>
                                                </div>
                                            </div>
                                          </div>
                                          <div className="event-info">
                                            <ul className="exam-icon-list list-unstyled" style={{maxHeight: '400px'}}>
                                                {exams && exams.length > 0 && exams.map((exam, index) => {
                                                  return (
                                                    <li key={`ex${exam.id}`}>{exam.exam} <span>on {exam.exam_date}</span></li>
                                                  );
                                                 })}
                                            </ul>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-sm-12 recent-post">
                                <h3>Recent Notifcations</h3>
                                <div className="row">
                                {otherNotifications && otherNotifications.length > 0 && otherNotifications.map((otherNotification, index) => {
                                  return (
                                    <div className="col-sm-12 recent-single" key={`nf${otherNotification.id}`}>
                                      <div className="recent-content-item">
                                          <div className="img-box"><Link to={`/notifications/${otherNotification.slug}`}>
                                            <img src={`/storage/notifications/${otherNotification.image}`} alt=""/></Link>
                                          </div>
                                          <div className="recent-text pull-right">
                                            <Link to={`/notifications/${otherNotification.slug}`}>{otherNotification.title}</Link>
                                            <p>{notificationDate(new Date(otherNotification.created_at))}</p>
                                          </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    :
    <h1> Can't connect to server ! </h1>
    :
    <div className="row">
      <div className="col-md-12 text-center">
        <ThreeDots
        visible={true}
        height="50"
        width="50"
        color="#black"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{textAlign:'center', display:'block'}}
        wrapperclassName="loader"
        />
      </div>
    </div>
  )
};

// export default NotificationDetails;