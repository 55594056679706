import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import { ThreeDots } from 'react-loader-spinner'

export default class Specialization extends Component {
    
    constructor(){
        super();
        this.state={
          specialisations:[],
          isLoading:false
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        this.setState({isLoading:true})
        let usersData=await axios.get('/specialisations-page-data')
                    .then(res=>{
                        this.setState({
                            specialisations:res.data.specialisations
                        },()=>{
                        })
                        this.setState({isLoading:false})
                    })
                    .catch(err=>{
                        this.setState({isLoading:false})
                        console.log(err);
                    })
      }
    render(){
        const {specialisations, isLoading}=this.state;
        return (
            !isLoading ? 
	        specialisations!==null?
            <div>
                <Helmet>
                    <title>Specializations for MBA in Bangalore | MBA Direct Admission Open for 2024-25</title>
                    <meta name="description" content="Explore a wide range of specializations for MBA in Bangalore. Get direct admission to top mba management colleges in bangalore for academic year 2024-25. Shape your career with an MBA degree."/>
                    <meta name="keywords" content="mba specializations, mba specializations in Bangalore, mba specializations in top colleges, top mba colleges & specialisations, best mba specialization in bangalore"/>
                    <link rel="stylesheet" href="/assets/css/specialisations.css"></link>
                </Helmet>
                <section className="courses-1">
	                <div className="container">	
                        <div className="row courses-r-margin-bottom">
                        {specialisations && specialisations.length > 0 && specialisations.map((specialisation, index) => {
                            return (
                            <div className="col-sm-3 col-xs-6 single-courses-box" key={index}>
                                <div className="single-courses">
                                    <figure>
                                        <div className="figure-img">
                                            <img src={`/storage/Specialisation/${specialisation.image}`} alt={`MBA in ${specialisation.category}`} className="img-responsive"/></div>
                                        <figcaption>
                                            <div><a href="#apply-now" data-toggle="modal" className="read_more-btn">Apply Now</a></div>
                                        </figcaption>
                                    </figure>
                                    <div className="courses-content-box">
                                        <div className="courses-content">
                                            <h3><Link to={`/specialisations/${specialisation.slug}`}>{specialisation.category}</Link></h3>
                                            <Link to={`/specialisations/${specialisation.slug}`}><button className="read_more-btn">Read more...</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            );
                        })}
                        </div>
                        <hr/>


                        <div className="single-courses_v">
                            <div className="row single-courses-area" style={{padding: '0'}}>
                                <div className="col-sm-12 single-curses-contert">
                                    <h3 style={{textTransform: 'uppercase',color: '#1b5167',paddingBottom: '25px'}}>Frequently Asked Questions</h3>
                                    <div className="curriculum-text-box">
                                        <div className="curriculum-section">
                                        <div className="panel-group" id="accordion">
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                                    <b>1.</b> Does the fee structure vary for direct admission and Merit-based admission?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse1" className="panel-collapse collapse in">
                                                    <div className="panel-body">
                                                    <p>The fee structure is different for different MBA colleges. Some colleges offer the same fee structure for direct and Merit-based admission, while other colleges offer less fees for Merit-based admission and higher fees for direct Management quota admission. Some of the top colleges also have an additional management fee for the  direct admission.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse2" className="collapsed">
                                                    <b>2.</b> Do all MBA colleges in Bangalore provide 100% placements?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse2" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>No, Placements depend on the type of MBA college. Most reputed colleges provide 100% placement opportunities and additional facilities like support from the Placement Department. The low grade MBA colleges do not provide many placement opportunities. Some colleges also advertise for 100% placement but only provide fake recruitment information.</p>
                                                    <p>The main criteria for 100% placement are the associated companies and recruiters that participate in Campus Recruitment and the performance of the candidates in academics and in the personal interview.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse3" className="collapsed">
                                                    <b>3.</b> Is it good to do an MBA from a top college in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse3" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Yes, it is always good to do an MBA from a top and reputed college in Bangalore. This is because reputed colleges provide a good platform for students to be recognized by highlighting their academic profile, concentrating on the student’s additional skill development, and in getting placed in top companies in Bangalore.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse4" className="collapsed">
                                                    <b>4.</b> Is it good to do an MBA through online mode?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse4" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>It has become the current trend to study MBA through online mode. 
                                                        This is the preferred mode of study for students because they can work and study simultaneously to earn continuously without any effect on their education and studies.
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse5" className="collapsed">
                                                    <b>5.</b> Does an MBA degree help in starting up your own business?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse5" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Yes, an MBA degree helps candidates in starting up their own business. The two main MBA specializations that help candidates to start their own business are:
                                                    </p>
                                                    <ul>
                                                        <li>Entrepreneurship</li>
                                                        <li>Startups & SMEs Management</li>
                                                    </ul>
                                                    <p>These specializations provide in-depth knowledge about the process of business administration and help candidates to design, plan, and build their own business. Some management colleges in Bangalore also provide a platform for MBA students to start their own business.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse6" className="collapsed">
                                                    <b>6.</b> Are all the top colleges in Bangalore good for MBA?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse6" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>No, there are few colleges in Bangalore that are good for MBA courses and students should be careful while choosing an MBA college. Many management colleges in Bangalore do not provide placement opportunities so students should check all the information about the college before selecting and finalizing a college for MBA.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse7" className="collapsed">
                                                    <b>7.</b> What percentage should I get in my Bachelor’s degree to get admission in the top colleges in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse7" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>The minimum eligibility for MBA course is 50% aggregate (or equivalent) in the Bachelor’s degree from a recognized University. Many of the top colleges have their own cutoff percentage and selection process for admission. The cut off criteria for MBA courses varies with different colleges and candidates have to check the cutoff criteria of each college before beginning the application process.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse8" className="collapsed">
                                                    <b>8.</b> How do I increase my chances of getting placed in an MNC after MBA course?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse8" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>To increase the chances of getting placed in a reputed MNC, candidates will have to begin trying at the beginning of the MBA course. They have to have:</p>
                                                    <ul>
                                                        <li>A good Academic Record</li>
                                                        <li>Detailed knowledge and applications of the chosen Specialization</li>
                                                        <li>Exceptional communication, leadership, and presentation skills</li>
                                                        <li>Very good professional skills</li>
                                                        <li>Knowledge about the latest industrial needs and developments</li>
                                                        <li>Updated information about upcoming companies and MNCs</li>
                                                        <li>Training through Certifications and Internships</li>
                                                        <li>Mock Interview exposure and preparation</li>
                                                    </ul>
                                                    <p>The top management colleges in Bangalore provide various opportunities for academic, personal, and professional development that help the candidates to perform well during campus recruitment and to increase the candidate’s chances of getting placed in a very good and reputed MNC.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse9" className="collapsed">
                                                    <b>9.</b> Do entrance exams vary in different states in India?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse9" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Yes, State Level entrance exams are different throughout India; but there are some common National Level entrance exams.</p>
                                                    <ul>
                                                        <li>National level Entrance Exams can be attempted by candidates who are in any state in India like Common Admission Test (CAT), Management Aptitude Test (MAT), Graduate Management Admission Test (GMAT), etc.</li>
                                                        <li>State level Entrance Exams can be attempted by candidates who are in Karnataka. These exams include Karnataka Management Aptitude Test (KMAT) by KPPGCA, and Karnataka Post Graduate Common Entrance Test (PGCET) by KEA</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse10" className="collapsed">
                                                    <b>10.</b> Is there any Reservation Category for the candidates from different states in India?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse10" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Yes, Reservation Categories are available for the candidates from Karnataka especially for management courses in government colleges. Seats can be claimed by the candidates through State Counselling. 
                                                        There are no Reservation Categories for the non-Karnataka candidates unless they have completed 14 years of education in Karnataka.
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse11" className="collapsed">
                                                    <b>11.</b> Who can apply under the Reservation Category in MBA colleges?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse11" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Candidates have the right to apply for management education in the MBA college of their own state under the Reservation Category.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse12" className="collapsed">
                                                    <b>12.</b> Can I apply for an MBA in India after my Bachelor’s degree abroad?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse12" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Yes, candidates who have completed their Bachelor’s degree abroad can apply for an MBA in India as long as the University of their undergraduate degree is approved and recognized by the Association of Indian Universities (AIU).</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse13" className="collapsed">
                                                    <b>13.</b> Is it difficult for an NRI candidate to get admission in MBA colleges in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse13" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>No, it is easy for an NRI candidate to get admission in MBA colleges in Bangalore. This is because in the management colleges in Bangalore, MBA admissions are open for all the categories of students including NRI and foreign candidates. There are seats reserved through NRI Quota especially for NRI and foreign candidates.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse14" className="collapsed">
                                                    <b>14.</b> Which is the best college for MBA in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse14" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Bangalore is known for its management courses and colleges. There are many ‘A’ Grade MBA colleges which include  Indian Institute of Management (IIM), Christ University, Narsee Monjee Institute of Management Studies, and Symbiosis Institute of Business Management (SIBM).</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse15" className="collapsed">
                                                    <b>15.</b> Which is the best specialization for MBA in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse15" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>MBA has some common and evergreen specializations like Marketing, Finance, Human Resources, and Operations. Apart from this, other specializations that are high in demand can be chosen based on the candidate’s aptitude, interest, and industry needs. Some of the latest, high in demand, and trending specializations that have newly emerged are Logistics and Supply Chain Management, Luxury Management, Business Analytics, Digital transformation,Digital Marketing, E-commerce,Healthcare Management, Pharma Business Management, Hospitality Management, Sports Management, and Aviation Management.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse16" className="collapsed">
                                                    <b>16.</b> Which are the top universities for MBA in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse16" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Karnataka has two main Government universities that provides AICTE-approved MBA programs:</p>
                                                    <ul>
                                                        <li>Bangalore University</li>
                                                        <li>Visvesvaraya Technical University</li>
                                                    </ul>
                                                    <p>Bangalore has multiple deemed and private universities with popular and well-structured MBA programs:</p>
                                                    <ul>
                                                        <li>Christ University</li>
                                                        <li>Presidency University</li>
                                                        <li>Dayananda Sagar University</li>
                                                        <li>MS Ramaiah University</li>
                                                        <li>PES University</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse17" className="collapsed">
                                                    <b>17.</b> How can I find the best MBA college in Bangalore for management studies?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse17" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Before choosing and finalizing an MBA college in Bangalore for management studies, it is important to check some important criteria:</p>
                                                    <ul>
                                                        <li>The Approval and affiliation of the college</li>
                                                        <li>NAAC accreditation of the college</li>
                                                        <li>The Ranking and Reputation of the college</li>
                                                        <li>Courses and Specializations offered</li>
                                                        <li>Faculty and Student ratio</li>
                                                        <li>Infrastructure and Facilities provided</li>
                                                        <li>Compared Fee Structure</li>
                                                        <li>Ensures high return of investment (ROI)</li>
                                                        <li>Placement Department and additional placement assistance</li>
                                                        <li>Placement Statistics</li>
                                                        <li>Previous years Placement Records from various sources</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse18" className="collapsed">
                                                    <b>18.</b> What is the eligibility for MBA in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse18" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Eligibility for regular MBA program in Bangalore is according to AICTE guidelines. The eligibility criteria is:</p>
                                                    <ul>
                                                        <li>Minimum 50% aggregate (or equivalent) in Bachelor’s course in any stream from a recognized University</li>
                                                        <li>Qualification in management entrance examinations</li>
                                                        <li>MBA courses that are in collaboration with foreign management universities may accept less than 50% aggregate in Bachelor’s course in any stream</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse19" className="collapsed">
                                                    <b>19.</b> Which are the colleges that provide Part-time MBA in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse19" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Bangalore has many colleges that provide Part-time MBA courses. These courses are not provided by regular universities; however, they are offered by foreign universities or by online universities that provide management education through Part-time or Distance mode.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse20" className="collapsed">
                                                    <b>20.</b> Which are the colleges that provide Online MBA in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse20" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Online MBA courses are provided by colleges with collaborations with foreign universities through Virtual or Distance mode. This course is recommended for students who are currently working and prefer to study and work simultaneously. Most of the popular and reputed companies encourage and accept Online MBA so that the candidates can excel and grow in their job.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse21" className="collapsed">
                                                    <b>21.</b> Which are the colleges in Bangalore that provide international exposure?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse21" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>In recent times, international management programs have become very popular. The duration of the program varies in different colleges. Some of the colleges that provide international exposure in MBA programs are:</p>
                                                    <ul>
                                                        <li>Christ University</li>
                                                        <li>Jain University</li>
                                                        <li>Acharya Institute of Management and Sciences (AIMS)</li>
                                                        <li>Many other regular colleges in Bangalore and colleges which are affiliated to foreign universities.</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse22" className="collapsed">
                                                    <b>22.</b> Which is the best college for Global MBA Programmes in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse22" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>One of the best colleges in Bangalore for Global MBA or Dual MBA Programs is Christ University. The college has two programs that are collaborated with foreign universities with dual degrees:</p>
                                                    <ul>
                                                        <li>MBA - CHRIST (Deemed to be University) + MBA (FHWS, Germany/Western Michigan University, USA)</li>
                                                        <li>MBA - CHRIST (Deemed to be University) + MS (Virginia Commonwealth University, USA)</li>
                                                    </ul>
                                                    <p>The two-year programs involve the first year in Indian and the second year abroad.
                                                        Other colleges also have Global MBA programmes which are highly beneficial for                                 management candidates.
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse23" className="collapsed">
                                                    <b>23.</b> How can I get MBA admission in Christ University?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse23" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>To get MBA admission in Christ University, students have to check the eligibility criteria:</p>
                                                    <ul>
                                                        <li>Minimum 500 score in MAT exam</li>
                                                        <li>Minimum 50% aggregate (or equivalent) in Bachelor’s degree in any stream from a recognized university</li>
                                                    </ul>
                                                    <p>For application:
                                                    </p>
                                                    <ul>
                                                        <li>Notification for MBA Application Forms will be available on the Christ University official website.</li>
                                                        <li>The candidate should register online and fill in their personal information and academic details.</li>
                                                        <li>The college will short-list candidates for the Selection Process.</li>
                                                        <li>The Selection Process includes a written exam, personal interview, group discussion, micro-presentation, and evaluation of academic record.</li>
                                                        <li>After assessment, Christ University college will publish a list of selected candidates.</li>
                                                        <li>Candidates can complete the fees formalities to confirm admission.</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse24" className="collapsed">
                                                    <b>24.</b> How can I get direct admission in Christ University Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse24" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Christ University provides admission for eligible students through Merit Quota. Students should ensure that they fulfil all the eligibility criteria and can directly approach Christ University for admission formalities. Once selected, the university will send a direct notification to confirm the candidate’s admission.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse25" className="collapsed">
                                                    <b>25.</b> How can I verify the information provided by MBA colleges in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse25" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>In order to verify the information provided by management colleges in Bangalore:</p>
                                                    <ul>
                                                        <li>Candidates should verify college details and information with various online and offline sources.</li>
                                                        <li>The affiliation and accreditations of the college should be confirmed and verified with a government authorized body</li>
                                                        <li>The ratings and reviews of alumni faculty and students should be checked and verified from trusted websites or from information providers.</li>
                                                        <li>Review the previous year’s Passing Percentage</li>
                                                        <li>Placement Records and Statistics should be verified with reliable sources that are not related to the college</li>
                                                        <li>Contact and get advice from reputed and recognized Educational Advisors</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse26" className="collapsed">
                                                    <b>26.</b> What should I remember before selecting an MBA college in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse26" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Before selecting an MBA college in Bangalore candidates should remember:</p>
                                                    <ul>
                                                        <li>The college should be recognized and affiliated to a good university</li>
                                                        <li>The college should have proper approvals and affiliations</li>
                                                        <li>The fee structure of the course should be within the planned budget</li>
                                                        <li>It is important to ensure that the course is worth it by estimating the return of investment (ROI)</li>
                                                        <li>The college should have good infrastructure and facilities</li>
                                                        <li>It is important to check  how the colleges claim to provide 100% placement and placement assistance</li>
                                                        <li>The college should uphold culture, ethics, and holistic values</li>
                                                        <li>Verify with the college management and alumni students if there are any hidden fees.</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse27" className="collapsed">
                                                    <b>27.</b> How can I be aware of MBA unapproved college marketing?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse27" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>There are many colleges in Bangalore that focus on high-level advertising and marketing. These colleges:</p>
                                                    <ul>
                                                        <li>Have a very high budget for marketing techniques to attract the students.</li>
                                                        <li>Have trained associates who try to influence the students</li>
                                                        <li>Use online media and marketing strategies to highlight their college on web-based search engines like Google</li>
                                                        <li>They don’t have the infrastructure and facilities that are promised to candidates</li>
                                                        <li>The faculty and student ratio is not ideal and students are not given individual attention and focus as mentioned by them before admission.</li>
                                                        <li>Highlight false Placement Statistics to show 100% placement</li>
                                                    </ul>
                                                    <p>It is important not to depend only on Google information because most of the colleges give paid advertisements on Google so that they can come at the top of web-based searches. Most of the websites do not have updated information and this tends to mislead the students. Moreover, colleges pay educational portals to show their college name among the top of the list so that students believe that those are the top colleges</p>
                                                    <p>Students may tend to get attracted to this false information and should be aware and careful of these marketing tactics. These factors influence the students future and career; hence, students should be aware of the high-level advertising and marketing done by  colleges.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse28" className="collapsed">
                                                    <b>28.</b> What are the approvals and affiliations required for an MBA college?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse28" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>The approvals and affiliations required for:</p>
                                                    <p><b>Full-time Regular MBA</b></p>
                                                    <ul>
                                                        <li>AICTE approval</li>
                                                        <li>The college should be affiliated to a recognized university</li>
                                                        <li>State government approval</li>
                                                    </ul>
                                                    <p><b>Part-time/Online MBA</b></p>
                                                    <p>The approvals and affiliations are not significant because the course is not recognized by government universities. The program mostly focuses on the curriculum and outcome of the course.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse29" className="collapsed">
                                                    <b>29.</b> How are students cheated about MBA and management colleges in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse29" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Many students are cheated about MBA and management colleges in Bangalore. This is because of the strategies that unapproved and unrecognized colleges adopt to attract the attention of naive students.</p>
                                                    <p></p>
                                                    <p><b>These colleges:</b></p>
                                                    <ul>
                                                        <li>Set up a very high budget for advertising and marketing</li>
                                                        <li>Recruit trained officials to attract and influence the students</li>
                                                        <li>Use online digital marketing to increase the position and ranking of their college on web-based search engines</li>
                                                        <li>They don’t have the infrastructure that are promised to candidates</li>
                                                        <li>The faculty is not well qualified and are only Guest Lecturers</li>
                                                        <li>The faculty and student ratio does not ensure student attention and understanding</li>
                                                        <li>Promise additional packages like laptops and foreign trips  that are not provided</li>
                                                        <li>Graphics or photoshop-edited photographs of the college and facilities are used in Brochures and websites to attract the students</li>
                                                        <li>They focus on fake boosted Placement Records and Statistics</li>
                                                    </ul>
                                                    <p>Students may get attracted to this false information and get cheated during the admission process.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse30" className="collapsed">
                                                    <b>30.</b> Is it advisable to consult with an Educational Advisory before selecting a college?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse30" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>It is important to consult with an authorised Educational Advisory Centre before your MBA admission. This is because they provide:</p>
                                                    <ul>
                                                        <li>Genuine Ranking and Rating of colleges and universities</li>
                                                        <li>Comparison of different colleges and courses</li>
                                                        <li>Explanation and comparison of different specialisations and career scope</li>
                                                        <li>Dynamic industrial needs and requirements are highlighted and specializations are recommended</li>
                                                        <li>Genuine reviews are provided</li>
                                                        <li>Authentic Placement Records are provided to students</li>
                                                        <li>Hidden information and costs are openly discussed so that students are not cheated</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse31" className="collapsed">
                                                    <b>31.</b> How to prepare for competitive entrance examinations for MBA?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse31" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Candidates should prepare well for competitive MBA entrance examinations since an excellent score increases the chance of getting admission into a reputed management college.&nbsp;</p>
                                                    <p>Proper planning, time management, and preparation can help to ease the studying process and make the candidates prepared and better adapted to face entrance exams.&nbsp;</p>
                                                    <p>Some guidelines before beginning with the preparation for entrance examinations:</p>
                                                    <ul>
                                                        <li>Early organization, strategy, planning, and detailed preparation&nbsp;</li>
                                                        <li>Draft a time table and follow it for constant revision</li>
                                                        <li>Check the syllabus, marking scheme, and examination pattern of the examination</li>
                                                        <li>Purchase preparatory guides, study material, and question banks of the previous 5 years to analyse the question trend.</li>
                                                        <li>Be consistent and practice sample question papers, previous examination papers, and mock tests to be familiar with pattern and timing.</li>
                                                        <li>Learn and remember the basics and fundamental concepts of the syllabus</li>
                                                        <li>Hard Hardwork and determination is a necessity during preparation</li>
                                                        <li>It is important to avoid distractions like TV, music, and video games</li>
                                                        <li>Prepare and write down short notes and points for quick and regular reference and revision</li>
                                                        <li>Take the suggestions, examination tips, and guidance of expert Counsellors from Galaxy Educational Services</li>
                                                        <li>Time management is essential to effectively grasp maximum syllabus in less time</li>
                                                        <li>Take frequent breaks to avoid tiredness and over-exhaustion</li>
                                                        <li>Eat healthy by maintaining a balanced diet and avoiding junk food</li>
                                                        <li>Proper sleep is important to maintain physical and mental wellbeing</li>
                                                        <li>Make time for leisurely activities like yoga, meditation, sports, and walking</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse32" className="collapsed">
                                                    <b>32.</b> Which are the popular companies that provide placements for MBA graduates in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse32" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>There are many companies in Bangalore that provide placements for MBA graduates. Almost every company requires a qualified MBA graduate in the team for successful and profitable business.</p>
                                                    <p>Some of these companies are:</p>
                                                    <ul>
                                                        <li>Deloitte</li>
                                                        <li>J P Morgan &amp; Chase</li>
                                                        <li>L&amp;T Consultants</li>
                                                        <li>Amazon</li>
                                                        <li>Infosys</li>
                                                        <li>Cognizant</li>
                                                        <li>Ernst and Young</li>
                                                        <li>PricewaterhouseCoopers</li>
                                                        <li>Accenture</li>
                                                        <li>Biocon</li>
                                                        <li>Aditya Birla Group</li>
                                                        <li>HP</li>
                                                        <li>IBM</li>
                                                        <li>MPhasis</li>
                                                        <li>TCS</li>
                                                        <li>Cisco</li>
                                                        <li>Honeywell</li>
                                                        <li>Citibank</li>
                                                        <li>KPMG</li>
                                                        <li>Cerner Corporation</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse33" className="collapsed">
                                                    <b>33.</b> What are the job designations and roles available for MBA graduates?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse33" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>There are multiple job designations and roles available for MBA graduates in Bangalore. They include:</p>
                                                    <ul>
                                                        <li>Manager</li>
                                                        <li>CEO</li>
                                                        <li>Director</li>
                                                        <li>Entrepreneur</li>
                                                        <li>Consultant</li>
                                                        <li>Finance and Accounts Manager</li>
                                                        <li>Operations Manager</li>
                                                        <li>Human Resource Manager</li>
                                                        <li>Business Analyst</li>
                                                        <li>Quantitative Analyst</li>
                                                        <li>Credit and Risk Manager</li>
                                                        <li>Marketing and Sales Manager</li>
                                                        <li>Logistics and Supply Chain Manager</li>
                                                        <li>Hospital Administrator</li>
                                                        <li>Pharmaceutical Project Manager</li>
                                                        <li>Hospital Business Manager</li>
                                                        <li>Food &amp; Beverage Manager</li>
                                                        <li>Aviation Manager</li>
                                                        <li>Labor Relations Specialist</li>
                                                        <li>Marketing Manager</li>
                                                        <li>Small Business Owner</li>
                                                        <li>Business Consultant</li>
                                                        <li>Corporate Manager</li>
                                                        <li>Trials Executive</li>
                                                        <li>Administrative Service Manager</li>
                                                        <li>Rural Development Officer</li>
                                                        <li>Purchase/Vendor Development Manager</li>
                                                        <li>Planning Manager</li>
                                                        <li>Project Manager</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse34" className="collapsed">
                                                    <b>34.</b> How can an MBA graduate build a long-term and successful career?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse34" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>An MBA graduate can build a long-term and successful career in business management through:</p>
                                                    <ul>
                                                        <li>Adaptability in different situations</li>
                                                        <li>Skill enhancement and development</li>
                                                        <li>Personality building and development</li>
                                                        <li>Industry-oriented certifications</li>
                                                        <li>Building of Personal Skills - Communication, Analysis, Decision-making</li>
                                                        <li>Building of Professional Skills - Management, Leadership, Teamwork</li>
                                                        <li>Informed and Up-to-date with the latest industry trends, economic changes, and requirements</li>
                                                        <li>Constant learning of new industrial techniques and strategies</li>
                                                        <li>Learning new-age topics and getting certified in those subject areas</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse35" className="collapsed">
                                                    <b>35.</b> Can I trust all educational portals and Google information before selecting an MBA college in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse35" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>No, it is not a good practice to trust every educational portal online or Google information. This is because Google is itself a paid promotional search engine and most of the websites enlisted are done based on commercial reasons. In these listed websites, the information may not be regularly updated. In addition, the information available online may not be genuine and may only be posted to support promotional partners since these websites survive based on the income generated from college or institution advertisements. Thus, we suggest students not to completely trust the websites that show many college advertisements.&nbsp;</p>
                                                    <p>Rather than trust every online educational portal, online websites, or Google information, it is better for students to conduct their own research by asking alumni students for their opinion, taking advice from educational advisors, Government portals, and to carry out offline enquiries regarding MBA courses and colleges in Bangalore before finalizing one.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse36" className="collapsed">
                                                    <b>36.</b> Why should students depend on an authorised Advisory Centre for college selection?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse36" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Even though all college-related information is available on Google, there is no accurate or genuine information available. Most of the web-based platforms do not provide good college comparisons and genuine information because information is mostly for promotional purposes and to attract students to specific partner institutions and courses. An authorised Advisory Centre provides well-compared college information, genuine course details, authentic rankings, ratings and reviews, and genuine college information. They are experienced and efficient enough to inform about the merits and demerits of each college and course and can reveal hidden information about college approvals and affiliations. They also provide useful recommendations and suggestions for college admission, career planning, and other benefits. Therefore, it is useful to consult with an authorised Advisory Centre who can help with course and college selection and career guidance before the student finalizes their options.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse37" className="collapsed">
                                                    <b>37.</b> Which are the specializations available under Bangalore University for MBA in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse37" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Since Bangalore University is a government university, there are limited specializations officially available under Bangalore University. These include:</p>
                                                    <ul>
                                                        <li>Marketing</li>
                                                        <li>Finance</li>
                                                        <li>Human Resources</li>
                                                        <li>Healthcare Management</li>
                                                        <li>Banking, Insurance and Finance</li>
                                                        <li>Start-ups and SMEs Management</li>
                                                    </ul>
                                                    <p>Apart from these specializations, other colleges provide additional MBA specializations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse38" className="collapsed">
                                                    <b>38.</b> Which are the specializations available under Deemed universities for MBA in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse38" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>There is a wide variety of specializations for MBA in Bangalore available under Deemed universities like:</p>
                                                    <ul>
                                                        <li>Christ University</li>
                                                        <li>Ms Ramiah University</li>
                                                        <li>Jain University</li>
                                                        <li>Presidency University</li>
                                                        <li>Dayananda Sagar University</li>
                                                        <li>CMR University</li>
                                                    </ul>
                                                    <p>These universities provide a wide area of specializations like:</p>
                                                    <ul>
                                                        <li>Marketing</li>
                                                        <li>Finance</li>
                                                        <li>Human Resources</li>
                                                        <li>Healthcare Management</li>
                                                        <li>Logistics &amp; Supply Chain Management</li>
                                                        <li>Business Analytics</li>
                                                        <li>Banking, Insurance and Finance</li>
                                                        <li>Operations Management</li>
                                                        <li>Sport Management</li>
                                                        <li>Luxury Management</li>
                                                        <li>Aviation Business Management</li>
                                                        <li>Lean Operations &amp; Systems</li>
                                                        <li>International Business</li>
                                                        <li>Startups &amp; SMEs Management</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse39" className="collapsed">
                                                    <b>39.</b> Which are the MBA specializations available under Visvesvaraya Technical University (VTU)?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse39" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Visvesvaraya Technical University is the only Government technical university in Karnataka which conducts engineering and other technical courses. The MBA course provided by Visvesvaraya Technical University focuses on technical subjects with specific specializations. These include:</p>
                                                    <ul>
                                                        <li>Marketing</li>
                                                        <li>Finance</li>
                                                        <li>Human Resources</li>
                                                        <li>Business Analytics</li>
                                                        <li>Operations Management</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse40" className="collapsed">
                                                    <b>40.</b> MBA in which college is better in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse40" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>Indian Institute of Management (IIM) is the best college for management education in Bangalore and in India. This is because of the comprehensive curriculum and future career prospects. However, students are selected based on different parameters like academic performance and getting admission in IIM is quite difficult.&nbsp;</p>
                                                    <p>Apart fromIIM, other management colleges in Bangalore are:</p>
                                                    <ul>
                                                        <li>Christ University</li>
                                                        <li>MS Ramaiah Institute of Technology</li>
                                                        <li>Symbiosis Bangalore</li>
                                                        <li>Dayananda Sagar College of Engineering</li>
                                                        <li>Acharya Institute of Technology</li>
                                                        <li>CMR Institute of Technology</li>
                                                        <li>IFIM College</li>
                                                        <li>ISBR College</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse41" className="collapsed">
                                                    <b>41.</b> MBA in which university is better in Bangalore?</a>
                                                    </p>
                                                </div>
                                                <div id="collapse41" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                    <p>The universities in Bangaloreprovide multiple opportunitiesfor management education with a detailed curriculum and extensive career prospects. Top management universities in Bangalore are:</p>
                                                    <ul>
                                                        <li>Bangalore University - for students who want a strong academic foundation in management</li>
                                                        <li>Visvesvaraya Technical University - for students who want a strong technical foundation in management</li>
                                                        <li>Deemed Universities - for students who want more industrial exposure and jobs in private companies</li>
                                                        <li>Private Universities- for students who want more industrial exposure and jobs in private companies</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                    </div>
                </section>
            </div>
        :
        <h1> Can't connect to server ! </h1>
        :
        <div className="row">
            <div className="col-md-12 text-center">
                <ThreeDots
                visible={true}
                height="50"
                width="50"
                color="#black"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{textAlign:'center'}}
                wrapperclassName="loader"
                />
            </div>
      </div>
        );
    }
}

// export default Home;