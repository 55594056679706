import React from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import Pagination from './Pagination';
import { ThreeDots } from 'react-loader-spinner'
import striptags from 'striptags';

export default function Blogs() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [blogs, setBlogs] = useState([])
    const [notifications, setNotifications] = useState([])
	const [programs, setPrograms] = useState([])
	const [otherblogs, setOtherBlogs] = useState([])
    const mounted = useRef();
	const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(4);
	const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        fetchProduct();
    },[])
    const fetchProduct = async () => {
		setIsLoading(true)
        await axios.get(`/blogs-page-data`).then(({data})=>{
			setBlogs(data.blogs)
			setOtherBlogs(data.recentblogs)
			setPrograms(data.programs)
			setNotifications(data.notifications)
			setIsLoading(false)
        //   console.log(data);
        }).catch(({response:{data}})=>{
			setIsLoading(false)
			Swal.fire({
				text:'Error!.',
				icon:"error"
			})
        })
      }

     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

	  function truncate (str) {
		return str.length > 200 ? str.substring(0, 200) + "..." : str;
	  }

	  const indexOfLastRecord = currentPage * recordsPerPage;
	  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	  const currentRecords = blogs.slice(indexOfFirstRecord, indexOfLastRecord);
	  const nPages = Math.ceil(blogs.length / recordsPerPage)

  return (
	!isLoading ? 
	blogs!==null?
	<div>
	<Helmet>
		<title>Blogs | Top ranked MBA Colleges Bangalore | Management Colleges</title>
		<meta name="description" content="Read article on top MBA Colleges in Bangalore, eligibility criteria, mba admission, career prospects. Contact us for direct MBA admission."/>
		<meta name="keywords" content="best mba colleges in bangalore, direct mba admissions in bangalore, mba eligibility criteria in bangalore colleges, direct mba admissions 2021, articles related to mba course"/>
		<link rel="stylesheet" href="/assets/css/blogs.css"></link>
	</Helmet>
	<div className="blog-area pd-100">
		<div className="container">
			<div className="row">
				<div className="col-sm-8 bolg_side-left">
				{currentRecords && currentRecords.length > 0 && currentRecords.map((blog, index) => {
                	return (
						<div className="col-sm-12 single-item-box" key={`b${blog.id}`}>
							<div className="single-item">
								<div className="img-box">
									<Link to={`/blogs/${blog.slug}`}><img src={`/storage/blogs/${blog.image}`} alt="" className="img-responsive"/></Link>
									<span><Link to={`/blogs/${blog.slug}`} className="overlay"></Link></span>
									<div className="img-caption">
									<p className="date"><span>{blog.day}</span><span>{blog.month}</span></p>
									</div>
								</div>
								<div className="single-text-box">
									<h3><Link to={`/blogs/${blog.slug}`}>{blog.title}</Link></h3>
									<ul className="list-unstyled">
										<li><Link to={`/blogs/${blog.slug}`}>{blogDate(new Date(blog.created_at))}</Link></li>
										<li><Link to={`/blogs/${blog.slug}`}>{blog.comments} comments</Link></li>
									</ul>
									{striptags(truncate(blog.content))}
									<div className="blog-btn-box">
										<Link to={`/blogs/${blog.slug}`}>Read More</Link>
									</div>
								</div>
							</div>
						</div>
					);
				})}
					<div className="row">
					<div className="col-sm-12 text-center">
					<Pagination
						nPages={nPages}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}/>
						{/* <nav aria-label="Page navigation">
							<ul className="pagination">
							</ul>
						</nav> */}
					</div>
					</div>
				</div>
				<hr className="hidden-lg"/>
				<div className="col-sm-4 blog_side-right">
					<div className="sidebar-content">
					<div className="event-01">
						<div className="events-list-03">
							<div className="events-single-box examBox">
								<div className="row">
								<div className="col-sm-12 section-header-box" style={{marginBottom: '0'}}>
									<div className="section-header section-header-l">
										<h3>MBA PROGRAMS IN BANGALORE</h3>
									</div>
								</div>
								</div>
								<div className="event-info">
									<ul className="exam-icon-list list-unstyled" style={{maxHeight: '600px'}}>
									{programs && programs.length > 0 && programs.map((program, index) => {
                        			    return (
										<li><Link to={`/programs/${program.slug}`}>{program.program}</Link></li>
										);
									})}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="textBox" style={{background:'#fdb60e',marginBottom: '0'}}>
						<Link to="/mba-specialisations">
							<p>TRENDING MBA SPECIALISATIONS <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></p>
						</Link>
					</div>
					<div className="single-courses_v">
						<div className="single-courses-area" style={{width:'100%',padding:'20px 0'}}>
							<div className="single-curses-contert">
								<div className="leave_a_comment" style={{marginTop:'0'}}>
								<div className="notifications">
									<div className="row">
										<div className="col-sm-12 section-header-box">
											<div className="section-header section-header-l">
											<h2>NOTIFICATIONS</h2>
											</div>
										</div>
									</div>
									<div className="blog_1">
										<div className="blog-area">
											<div className="blog_side-right">
											<div className="recent-post-01">
												<div className="row">
												{notifications && notifications.length > 0 && notifications.map((notification, index) => {
                        			            	return (
														<div className="col-sm-12 recent-single" key={`n${notification.id}`}>
															<div className="recent-content-item">
																<div className="img-box">
																	<Link to={`/notifications/${notification.slug}`}>
																		<img className="lozad" src={`/storage/notifications/${notification.image2}`} alt={notification.title} data-loaded="true"/>
																	</Link>
																</div>
																<div className="recent-text pull-right">
																	<Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
																	<p>{blogDate(new Date(notification.updated_at))}</p>
																</div>
															</div>
														</div>
													);
												})}
												</div>
											</div>
											</div>
										</div>
									</div>
								</div>
								</div>
							</div>
						</div>
					</div>
					<div className="textBox" style={{background:'#54b145',marginBottom: '0'}}>
						<p><i className="fa fa-phone" aria-hidden="true"></i> Helpline: <a href="tel:+918095797575">+918095797575</a></p>
					</div>
					<div className="row">
						<div className="col-sm-12 recent-post-01">
							<hr/>
							<h3>Recent Blogs</h3>
							<div className="row">
							{otherblogs && otherblogs.length > 0 && otherblogs.map((otherblog, index) => {
                        		return (
								<div className="col-sm-12 recent-single">
									<div className="recent-content-item">
										<div className="img-box">
											<Link to={`/blogs/${otherblog.slug}`}>
												<img src={`/storage/blogs/${otherblog.image2}`} alt=""/>
											</Link>
										</div>
										<div className="recent-text pull-right">
											<Link to={`/blogs/${otherblog.slug}`}>{otherblog.title}</Link>
							                <p>{blogDate(new Date(otherblog.updated_at))}</p>
							            </div>
									</div>
								</div>
								);
							})}
							</div>
						</div>
						<div className="col-sm-12">
							<h3>Popular tags</h3>
							<div className="populer-tags">
								<div className="tagcloud">
								<a href="#">MBA</a>
								<a href="#">PGDM</a>
								<a href="#">Specialisations</a>
								<a href="#">MBA Blogs</a>
								<a href="#">MBA in Bangalore</a>
								<a href="#">Colleges in Bangalore</a>
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
			</div>
		</div>
		</div>
	</div>
	:
	<h1> Can't connect to server ! </h1>
	:
	<div className="row">
		<div className="col-md-12 text-center">
			<ThreeDots
			visible={true}
			height="50"
			width="50"
			color="#black"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{textAlign:'center', display:'block'}}
			wrapperclassName="loader"
			/>
		</div>
  </div>
  )
};

// export default NotificationDetails;