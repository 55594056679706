import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';

export default class About extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          reviews:[],
          isLoading:false
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        this.setState({isLoading:true})
        let usersData=await axios.get('/about-page-data')
                      .then(res=>{
                          this.setState({
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates
                          },()=>{
                            // console.log(this.state)
                          })
                          this.setState({isLoading:false})
                      })
                      .catch(err=>{
                          console.log(err);
                      })
      }

    render(){
        const {notifications}=this.state;
        const {reviews}=this.state;
        return (
          <div>
            <Helmet>
                <title>About us | Direct Admission for MBA 2024</title>
                <meta name="description" content="About us, Find out all questions and answers for MBA Admissions."/>
                <meta name="keywords" content="mba about, mba colleges questions, mba admission frequently asked questions, top mba colleges & faqs"/>
                <link rel="stylesheet" href="/assets/css/about.css"></link>
            </Helmet>
            <div className="single-courses-area">
              <div className="container">
                  <div className="row">
                    <div className="col-sm-8 sidebar-left">
                        <div className="single-curses-contert description-content">
                          <div className="row">
                              <div className="col-sm-10">
                                <div className="section-header-box">
                                    <div className="section-header section-header-l">
                                      <h2>ABOUT MBA STUDY IN BANGALORE</h2>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <p>MBA Study in Bangalore was established in 2016 with the main objective of assisting
                              management aspirants to choose their ideal MBA college and finalize an appropriate
                              specialization that would ensure personal and professional satisfaction. Since 2016, we have
                              helped and guided many candidates in selecting the best management college in Bangalore,
                              choosing a specialization to build the best career, and to strive towards a prosperous and
                              successful life. We have been instrumental in directly connecting candidates to the top B-
                              Schools in Bangalore where they have the freedom to choose the management course or
                              specialization of their choice with confidence.
                          </p>
                          <p><b>The exclusive services offered by MBA Study in Bangalore are:</b></p>
                          <ul>
                              <li>Updated information about Management Colleges in Bangalore</li>
                              <li>Genuine ranking and rating of MBA Colleges</li>
                              <li>Latest College notifications and updates</li>
                              <li>Admission process of different top B-Schools</li>
                              <li>Notifications about management Entrance Examination dates</li>
                              <li>Notifications about Government and Private Scholarship Exams</li>
                              <li>Genuine reviews about the College from alumni students and present students</li>
                              <li>Comparison of different Colleges and B-Schools</li>
                              <li>Providing the verified List of reputed Colleges from our Research Team</li>
                              <li>Providing information about fake Colleges and Universities</li>
                              <li>Suggestion of Colleges on the basis of ROI</li>
                              <li>Recommending new trending Specializations based on job demand</li>
                          </ul>
                          <hr/>
                          <p>MBA Study in Bangalore is the Number One educational portal for management education in
                              Bangalore. It has been recognized and approved by most of the top B-Schools in Bangalore.
                              Lakhs of students have benefitted from our exclusive services and prefer our website for the
                              latest updates about management colleges. Most of the leading MBA Coaching Centres in
                              India recommend our services for student’s course-related needs and updated information.
                          </p>
                          <p>If you are a management aspirant and want to study in the best management college in
                              Bangalore BUT you are confused about which college and specialization to choose, your
                              college search ends here. We provide actual information and comparison about MBA
                              specializations and colleges in Bangalore that help to make the process of finalizing your
                              dream B-School a smooth and easy process.
                          </p>
                          <p>As you know, there are more than 200 B-Schools and management courses in Bangalore
                              City, among which there are good colleges, reputed colleges, recognized colleges, non-
                              recognized and unapproved colleges. Some of the colleges don’t have proper affiliations and
                              approvals. There are many of such colleges spread across Bangalore that carry out fake
                              marketing on a large scale to grab the attention of candidates. These colleges conduct fake
                              advertising and marketing through eye-washing tactics that include providing free laptops,
                              promising foreign trips, promising very good placements in top management companies,
                              ensuring admission through online processes where candidates are not encouraged to visit the
                              college directly, by displaying only the attractive features of the college, and by highlighting
                              fake Placement Records to attract candidates. These colleges do not fulfil these promises
                              once admission is completed. They manipulate their ratings and reviews and candidates are
                              drawn to these reviews, only to get cheated later. These colleges do high level advertising and
                              marketing to attract uninformed candidates. We recommend candidates not to blindly trust
                              such marketing techniques without verifying with various sources. Rest assured, MBA Study
                              in Bangalore provides genuine and authentic information with actual reviews and ratings
                              about the top B-Schools in Bangalore.
                          </p>
                          <p>If you need any help or assistance in finding the latest details about <a href="https://www.mbaadmissionsinbangalore.com/" title="Top Management Colleges in Bangalore">Top Management Colleges in Bangalore</a>, you can contact us for more information. </p>
                        </div>
                    </div>
                    <div className="col-sm-4 single-courses_v">
                        <div className="sidebar-content single-courses-area" style={{width:'100%',padding:'20px 0'}}>
                          <div className="single-curses-contert">
                              <div className="leave_a_comment">
                                <div className="notifications">
                                    <div className="row">
                                      <div className="col-sm-12 section-header-box">
                                          <div className="section-header section-header-l">
                                            <h2>NOTIFICATIONS</h2>
                                          </div>
                                      </div>
                                    </div>
                                    <div className="blog_1">
                                      <div className="blog-area">
                                          <div className="blog_side-right">
                                            <div className="recent-post-01">
                                                <div className="row">
                                                {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                                                  return (
                                                    <div className="col-sm-12 recent-single" key={`n${notification.id}`}>
                                                      <div className="recent-content-item">
                                                        <div className="img-box">
                                                          <Link to={`/notifications/${notification.slug}`}>
                                                            <img className="lozad" src={`/storage/notifications/${notification.image2}`} alt={notification.title} data-loaded="true"/>
                                                          </Link>
                                                        </div>
                                                        <div className="recent-text pull-right">
                                                          <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                                          <p>{this.notificationDate(new Date(notification.updated_at))}</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="rating-box">
                          <div className="row">
                              <div className="col-sm-12">
                                <div className="head-text">
                                    <a href="{{url('colleges')}}">
                                      <h4>REVIEWS & RATINGS OF MBA COLLEGES IN BANGALORE</h4>
                                    </a>
                                </div>
                                <div className="rating content">
                                    <div className="row rate-box" style={{marginBottom: '0'}}>
                                      <div className="col-sm-12">
                                          <div className="recent-box text-left">
                                          {reviews && reviews.length > 0 && reviews.map((reviews, index) => {
                                            const oneStar = (reviews.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
                                            const twoStar = (reviews.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
                                            const threeStar = (reviews.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
                                            const fourStar = (reviews.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
                                            const fiveSar = (reviews.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
                                            return (
                                              <div className="recent" key={`r${reviews.id}`}>
                                                <h6><span><div className="rating-stars">
                                                  <ul id="">
                                                    <li className="star rate-this" title="1 star" data-value="1" style={{margin: '0'}}>
                                                    <i className={oneStar}></i>
                                                    </li>
                                                    <li className="star rate-this" title="2 stars" data-value="2" style={{margin: '0'}}>
                                                    <i className={twoStar}></i>
                                                    </li>
                                                    <li className="star rate-this" title="3 stars" data-value="3" style={{margin: '0'}}>
                                                    <i className={threeStar}></i>
                                                    </li>
                                                    <li className="star rate-this " title="4 stars" data-value="4" style={{margin: '0'}}>
                                                    <i className={fourStar}></i>
                                                    </li>
                                                    <li className="star rate-this " title="5 stars" data-value="5" style={{margin: '0'}}>
                                                    <i className={fiveSar}></i>
                                                    </li>
                                                  </ul> 
                                                </div></span> <span>|</span> {reviews.name} <span>|</span> <Link to={`/colleges/${reviews.url}`}>{reviews.college}</Link> <span>|</span> <span>{reviews.date}</span></h6>
                                                <p>{reviews.review}</p>
                                              </div>
                                            );
                                          })}
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="textBox" style={{background:'#fdb60e'}}>
                          <a href="https://www.pgdmcollegesinbangalore.com" target="_blank">
                              <p>CLICK TO KNOW ABOUT PGDM PROGRAMS IN BANGALORE <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></p>
                          </a>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
        );
    }
}

// export default Home;