import React from "react";
import {Link} from "react-router-dom";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { useState, useEffect, Component, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from "./Slider";
import { HashLink } from 'react-router-hash-link';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import countryFile from './pages/Country';
import Select from 'react-select'
import checkNumber from './pages/CheckMobileNumber';
import Marquee from "react-fast-marquee";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

function Navbar() {
    const url = window.location.pathname.split('/').pop();
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
    const [blogTitle, setBlogTitle] = useState('')
    const [notificationTitle, seNotificationTitle] = useState('')
    const [programTitle, seProgramTitle] = useState('')
    const [headerImage, seHeaderImage] = useState('')
    const [examTitle, seExamTitle] = useState('')
    const [blogImage, setBlogImage] = useState('');
    const [mobile, setMobileValue] = useState([]);
    const [events, setUpcomingEvents] = useState([]);
    const [headerclass, setHeaderClass] = useState('');
    const [specialisations, setSpecialisation] = useState([]);
    const [collegeTitle, setCollegeTitle] = useState('')
    const [collegeImage, setCollegeImage] = useState('')
    const [specialisationTitle, setSpecialisationTitle] = useState('')
    

     //assigning location variable
     const location = useLocation();
     //destructuring pathname from location
     const { pathname } = location;
     //Javascript split method to get the name of the path in array
     const splitLocation = pathname.split("/");  

    function selectCountry (event) {
        setStateValue([])
        const state_arr = countryFile.s_a[event.key].split("|"); 
        const stateArray = [];
        stateArray.push({
          value: '', 
          label: 'Select'
        });
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        setStates(stateArray)
        var Country = event.value;
        // console.log(mobile);
        let check = checkNumber.checkNumber(Country,mobile);
        if(check === 2){
            setMobileValue('');
        }
    }
        
    function selectRegion (val) {
        if(val.value !== '' && val.value !== null){
          setStateValue(val)
        } else {
          setStateValue([])
        }
    }

    function updateMobile(value){
		setMobileValue(value)
	}

    useEffect(()=>{
        const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
        gatAPIData();
    },[url])

    const gatAPIData = async () => {
        axios.get(`/upcoming-events`).then(({data})=>{
            setUpcomingEvents(data.exams)
            setSpecialisation(data.specialisations)
        }).catch(({response:{data}})=>{
            
        })
       
        if(splitLocation[1] === ""){
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            setHeaderClass('');
            seHeaderImage('');
        } else if(splitLocation[1] === "about-us"){
            document.body.classList.add('single-courses_v');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            setHeaderClass('about');
            seHeaderImage('/assets/images/about-banner.jpg');
        } else if(splitLocation[1] === "best-mba-colleges-in-bangalore"){
            document.body.classList.add('courses');
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            setHeaderClass('courses');
            seHeaderImage('/assets/images/spec-bg.jpg');
        } else if(splitLocation[1] === "mba-specialisations"){
            document.body.classList.add('courses');
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            setHeaderClass('courses');
            seHeaderImage('/assets/images/spec-bg.jpg');
        } else if(splitLocation[1] === "faq"){
            document.body.classList.add('single-courses_v');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            setHeaderClass('faqs');
            seHeaderImage('/assets/images/spec-bg.jpg');
        } else if(splitLocation[1] === "blogs" && !splitLocation[2]){
            document.body.classList.add('blog_1');
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('courses');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            setHeaderClass('faqs');
            seHeaderImage('/assets/images/blog-bg.jpg');
        } else if(splitLocation[1] === "contact"){
            document.body.classList.add('contact');
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('post-1');
            setHeaderClass('');
            seHeaderImage('/assets/images/cont-bg.jpg');
        } else if(splitLocation[1] === "programs"  && splitLocation[2]){
            document.body.classList.add('single-courses_v');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            setHeaderClass('mba-programs');
            axios.get(`/program-page-data/${splitLocation[2]}`).then(({data})=>{
                seProgramTitle(data.program.program)
                seHeaderImage('/storage/Program/'+data.program.image)
            }).catch(({response:{data}})=>{
                
            })
        } else if(splitLocation[1] === "colleges"  && splitLocation[2]){
            document.body.classList.add('single-courses_v');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            setHeaderClass('college');
            axios.get(`/college-page-data/${splitLocation[2]}`).then(({data})=>{
                setCollegeTitle(data.college.college)
                seHeaderImage('/assets/images/college-bg.jpg')
            }).catch(({response:{data}})=>{
                
            })
        } else if(splitLocation[1] === "specialisations"  && splitLocation[2]){
            document.body.classList.add('single-courses_v');
            setHeaderClass('aviation');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            axios.get(`/specialisation-page-data/${splitLocation[2]}`).then(({data})=>{
                setSpecialisationTitle(data.specialisation.category)
            }).catch(({response:{data}})=>{
                
            })
            seHeaderImage('/assets/images/aviation-bg.jpg')
        } else if(splitLocation[1] === "exams"  && splitLocation[2]){
            document.body.classList.add('single-courses_v');
            setHeaderClass('exam');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            seHeaderImage('/assets/images/exam-bg.jpg')
            axios.get(`/exam-page-data/${splitLocation[2]}`).then(({data})=>{
                seExamTitle(data.exam.exam_name)
            }).catch(({response:{data}})=>{
                
            })
        } else if(splitLocation[1] === "notifications"  && splitLocation[2]){
            document.body.classList.add('post-1');
            // setHeaderClass('');
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('courses');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            seHeaderImage('/assets/images/mba-adm-bg.jpg')
            axios.get(`/notification-page-data/${splitLocation[2]}`).then(({data})=>{
                seNotificationTitle(data.notification.title)
            }).catch(({response:{data}})=>{
                
            })
        } else if(splitLocation[1] === "list-of-colleges-offering-direct-admission-for-mba-in-bangalore"){
            document.body.classList.add('courses');
            // setHeaderClass('');
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('post-1');
            seHeaderImage('/assets/images/spec-bg.jpg')
        } else if(splitLocation[1] === "galaxy-educational-services-reviews"){
            document.body.classList.add('post-1');
            // setHeaderClass('');
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('courses');
            seHeaderImage('/assets/images/spec-bg.jpg')
        } else if(splitLocation[1] === "blogs"  && splitLocation[2]){
            document.body.classList.add('post-1');
            // setHeaderClass('');
            document.body.classList.remove('single-courses_v');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('courses');
            seHeaderImage('/assets/images/mba-adm-bg.jpg')
            axios.get(`/blog-page-data/${splitLocation[2]}`).then(({data})=>{
                setBlogTitle(data.blog.title)
            }).catch(({response:{data}})=>{
                
            })
        } else if(splitLocation[1] === "differences"){
            document.body.classList.add('single-courses_v');
            setHeaderClass('faqs');
            document.body.classList.remove('post-1');
            document.body.classList.remove('blog_1');
            document.body.classList.remove('contact');
            document.body.classList.remove('courses');
            seHeaderImage('/assets/images/spec-bg.jpg')
        }
    }

    const renderMessage = () => {
        return <p>Welcome back!</p>;
    };
    const handleClick = (myLink) => () => {
        window.location.href=myLink;
    }
    
    const mystyle = {
        backgroundColor: '#563d16',
        opacity: '.9 !important',
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'scroll',
		backgroundImage: '/assets/img/titlebar-img.jpg'
      };
   
    
    function closeMenu()
    {
        // jQuery('.meanmenu-reveal').removeClass('meanclose');
        jQuery('.mega-menu-toggle').removeClass('mega-menu-open');
    }

    function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        jQuery("#overlay").fadeIn(300);　
        jQuery('#apply-now').modal('hide');
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset();
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }
    return (
        <header id="header">
            <div className="header-top-p">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-xs-12 header-top-left">
                            <div className="row">
                            <div className="col-sm-4 col-md-3 col-lg-3 col-xs-12">
                            <p className="exam-p"><i className="fa fa-calendar" aria-hidden="true"></i> UPCOMING EXAMS & EVENTS</p>
                            </div>
                            <div className="col-sm-8 col-md-9 col-lg-9 col-xs-12">
                            <div className="basim-mq">
                            <Marquee speed={1} pauseOnHover={true} autoFill>
                                {events && events.length > 0 && events.map((event, index) => {
                                    return ( 
                                        <p key={`n${event.id}`}><i className="fa fa-dot-circle-o" aria-hidden="true"></i> {event.exam} <span>On {event.exam_date}</span></p>
                                    );
                                })}
                            </Marquee>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-xs-12 hidden-xs header-top-left">
                            <ul className="list-unstyled">
                                <Link to="best-mba-colleges-in-bangalore"><li>All MBA Colleges</li></Link>
                                <Link to="blogs/why-prefer-an-mba-education-in-bangalore"><li>Why MBA in Bangalore?</li></Link>
                                <HashLink smooth to="/#exams"><li>Entrance Exams</li></HashLink>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-xs-12 header-top-right hidden-xs">
                            <ul className="list-unstyled">
                                <a href="#apply-now" data-toggle="modal"><li className="header-side-btn">Apply Now</li></a>
                                <a href="#apply-now" data-toggle="modal"><li className="header-side-btn">Get Direct Admission now</li></a>
                                <li>Helpline: <a href="tel:+918095797575"> +918095797575</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`header-body ${headerclass}`} style={{backgroundImage: `url(${headerImage})` }}>
                <nav className="navbar edu-navbar main-navigation" id="site-navigation" aria-label="Primary Menu" data-sticky-height="70">
                    <div className="container">
                        <div className="navbar-header">
                            <Link to="/" className="navbar-brand  data-scroll">
                                <img src="/assets/images/logo.png" alt=""/>
                                <span>MBA Study in Bangalore</span><p>&nbsp;&nbsp;Get connected to Top B-Schools</p>
                            </Link>
                        </div>

                        <div className="collapse navbar-collapse edu-nav main-menu mega-menu-wrap"  id="mega-menu-wrap-tm-main-menu" >
                        <div className="mega-menu-toggle">
                                    <div className="mega-toggle-blocks-right">
                                        <div className='mega-toggle-block mega-menu-toggle-block mega-toggle-block-1' id='mega-toggle-block-1' tabIndex='0'> <span className='mega-toggle-label' role='button' aria-expanded='false'></span>
                                        </div>
                                    </div>
                                  </div>
                            <ul id="mega-menu-tm-main-menu" className="nav navbar-nav pull-right mega-menu max-mega-menu mega-menu-horizontal mega-no-js" data-event="hover_intent" data-effect="fade_up" data-effect-speed="200" data-effect-mobile="disabled" data-effect-speed-mobile="200" data-mobile-force-width="false" data-second-click="go" data-document-click="collapse" data-vertical-behaviour="standard" data-breakpoint="1200" data-unbind="true">
                                <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/" tabIndex="0">Home</Link></li>
                                <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "about-us" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/about-us" tabIndex="0">About us</Link></li>
                                <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "best-mba-colleges-in-bangalore" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/best-mba-colleges-in-bangalore" tabIndex="0">Colleges</Link></li>
                                <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "mba-specialisations" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/mba-specialisations" tabIndex="0">Specialisations</Link></li>
                                <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "faq" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/faq" tabIndex="0">FAQs</Link></li>
                                <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "blogs" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/blogs" tabIndex="0">Blogs</Link></li>
                                <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "contact" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/contact" tabIndex="0">Contact us</Link></li>
                                <li className="nav-pgdm-btn"><a className="mega-menu-link" href="https://www.pgdmcollegesinbangalore.com" target="_blank">Top PGDM Colleges <br className="hidden-xs"/>in Bangalore</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* <nav id="site-navigation" className="main-navigation" aria-label="Primary Menu" data-sticky-height="70">
                              <div id="mega-menu-wrap-tm-main-menu" className="mega-menu-wrap">
                                  <div className="mega-menu-toggle">
                                    <div className="mega-toggle-blocks-left"></div>
                                    <div className="mega-toggle-blocks-center"></div>
                                    <div className="mega-toggle-blocks-right">
                                        <div className='mega-toggle-block mega-menu-toggle-block mega-toggle-block-1' id='mega-toggle-block-1' tabIndex='0'> <span className='mega-toggle-label' role='button' aria-expanded='false'><span className='mega-toggle-label-closed'>MENU</span><span className='mega-toggle-label-open'>MENU</span></span>
                                        </div>
                                    </div>
                                  </div>
                                  <ul id="mega-menu-tm-main-menu" className="mega-menu max-mega-menu mega-menu-horizontal mega-no-js" data-event="hover_intent" data-effect="fade_up" data-effect-speed="200" data-effect-mobile="disabled" data-effect-speed-mobile="200" data-mobile-force-width="false" data-second-click="go" data-document-click="collapse" data-vertical-behaviour="standard" data-breakpoint="1200" data-unbind="true">
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/" tabIndex="0">Home</Link></li>
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "about-us" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/about-us" tabIndex="0">About us</Link></li>
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "colleges" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/colleges" tabIndex="0">BCA Colleges</Link></li>
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "blogs" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/blogs" tabIndex="0">Blogs</Link></li>
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "contact-us" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/contact-us" tabIndex="0">Contact us</Link></li>
                                    <li onClick={closeMenu} className="mega-menu-item mega-menu-item notification-btn last"><HashLink smooth className="mega-menu-link" to="/#notifications" tabIndex="0"><span className="bell fa fa-bell"></span> Notifications</HashLink></li>
                                  </ul>
                              </div>
                            </nav> */}
                {(() => {
                if(splitLocation[1] !== ''){
                    return(
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                {(() => {
                                    if(splitLocation[1] === "about-us"){
                                        return(
                                            <div className="intro-text ">
                                                <h1>ABOUT MBA STUDY IN BANGALORE</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">About</span></p>
                                            </div>
                                        );
                                    } else if(splitLocation[1] === "best-mba-colleges-in-bangalore"){
                                        return(
                                            <div className="intro-text ">
                                                <h1>MBA ADMISSION IN TOP MANAGEMENT COLLEGES IN BANGALORE</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">Colleges</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "mba-specialisations"){
                                        return(
                                            <div className="intro-text ">
                                                <h1>Specialisations In MBA</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">Specialisations</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "faq"){
                                        return(
                                            <div className="intro-text ">
                                                <h1>Frequently Asked Questions</h1>
                                                <p><span><Link to="/">Home</Link><i className='fa fa-angle-right'></i></span> <span className="b-active">FAQs</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "blogs"   && !splitLocation[2]){
                                        return(
                                            <div className="intro-text ">
                                                <h1>Blogs</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">Blogs</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "contact"){
                                        return(
                                            <div className="intro-text ">
                                                <h1>Contact us</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">Contact</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "programs"  && splitLocation[2]){
                                        return(
                                            <div className="intro-text ">
                                                <h1>{programTitle}</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">{programTitle}</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "colleges"  && splitLocation[2]){
                                        return(
                                            <div className="intro-text ">
                                                <h1>{collegeTitle}</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span><Link to="/best-mba-colleges-in-bangalore">Colleges</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">{collegeTitle}</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "specialisations"  && splitLocation[2]){
                                        return(
                                            <div className="intro-text ">
                                                <h1>{specialisationTitle}</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span><Link to="/mba-specialisations">Specialisations</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">{specialisationTitle}</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "exams"  && splitLocation[2]){
                                        return(
                                            <div className="intro-text ">
                                                <h1>{examTitle}</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">{examTitle}</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "notifications"  && splitLocation[2]){
                                        return(
                                            <div className="intro-text "> 
                                                <h1>{notificationTitle}</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">Notifcations</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "list-of-colleges-offering-direct-admission-for-mba-in-bangalore"){
                                        return(
                                            <div className="intro-text ">
                                                <h1>List of Colleges Offering Direct Admission for MBA in Bangalore</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">List of Colleges</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "galaxy-educational-services-reviews"){
                                        return(
                                            <div className="intro-text ">
                                                <h1>Galaxy Educational Services Reviews</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">Reviews</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "blogs"  && splitLocation[2]){
                                        return(
                                            <div className="intro-text "> 
                                                <h1>{blogTitle}</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span><Link to="/blogs">Blogs</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">{blogTitle}</span></p>
                                            </div>
                                        )
                                    } else if(splitLocation[1] === "differences"){
                                        return(
                                            <div className="intro-text ">
                                                <h1>Difference between Entrance Exams</h1>
                                                <p><span><Link to="/">Home</Link> <i className='fa fa-angle-right'></i></span> <span className="b-active">Differences</span></p>
                                            </div>
                                        )
                                    }
                                })()}
                                </div>
                            </div>
                        </div>
                    );
                    }
            })()}
            </div>
            {(() => {
                if(splitLocation[1] === ""){
                    return(
                        <div className="rev_slider_wrapper">
                            <Swiper modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} spaceBetween={0} slidesPerView={1} simulateTouch={false} onSwiper={(swiper) => console.log('')} loop={true}  cssMode={true} mousewheel={false} autoplay={{delay: 3000, disableOnInteraction: false}} className="home-slider" navigation={{ nextEl: ".arrow-next-s", prevEl: ".arrow-prev-s" }}>
                                <SwiperSlide>
                                    <div className="slider-image">
                                        <img src="/assets/images/slider-mba.jpg" alt="mba admission bangalore" className="rev-slidebg"/>
                                        <div className="mask"></div>
                                    </div>
                                    <div className="slider-content-wrap">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="slider-content text-center">
                                                        <h1>Top MBA Colleges in Bangalore</h1>
                                                        <Link to="/best-mba-colleges-in-bangalore" className="btn btn-success">Find your college</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="slider-image">
                                        <img src="/assets/images/slider-mba2.jpg" alt="mba admission bangalore" className="rev-slidebg"/>
                                        <div className="mask"></div>
                                    </div>
                                    <div className="slider-content-wrap">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="slider-content text-center">
                                                        <h1>Most Popular Specialisations in Bangalore</h1>
                                                        <Link to="/mba-specialisations" className="btn btn-success">View specialisations</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <div className="arrow">
                              <button className="arrow-prev-s"><i className="fa fa-angle-left"></i></button>
                              <button className="arrow-next-s"><i className="fa fa-angle-right"></i></button>
                            </div>
                        </div>
                    );
                }
            })()}
            <div className="modal fade" id="apply-now" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Apply Now!</h4>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="apply-now">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input className="form-control" name="name" placeholder="Enter your name" required="" type="text"/>
                                                <input type="hidden" name="source" value="Navbar"/>
                                            </div>
                                        </div>

                                        <div className="col-xs-12">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input className="form-control" name="email" placeholder="Enter your Email" required="" type="email"/>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <input className="form-control EnquiryMobile" name="phone" value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} placeholder="Enter your mobile number" required="" type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="form-group">
                                            <label>Specialisation</label>
                                            <select className="form-control SpecialSelect" name="specialisation" required>
                                                <option value="">Select your specialisation</option>
                                                {specialisations && specialisations.length > 0 && specialisations.map((specialisation, index) => {
                        			                return (
                                                        <option key={index} value={specialisation.category}>{specialisation.category}</option>
                                                    )
                                                })}
                                            </select>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <Select classNamePrefix="CountrySelect"  options={countries} name="country" className="select-country"  placeholder="Select Country" required onChange={selectCountry} />
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="form-group">
                                                <label>State</label>
                                                <Select classNamePrefix="StateSelect" options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={selectRegion} />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 register-btn-box">
                                            <button className="register-btn" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div id="overlay">
                <div className="cv-spinner">
                    <span className="spinner"></span>
                </div>
            </div>
        </header>
    );
};

export default Navbar;