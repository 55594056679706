import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
// import Records from "./Records"
import Pagination from './Pagination';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

function App() {

    // To hold the actual data
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [style, setStyle] = useState(20)

    const inputElement = useRef();

    const [isActive, setActive] = useState(false);

    const [isOpen, setOpen] = useState(false);
    const [videoId, setVideoId] = useState('L61p2uyiMSo');

    const toggleClass = () => {
        setActive(!isActive);
      };

    useEffect(() => {
        axios.get('/test-data')
            .then(res => {
              console.log()
                    setData(res.data);
                    setLoading(false);
                })
                .catch(() => {
                    alert('There was an error while retrieving the data')
                })
    }, [])


    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data.length / recordsPerPage)

    return (
        <div className='container mt-5'>
            
            <h2> Simple Pagination Example in React </h2>
            {/* <Records data={currentRecords}/> */}
            <table className="table">
              <thead>
                  <tr>
                      <th scope='col'>ID</th>
                      <th scope='col'>First Name</th>
                      <th scope='col'>Last Name</th>
                      <th scope='col'>City</th>

                  </tr>
              </thead>
              <tbody>
                  {currentRecords.map(item => (
                      <tr>
                          <td>{item.id} </td>
                          <td>{item.first_name} </td>
                          <td>{item.last_name} </td>
                          <td>{item.city} </td>
                      </tr>
                  ))}
              </tbody>
            </table>
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <React.Fragment>
      <ModalVideo
				channel="youtube"
				youtube={{ mute: 0, autoplay: 0 }}
				isOpen={isOpen}
				videoId={videoId}
				onClose={() => setOpen(false)} 
			/>
      <button className="btn-primary" onClick={() => setOpen(true)}>
        VIEW DEMO
      </button>
    </React.Fragment>

<div>
                <div id="exams" className={isActive ? null : 'exam-closed'}  ref={inputElement}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem IpsumLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</div>
                <a onClick={toggleClass} href='#!'>{isActive ? 'View less' : 'View more'}</a>
            </div>
            {/* <div class="themetechmount-pagination">
        
                <a class="prev page-numbers" href="#"><i class="tm-acadevo-icon-arrow-left"></i> 
                  <span class="tm-hide tm-pagination-text tm-pagination-text-prev">Previous page</span>
                </a>
                <span aria-current="page" class="page-numbers current">1</span>
                <a class="page-numbers" href="https://www.bcacollegesinbangalore.com/blogs?page=2">2</a>
                <a class="next page-numbers" href="https://www.bcacollegesinbangalore.com/blogs?page=2">
                  <span class="tm-hide tm-pagination-text tm-pagination-text-next">Next page</span> <i class="tm-acadevo-icon-arrow-right"></i>
                </a>

                <div class="themetechmount-pagination">
                
                </div>
            </div> */}
        </div>
    );
}

export default App;