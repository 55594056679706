import React from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'
import { HashLink } from 'react-router-hash-link';

export default function FAQS() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [notifications, setNotifications] = useState([])
    const [programs, setPrograms] = useState([])
    const [meta, setMeta] = useState('')
    const [countries, setCountry] = useState([])
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
    const [mobile, setMobileValue] = useState([]);
    
    function selectCountry(event) {
        const selectedCountryLabel = event.target.value;
        setStateValue("");
        const selectedCountry = countries.find(country => country.label === selectedCountryLabel);

        if (selectedCountry) {
            const state_arr = countryFile.s_a[selectedCountry.key]?.split("|") || [];
            const stateArray = state_arr.map(state => ({ value: state, label: state }));
            setStates(stateArray);
            
            let check = checkNumber.checkNumber(selectedCountryLabel, mobile);
            if (check === 2) {
                setMobileValue("");
            }
        }
    }
    
    function updateMobile(value){
      setMobileValue(value)
    }
      
    function selectRegion(event) {
        const selectedStateValue = event.target.value;
        setStateValue({ value: selectedStateValue, label: selectedStateValue });
    }

    useEffect(()=>{
        fetchProduct(params.id);
        const countryArray = [];
        // console.log(countryFile);
        var country1 = countryFile.country_arr;
        
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
    },[params.id])
    const fetchProduct = async () => {
        setIsLoading(true)
        await axios.get(`/faqs-page-data`).then(({data})=>{
            setNotifications(data.notifications)
            setPrograms(data.programs)
          // console.log(data);
          setIsLoading(false)
        }).catch(({response:{data}})=>{
          setIsLoading(false)
          Swal.fire({
            text:'Error!.',
            icon:"error"
          })
        })
      }
     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ ' ' +month+', ' +year;
      }

      function commentDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ '-' +month+'-' +year;
      }

      function enquirySubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset();
            jQuery('.EnquiryMobile').val('');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

      function submitComment(event){
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-comment', formData)
        .then(({data})=>{
            event.target.reset();
            fetchProduct()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }
  return (
    !isLoading ? 
    notifications!==null?
        <div>
            <Helmet>
                    <title>Frequently Asked Questions - FAQs for MBA | Direct Admission for MBA 2024-25</title>
                    <meta name="description" content="MBA Frequently Asked Questions - FAQ, Find out all questions and answers for MBA Admissions"/>
                    <meta name="keywords" content="mba faq, mba colleges questions, mba admission frequently asked questions, top mba colleges & faqs"/>
                    <link rel="stylesheet" href="/assets/css/faqs.css"></link>
            </Helmet>
            <div className="single-courses-area">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 sidebar-left">
                            <div className="single-curses-contert">
                            <h2>FAQs</h2>
                            <div className="curriculum-text-box">
                                <div className="curriculum-section">
                                    <div className="panel-group" id="accordion">
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                                <b>1.</b> What is the difference between MBA and PGDM degree?</a>
                                            </p>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse in">
                                            <div className="panel-body">
                                                <p>A Master of Business Administration (MBA) degree is a theory-based program that
                                                    focuses on business and finance fundamentals. It is taught in universities and colleges
                                                    that are University Grants Commission (UGC) affiliated. Courses are rigid and have
                                                    common curriculum because universities are unified and affiliated to a common primary
                                                    university. The fee structure is less expensive because of multiple university grants and
                                                    scholarships. Focus is more on technical and analytical knowledge.
                                                </p>
                                                <p>A Post Graduate Diploma in Management (PGDM) is a diploma or certification. It is an
                                                    application-based program that focuses on real-world practical skills of management. It is
                                                    taught in colleges that are recognized by All India Council for Technical Education
                                                    (AICTE). The course is flexible and involves case studies, on-site trainings, and
                                                    simulations. The curriculum is not centralized and differs from individual colleges. The
                                                    fee structure is more expensive than MBA since financial aid is not provided. Focus is
                                                    more on soft skills, practical knowledge, and employability.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse2" className="collapsed">
                                                <b>2.</b> Can a student with science background without any exposure to Business and Finance pursue an MBA degree?</a>
                                            </p>
                                        </div>
                                        <div id="collapse2" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Yes, MBA programs are applicable to students from any educational background as long
                                                    as they can acquire a minimum of 45 to 50% aggregate (or CGPA) from their
                                                    undergraduate course. Starting trouble is unavoidable, but after understanding the basic
                                                    concepts of mathematics, finances, economics, and marketing, the candidate is prepared
                                                    to face real-world situations in the business sector. In addition, additional skills can be
                                                    acquired practically on-the-job by accumulating experience.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse3" className="collapsed">
                                                <b>3.</b> Should only individuals interested in leadership roles take up an MBA course?</a>
                                            </p>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>An MBA is a versatile degree which has diverse specializations and multiple applications
                                                    in different professional domains. Candidates with a good educational background,
                                                    excellent communication skills, and creativity can be transformed into valuable resources
                                                    for different domains and sectors. People from different educational backgrounds can join
                                                    an MBA program to specialize fields like Finance, Human Resources, Education,
                                                    Fashion, Information Technology etc. in roles as Analysts, Strategists, Consultants,
                                                    Developers, Advisors, Planners, and Managers.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse4" className="collapsed">
                                                <b>4.</b> Is prior work experience mandatory before applying for an MBA course?</a>
                                            </p>
                                        </div>
                                        <div id="collapse4" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>No, prior work experience is not mandatory for applying to an MBA course; however,
                                                    some degree of exposure to a work environment is beneficial. Insights from prior work
                                                    experience will add practical exposure in real-world business situations. The course will
                                                    carry more quality compared to quantity. Prior experience will also contribute to practical applications of theoretical knowledge in the class room course. In case a
                                                    candidate does not have a strong academic background, work experience will prove that
                                                    the candidate is capable of managing the complete course curriculum.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse5" className="collapsed">
                                                <b>5.</b> Are MBA courses only for entrepreneurs and people interested in beginning a startup?</a>
                                            </p>
                                        </div>
                                        <div id="collapse5" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>No, MBA courses are for anyone interested in learning the core business framework and
                                                    working of a company. If an individual is passionate about learning new things and
                                                    finding solutions to business problems they can pursue an MBA. After completing the
                                                    course, candidates can take up various roles based on specialization in order to learn and
                                                    grow in the company hierarchy. Some candidates contribute to the back-end growth of
                                                    business by pursuing a PhD in management and becoming a subject matter expert or
                                                    Consultant.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse6" className="collapsed">
                                                <b>6.</b> What is the difference between MBA and EMBA degree?</a>
                                            </p>
                                        </div>
                                        <div id="collapse6" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>An MBA degree is mostly pursued by fresh graduates or working professionals who wish
                                                    to learn the theoretical aspects of business. It is usually a two-year self-funded (or with
                                                    financial aid) course. Qualification of national, state, or institution-specific entrance
                                                    exams is mandatory to pursue a regular MBA.
                                                </p>
                                                <p>An Executive MBA (EMBA) is commonly pursued by working professionals who hold
                                                    various leadership and management positions in the organization. The course is usually
                                                    less than two years in duration and does not require scores of competitive examinations.
                                                    It is usually funded by the employer of the company and mainly targets corporate
                                                    executives and managers.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse7" className="collapsed">
                                                <b>7.</b> Which is better, a full-time or online MBA program?</a>
                                            </p>
                                        </div>
                                        <div id="collapse7" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>A full-time MBA program is a classroom based degree program. Classes are conducted in
                                                    colleges and institutes that are autonomous or affiliated to the main university.
                                                    Curriculum is rigid and closely regulated to ensure scheduled completion of subjects.
                                                    Candidate attendance is constantly monitored. Working simultaneously with learning is
                                                    not encouraged to ensure complete concentration and discipline.
                                                </p>
                                                <p>An online MBA program is a home-based degree program. Classes are conducted via
                                                    online portals and solely rely on a stable and consistent internet connection. Institutes
                                                    may not always be accredited or affiliated to reputed universities. Curriculum can be
                                                    completed as per convenience which leads to the probability of delays. Candidates are
                                                    free to work while earning; however, proper focus, discipline, and management is
                                                    essential or candidates may incur losses due to irregularities.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse8" className="collapsed">
                                                <b>8.</b> How do I select the institution for my MBA program?</a>
                                            </p>
                                        </div>
                                        <div id="collapse8" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>There are few key points to keep in mind before you select an institution to pursue an
                                                    MBA program:
                                                </p>
                                                <ul>
                                                    <li><strong>Reputation of the institution:</strong>&nbsp;this is the most important factor as students from an institution are judged based on the university they graduate from. The university should be accredited or recognized by a reputed universal body.</li>
                                                    <li><strong>Ranking:</strong>&nbsp;the local, national, and global ranking of the institution should be checked prior to applying. A good ranking denotes how much better an institute is in comparison to competitors.</li>
                                                    <li><strong>Location:</strong>&nbsp;this is also very important as the institution should be easily accessible for travel and to obtain basic supplies.</li>
                                                    <li><strong>Curriculum:</strong>&nbsp;the availability of the subject of choice should be considered before finalizing an institution. The specialization and choice of electives should align with the overall goal of the program. In addition to theoretical knowledge, practical and analytical skills should also be encouraged.</li>
                                                    <li><strong>Fee structure:</strong>&nbsp;the feasibility of a course varies for different individuals. A person&rsquo;s budget and availability of financial aid should be considered before selecting an institution.</li>
                                                    <li><strong>Scholarships:</strong>&nbsp;the availability of grants and scholarships should be considered before applying for an MBA course. Some courses may be expensive and scholarship availability makes obtaining the degree affordable. Some students choose to earn while learning in order to pay their education fees, but this leads to overworking and exhaustion. Hence, a scholarship is preferred to ensure an uninterrupted education.</li>
                                                    <li><strong>Facilities:</strong>&nbsp;it is essential to check for on-campus facilities to ensure that candidates are comfortable and receive basic necessities. Resources like fully furnished laboratories, projectors, stationary outlets, hostels, and canteens should be checked for before applying to an institution.</li>
                                                    <li><strong>Student and Faculty ratio:</strong>&nbsp;the ratio of students to faculty should be small to ensure that an individual receives as much attention to their academics as possible. The teachers have to be highly qualified with plenty of teaching experience. It is an important factor to determine the effectively of a course.</li>
                                                    <li><strong>Extracurricular activities:</strong>&nbsp;it is ideal if an institution promotes both academic and extracurricular activities. Extracurricular activities include sports, creativity, and talent centered activities that enhance the overall development of an individual.</li>
                                                    <li><strong>Placements:</strong>&nbsp;it is important to check if an institution provides assistance in placement. One of the primary reasons for taking up an MBA course is career progress and growth; hence, it is essential to know if the institution has collaborations with reputed recruiting partners.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse9" className="collapsed">
                                                <b>9.</b> Are expensive MBA programs from prestigious institutions better than low-budget MBA courses?</a>
                                            </p>
                                        </div>
                                        <div id="collapse9" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>No, the quality of the MBA program is not entirely dependent on the fee structure or the
                                                    institution it is obtained from. The main consideration is what is understood and
                                                    interpreted during the course and how it is applied to the business in future. Indeed, an
                                                    MBA obtained from a reputed institution does hold a lot of value; however, smaller
                                                    learning facilities are also known to be beneficial in providing good quality education.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse10" className="collapsed">
                                                <b>10.</b> Is a foreign MBA better than an MBA done in India?</a>
                                            </p>
                                        </div>
                                        <div id="collapse10" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Both foreign and Indian MBA degrees have almost the same commercial and
                                                    professional value; however, certain factors determine which of the two is preferred for
                                                    an individual.
                                                </p>
                                                <p>A foreign MBA is preferred when an individual aspires to work abroad after course
                                                    completion. If the candidate has the budget to afford a foreign MBA degree and can
                                                    additionally organize and manage living expenses, they can pursue in a foreign country.
                                                    A foreign degree provides additional benefits of exposure to diverse cultures which
                                                    cannot be obtained in India.
                                                </p>
                                                <p>If an individual aspires to work in India, an MBA can be pursued in India. Institutes like
                                                    Indian Institute of Management (IIM) provide MBA courses of international quality so
                                                    that the candidate can not only receive a reputed degree but also continue to work in
                                                    India.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse11" className="collapsed">
                                                <b>11.</b> Is there a maximum age limit to apply for an MBA degree?</a>
                                            </p>
                                        </div>
                                        <div id="collapse11" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>No, there is no specified age limit to apply for and pursue an MBA degree. However, it is
                                                    recommended to begin an MBA immediately after graduation since individuals are
                                                    proactive and have a receptive mind.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse12" className="collapsed">
                                                <b>12.</b> Is the female to male ratio less with regard to an MBA program?</a>
                                            </p>
                                        </div>
                                        <div id="collapse12" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>No, it has been statistically proven that MBA programs conducted in the last five years
                                                    have more number of female candidates in comparison to males. With increased
                                                    awareness of gender equality and education of the girl child, the number of girls
                                                    attending school and pursuing a higher education has exponentially increased. There is no
                                                    longer a big gap in the number of boys and girls receiving an MBA degree.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse13" className="collapsed">
                                                <b>13.</b> How do you select a course specialization after enrolling for an MBA program?</a>
                                            </p>
                                        </div>
                                        <div id="collapse13" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>There are four main domains for specialization in an MBA program:</p>
                                                <ul>
                                                    <li>Marketing</li>
                                                    <li>Human Resources</li>
                                                    <li>Operations</li>
                                                    <li>Finance</li>
                                                </ul>
                                                <p>The course offers detailed curriculum related to the above domains that will help to
                                                    enhance a candidate’s knowledge and expertise. The integral point to consider before
                                                    selecting an MBA course is the area of interest and how it is related to an individual’s
                                                    long term career goal.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse14" className="collapsed">
                                                <b>14.</b> Are there MBA courses available with dual specialization?</a>
                                            </p>
                                        </div>
                                        <div id="collapse14" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Certain universities and colleges offer dual specialization programs that allow
                                                    combination of two or more of the core subjects (Marketing, Human Resources,
                                                    Operations, and Finance) in addition to other electives. It is important to check and verify
                                                    this in the course syllabus before applying for the degree program.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse15" className="collapsed">
                                                <b>15.</b> Is the curriculum of the MBA program case based?</a>
                                            </p>
                                        </div>
                                        <div id="collapse15" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>A select few universities provide case based MBA programs (majority provide lecture,
                                                    experiential, or team based courses). This type of teaching system is carried out in a
                                                    practical set-up and is dynamic as students are encouraged to provide analytical and
                                                    logical solutions to complex real world problems. It is important to check and verify the
                                                    mode of teaching before applying for the degree program.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse16" className="collapsed">
                                                <b>16.</b> Is CAT, GMAT, MAT, or other qualifying examinations mandatory to pursue MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse16" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Qualifying examinations are a prerequisite in order to get admission into a reputed and
                                                    prestigious institution; however, certain private institutes offer admission into MBA
                                                    programs without qualification of an entrance examination as eligibility criteria or also
                                                    with the payment of an additional fee (university donation).
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse17" className="collapsed">
                                                <b>17.</b> Can admission into an MBA program be obtained without attempting/clearing qualifying examinations?</a>
                                            </p>
                                        </div>
                                        <div id="collapse17" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>A qualifying examination is not mandatory in order to get admission into an MBA
                                                    program; however, recognized, reputed, and prestigious institutions prefer candidates
                                                    who have secured high scores in qualifying examinations. In addition, reputed companies
                                                    and recruiters prefer candidate with exceptional analytical skills demonstrated by
                                                    qualifying these very competitive examinations (GMAT, CAT etc.).
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse18" className="collapsed">
                                                <b>18.</b> Is job placement guaranteed after successful completion of MBA program?</a>
                                            </p>
                                        </div>
                                        <div id="collapse18" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Job placement is not 100% guaranteed after successful completion of MBA. Candidates
                                                    are prepared for various real world scenarios and are trained in analyzing, strategizing,
                                                    and decision-making. They are prepared for various job roles; however, individual 
                                                    performance during qualifying examinations, written tests, and group discussions
                                                    determine the career prospects of a candidate. After being offered a job, retention is
                                                    determined by performance and profitability.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse19" className="collapsed">
                                                <b>19.</b> Is a handsome salary package guaranteed after course completion?</a>
                                            </p>
                                        </div>
                                        <div id="collapse19" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Salary package and benefits are determined by various factors like designation, skill set,
                                                    previous work experience, and additional professional certifications. A management
                                                    trainee is offered a smaller salary package compared to an individual with 2-3 years of
                                                    work experience. Post course completion, salary can range from 4 lakhs and increases to
                                                    12-13 lakhs with 10-12 years of work experience. Some companies provide additional
                                                    bonuses and hikes depending on performance, work quality, quick turnaround time, and
                                                    profitability.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse20" className="collapsed">
                                                <b>20.</b> What if I discontinue the program before complete course completion?</a>
                                            </p>
                                        </div>
                                        <div id="collapse20" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>It is essential to complete the course entirely. In Indian universities, candidates have to
                                                    reapply to the course if they discontinue prematurely and wish to continue the course
                                                    again. In such an instance, the entire application fee and course amount is required to be
                                                    resubmitted. Some foreign universities reimburse a partial amount of the total fees
                                                    deposited, in very rare situations, where there is a critical and indispensible reason for
                                                    discontinuation.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse21" className="collapsed">
                                                <b>21.</b> How do I choose a college to pursue an MBA degree in Bangalore?</a>
                                            </p>
                                        </div>
                                        <div id="collapse21" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Bangalore is known as the city of infinite opportunities. There are many reputed and
                                                    recognized colleges in Bangalore which provide MBA courses with various
                                                    specializations.
                                                </p>
                                                <p>The most important factor to consider is the <b>accreditation, ranking, and affiliation</b> of the
                                                    college as this will have a far reaching impact in terms of quality of education. The
                                                    <b>subject and specialization</b> of the course is also very important as a candidate’s
                                                    performance is dependent on individual interest in the subject. The <b>location</b> of the college
                                                    is also of prime importance as an easily accessible institution saves time and resources.
                                                    Another important factor to consider is whether <b>scholarships and financial aid</b> is provided
                                                    to candidates who genuinely deserve them. Quality of education is related to the
                                                    <b>experience and qualification</b> of teachers, hence this is a factor to be considered before
                                                    applying for the course of interest. Lastly, the ultimate aim of good education is to apply
                                                    everything learned for community and personal benefit. It is essential to check for
                                                    possible <b>placement opportunities</b> in the field of interest before applying for a course.
                                                </p>
                                                <p>It is normal for candidates to be confused about choosing a university while also
                                                    considering the above factors. Hence, it is essential to take the help and support of <b>third
                                                    party companies</b> in order to make smart and beneficial decisions. Bangalore has one of
                                                    India’s best educational consultancy companies (the most promising in South India and
                                                    the best in Karnataka) that provides end-to-end support, genuine academic counseling,
                                                    and guaranteed admission to reputed colleges in Bangalore (and select cities across
                                                    India).
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse22" className="collapsed">
                                                <b>22.</b> Are there colleges in Bangalore that provide dual degree MBA courses?</a>
                                            </p>
                                        </div>
                                        <div id="collapse22" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>There are few colleges in Bangalore that provide the choice of two specializations within
                                                    one course. This not only provides additional knowledge in one field, but also increases
                                                    exposure to multiple fields which helps to build corporate value, professional experience,
                                                    and provides a broad range of career opportunities. Colleges in Bangalore that provide
                                                    dual specialization degrees:
                                                </p>
                                                <ul>
                                                    <li>Colleges affiliated to Bangalore University (BU)</li>
                                                    <li>Colleges affiliated to Visvesvaraya Technological University (VTU)</li>
                                                    <li>Majority of Deemed to be Universities</li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse23" className="collapsed">
                                                <b>23.</b> How do I prepare for the various entrance exams for MBA admission?</a>
                                            </p>
                                        </div>
                                        <div id="collapse23" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>There are multiple MBA entrance exams and preparation for them can turn into a
                                                    daunting, intimidating, and exhaustive task. However, proper planning, management, and
                                                    preparation can help to ease the process and make students better adapted to face entrance
                                                    exams.
                                                </p>
                                                <p>It is thus, recommended to consider and follow a few tips before beginning with
                                                    examination preparation.
                                                </p>
                                                <ul>
                                                    <li>Find out if you are eligible under the Merit category. If yes, find out the cut off required for MBA admission in your preferred college/university</li>
                                                    <li>Check which entrance exams (national, state, or university), are mandatory for the course and college/university of your choice</li>
                                                    <li>Check the mode, syllabus, and pattern of the entrance exams so that you can plan strategize accordingly</li>
                                                    <li>Purchase entrance examination guides, preparation material, sample question papers, previous examination papers, mock tests, and question banks to analyze the trend of questions asked like repetitive, out-of-syllabus, analytical, and reasoning questions</li>
                                                    <li>Keep updated with the latest news and current affairs of national and international significance</li>
                                                    <li>Prepare for logical reasoning and analytical thinking questions by continuous practice using guide books and practice test questions<br />
                                                    <ins><strong>Some reference books:</strong></ins> A Modern Approach to Logical Reasoning (Aggarwal R. S.), Olympiad Reasoning Workbook (Pallavi Aggarwal), How to Prepare for Logical Reasoning for CAT (Arun Sharma)
                                                    </li>
                                                    <li>Continuously build vocabulary, comprehension, and grammar. Prepare for vocabulary questions by continuous practice using practice test questions<br />
                                                    <ins><strong>Some reference books:</strong></ins> Word Power Made Easy (Norman Lewis), Right Way to Improve Your Vocabulary&nbsp;(Chaudhari Bharti), The Vocabulary Builder Workbook: Simple Lessons and Activities to Teach Yourself Over 1,400 Must-Know Words (Chris Lele), English Vocabulary: Speedy Study Guides (Speedy Publishing LLC)
                                                    </li>
                                                    <li>Practice basic mathematics. Memorize formulae and short-cuts for better recall</li>
                                                    <li>Hard work and knowledge of fundamentals and basic theory is mandatory</li>
                                                    <li>If constant monitoring and assessment is required, join a coaching centre or coaching program</li>
                                                    <li>Refer and note down short notes for quick revision</li>
                                                    <li>Early organization, strategy, planning, and preparation is essential</li>
                                                    <li>Time management is essential to effectively grasp maximum syllabus in less time</li>
                                                    <li>It is essential to take frequent breaks to avoid exhaustion</li>
                                                    <li>Care must be taken to eat healthy and obtain proper sleep as this will help to maintain physical and mental wellbeing</li>
                                                    <li>Limited use of digital devices like mobile phones, laptops, and tablets will help to avoid distractions</li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse24" className="collapsed">
                                                <b>24.</b> Do colleges in Bangalore provide internship opportunities and student exchange programs?</a>
                                            </p>
                                        </div>
                                        <div id="collapse24" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>An internship is an essential component of an MBA course as it provides practical
                                                    exposure to real world business scenarios. It provides an opportunity to apply knowledge,
                                                    skills, and lessons learnt in the university to the professional world. Internships also
                                                    provide additional professional experience as candidates are given an opportunity to work
                                                    in reputed companies. If a candidate has exceptional performance, they can be
                                                    permanently absorbed by the company. Majority of the colleges in Bangalore provide
                                                    internship opportunities in the fourth semester of the MBA course.
                                                </p>
                                                <p>Student exchange programs are conducted in few colleges in Bangalore. They provide
                                                    opportunities for students from different nationalities to experience another culture,
                                                    society, and education system. They also help in all round development of the candidate
                                                    as they are exposed to diverse situations.
                                                </p>
                                                <p>Colleges in Bangalore that provide student exchange programs:</p>
                                                <ul>
                                                    <li>Indian Institute of Management</li>
                                                    <li>Christ University</li>
                                                    <li>International School of Business & Research Business School</li>
                                                    <li>Institute Of Business Management & Technology Business School</li>
                                                    <li>National School of Business</li>
                                                    <li>St. Joseph's Institute of Management</li>
                                                    <li>New Horizon College of Engineering</li>
                                                    <li>Institute of Finance and International Management Business School</li>
                                                    <li>VYASA Business School</li>
                                                    <li>Alliance University</li>
                                                    <li>International School of Management Sciences</li>
                                                    <li>PES University</li>
                                                    <li>Symbiosis International University</li>
                                                    <li>Vignan's Foundation for Science, Technology and Research University</li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse25" className="collapsed">
                                                <b>25.</b> Do colleges in Bangalore guarantee placement after course completion?</a>
                                            </p>
                                        </div>
                                        <div id="collapse25" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Majority of the colleges in Bangalore provide 100% placement assistance to candidates
                                                    who take up MBA courses, provided candidates meet the placement criteria. Basic
                                                    criteria include:
                                                </p>
                                                <ul>
                                                    <li>Potential candidates should have a very good academic record with a minimum of 60% aggregate marks (or equivalent GPA) without any backlogs.</li>
                                                    <li>An aggregate of ≥75% ensures placement in a esteemed and reputed company</li>
                                                    <li>Good logical and analytical reasoning skills</li>
                                                    <li>Exceptional verbal communication skills</li>
                                                    <li>Proficient decision-making and leadership kills</li>
                                                </ul>
                                                <p>Placement programs are organized by colleges in the last semester. The program usually
                                                    includes improvement in soft skills like public speaking, writing skills, problem solving,
                                                    leadership, strategic planning, and decision making. The college arranges for recruiters
                                                    from established companies to conduct placement-related activities like written tests,
                                                    group discussions, and personal interviews to select potential employees for their firms.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse26" className="collapsed">
                                                <b>26.</b> What are the possible career opportunities after completion of an MBA course in Bangalore?</a>
                                            </p>
                                        </div>
                                        <div id="collapse26" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>There are abundant professional opportunities after successful completion of an MBA
                                                    course. The government, private, and non government sectors provide infinite job
                                                    opportunities for professional growth. There are many opportunities in industries like
                                                    healthcare, IT, education, finance, logistics, etc. Bangalore, the IT Hub of India, provides
                                                    myriad career opportunities to candidates in almost every professional field.
                                                </p>
                                                <p>Possible career opportunities:</p>
                                                <p><strong>MBA in Business Analytics</strong></p>
                                                <ul>
                                                    <li>Business Analyst</li>
                                                    <li>Big Data Analyst</li>
                                                    <li>Financial Analyst</li>
                                                    <li>Fraud Analyst</li>
                                                    <li>Market Research Analyst</li>
                                                </ul>
                                                <p><strong>MBA in Marketing</strong></p>
                                                <ul>
                                                    <li>Marketing Manager</li>
                                                    <li>Operations Manager</li>
                                                    <li>Digital Marketer&nbsp;</li>
                                                    <li>Social Media Manager</li>
                                                    <li>Forecast Strategist</li>
                                                </ul>
                                                <p><strong>MBA in Human Resources</strong></p>
                                                <ul>
                                                    <li>Training and Development Manager</li>
                                                    <li>Nonprofit Human Resources Expert</li>
                                                    <li>HR Trainee Manager</li>
                                                    <li>Performance Analyst</li>
                                                    <li>Compensation and Benefits Manager</li>
                                                </ul>
                                                <p><strong>MBA in Finance</strong></p>
                                                <ul>
                                                    <li>Chief Financial Officer</li>
                                                    <li>Accounting Manager</li>
                                                    <li>Accounting Manager</li>
                                                    <li>Fund Manager&nbsp;</li>
                                                    <li>Insurance &amp;&nbsp;Risk Managers</li>
                                                </ul>
                                                <p><strong>MBA in Operations</strong></p>
                                                <ul>
                                                    <li>Operations Manager</li>
                                                    <li>Area Operations Manager</li>
                                                    <li>Material Manager</li>
                                                    <li>Logistics Manager</li>
                                                    <li>Technical Operations Manager</li>
                                                </ul>
                                                <p><strong>MBA in Entrepreneurship</strong></p>
                                                <ul>
                                                    <li>Finance Controller</li>
                                                    <li>Small Business Owner</li>
                                                    <li>Business Reporter</li>
                                                    <li>New Venture Developer</li>
                                                    <li>Product Manager</li>
                                                </ul>
                                                <p><strong>MBA in Banking, Finance, and Insurance</strong></p>
                                                <ul>
                                                    <li>Financial Analyst</li>
                                                    <li>Credit and Risk Manager</li>
                                                    <li>Loan Counselor</li>
                                                    <li>Stock Analyst</li>
                                                    <li>Accountants and Auditor</li>
                                                </ul>
                                                <p><strong>MBA in Logistics and Supply Chain Management</strong></p>
                                                <ul>
                                                    <li>Supply and Chain Manager</li>
                                                    <li>Procurement Manager</li>
                                                    <li>Transportation Manager</li>
                                                    <li>Purchasing Manager</li>
                                                    <li>Operations manager</li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse27" className="collapsed">
                                                <b>27.</b> What are the criteria considered for campus placement in Bangalore?</a>
                                            </p>
                                        </div>
                                        <div id="collapse27" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Majority of the recognized and reputed colleges in Bangalore provide placement
                                                    opportunities for candidates. This is based on certain criteria:
                                                </p>
                                                <ul>
                                                    <li><b>Exceptional academic record –</b> the candidate should have passed in all
                                                    semesters of the total duration of the MBA course with at least 60% to 75%
                                                    aggregate (or equivalent CGPA or grade)
                                                    </li>
                                                    <li><b>Personal interview –</b> the most crucial factor to decide if a candidate is suitable
                                                    for placement is their performance in the personal interview round. The candidate
                                                    is judged based on their confidence, EQ, subject knowledge, IQ, and general
                                                    awareness. The candidate’s opinions on current and future prospects help
                                                    recruiters determine if they are ideal resources to contribute to their company
                                                    growth
                                                    </li>
                                                    <li><b>Subject knowledge –</b> the candidate should be able to apply everything studied in
                                                    the course in a practical environment. They should be able to recall and execute
                                                    the widespread applications of theoretical concepts in the company
                                                    </li>
                                                    <li><b>Awareness –</b> recruiters value candidates who are aware of the constantly
                                                    changing global business economy. They are updated with the latest trends in
                                                    business and make critical decisions regarding different business opportunities
                                                    </li>
                                                    <li><b>Leadership –</b> leadership skills demonstrate the ability of a candidate to
                                                    collaborate with others effectively. It demonstrates the candidate’s ability to work
                                                    in a team with efficient decision making, project management, problem solving,
                                                    and execution
                                                    </li>
                                                    <li><b>Interpersonal skills –</b> importance is given to a candidate’s ability to step forward
                                                    and communicate effectively. Verbal and social skills govern how a candidate can
                                                    effectively manage their circumstance under high pressure situations. These skills
                                                    demonstrate the potential of a candidate as a future leader and manager of the
                                                    company
                                                    </li>
                                                    <li><b>Dynamic personality –</b> an individual’s personality is the reflection of
                                                    themselves. A potential employee should be capable of multitasking, making
                                                    quick and efficient decisions, communicating ideas and solutions effectively,
                                                    planning strategically, and being a good team leader. A candidate with these
                                                    qualities will be ideal since they have the ability to attract ample business to the
                                                    company
                                                    </li>
                                                    <li><b>Hobbies –</b> the candidate should have a passion for their desired field as this will
                                                    ultimately translate to productive and profitable work. Employers prefer to hire
                                                    candidates with enthusiasm, motivation, and drive to excel as this helps in the
                                                    growth of company economy and profit
                                                    </li>
                                                    <li><b>Work experience –</b> though this is not mandatory, work experience is additional
                                                    professional experience that denotes that a candidate is capable of working in a
                                                    real world scenario. This adds extra validation and credibility that makes the
                                                    candidate's resume stand out. This also reduces training time and expenditure
                                                    which are resources that should be conserved in order to avoid financial losses
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse28" className="collapsed">
                                                <b>28.</b> Who are the potential recruiters that participate in campus placement drives in Bangalore?</a>
                                            </p>
                                        </div>
                                        <div id="collapse28" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Bangalore, the IT Hub of India, provides myriad career opportunities to candidates in
                                                    almost every professional field. The government, private, and non government sectors
                                                    provide infinite job opportunities for professional growth. There are many opportunities
                                                    in multiple industries:
                                                </p>
                                                <ul>
                                                    <li>IT sector: Infosys, Amazon, Accenture, Capgemini, Cognizant, Dell, IBM, Tata Consultancy Services, and Wipro</li>
                                                    <li>Healthcare sector: Apollo Hospitals, Biocon, Cerner Corporation, GlaxoSmithKline, HCG Oncology, Novartis, and Novo Nordisk</li>
                                                    <li>Accounting sector: Wells Fargo, Deloitte, Ernst and Young, KPMG, and Target</li>
                                                    <li>Logistics sector: DHL, FedEx, SAP Labs and Ashok Leyland</li>
                                                    <li>Cosmetics sector: Colorbar Cosmetics, Himalaya Healthcare and Lotus Pharma</li>
                                                    <li>Sports sector: Cricinfo, Decathlon, Gambit Sports, and Nielsen Sports</li>
                                                    <li>Education sector: Cuemath, Simplilearn, Byju&rsquo;s, Edukart, Jigsaw Academy, and Unacademy</li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse29" className="collapsed">
                                                <b>29.</b> What is the average salary package that can be obtained after receiving an MBA degree in India?</a>
                                            </p>
                                        </div>
                                        <div id="collapse29" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>The salary package that a candidate receives is not dependent on the MBA course and
                                                    specialization of the course alone. Other criteria that are considered are:
                                                </p>
                                                <ul>
                                                    <li><b>Academic performance –</b> consistent and strong academic performance guarantees a high salary package</li>
                                                    <li><b>Professional degrees –</b> additional professional degrees enhance the professional experience of a candidate</li>
                                                    <li><b>Qualification in CAT and MAT examinations –</b> these examinations are difficult
                                                    and highly competitive. Candidates who qualify these examinations are given a
                                                    higher salary package, followed by candidates with fair performance, and lastly
                                                    candidates with poor performance
                                                    </li>
                                                    <li><b>Previous work experience –</b> the quality and achievements of a candidate in their
                                                    previous organization govern their potential and value in a prospective company.
                                                    Candidates with work experience get a better salary package than fresh graduates
                                                    </li>
                                                    <li><b>Skills and certifications –</b> additional skills and certifications add extra value to a candidate’s resume</li>
                                                    <li><b>Task execution –</b> The ability to execute tasks which result in positive and
                                                    profitable outcomes add extra value to a candidate. Consistent hard work,
                                                    dedication, and performance set high performers apart from laid-back workers.
                                                    This guarantees a better salary package than the average package
                                                    </li>
                                                </ul>
                                                <p>There is an increased demand for qualified managers and leaders which has resulted in an
                                                    increase in MBA placements. Recruiters are willing to pay a very good salary package
                                                    based on education background, experience, and hard work. With global economic
                                                    progress, an increased number of international firms are interested in qualified Indian
                                                    employees.
                                                </p>
                                                <div className="table-wrap">
                                                    <table border="1" cellPadding="1" cellSpacing="1" className="table table-striped table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">University / Institution / College [Location]</th>
                                                            <th scope="col">Highest Average Salary Offered (excellent aggregate)</th>
                                                            <th scope="col">Median Average Salary Offered (average aggregate)</th>
                                                            <th scope="col">Lowest Average Salary Offered (less aggregate)</th>
                                                            <th scope="col">Recruiters</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>IIM [Ahmedabad]</td>
                                                            <td>30.40 Lakhs p.a.</td>
                                                            <td>14.70 Lakhs p.a.</td>
                                                            <td>5.20 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Amazon</li>
                                                                <li>Deloitte</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>JP Morgan</li>
                                                                <li>McKinsey &amp;&nbsp;Company</li>
                                                                <li>The Boston Consulting Group</li>
                                                                </ul>
                                                                <p><strong>Foreign Recruiters:</strong></p>
                                                                <ul>
                                                                <li>AluZinc Asia, Singapore</li>
                                                                <li>Louis Dreyfus, Netherlands</li>
                                                                <li>Olam International, Singapore</li>
                                                                <li>Swiss, Singapore</li>
                                                                <li>Tropical General Investments Group, Lagos</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Bangalore]</td>
                                                            <td>24.54 Lakhs p.a.</td>
                                                            <td>10.54 Lakhs p.a.</td>
                                                            <td>5.54 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Aditya Birla Group</li>
                                                                <li>AT Kearney</li>
                                                                <li>Boston Consulting</li>
                                                                <li>Citibank</li>
                                                                <li>Deloitte</li>
                                                                <li>HSBC</li>
                                                                <li>ICICI Bank</li>
                                                                <li>JP Morgan</li>
                                                                <li>Kotak</li>
                                                                <li>McKinsey</li>
                                                                <li>Procter &amp;&nbsp;Gamble</li>
                                                                <li>Yes Bank</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Calcutta]</td>
                                                            <td>25.36 Lakhs p.a.</td>
                                                            <td>8.54 Lakhs p.a.</td>
                                                            <td>5.54 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>AT Kearney</li>
                                                                <li>Bain &amp;&nbsp;Co.</li>
                                                                <li>Bank of America Merrill Lynch (BAML)</li>
                                                                <li>Citi</li>
                                                                <li>EY</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>JP Morgan Chase</li>
                                                                <li>McKinsey &amp;&nbsp;Co</li>
                                                                <li>PwC</li>
                                                                <li>The Boston Consulting Group</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Kozhikode]</td>
                                                            <td>20.37 Lakhs p.a.</td>
                                                            <td>11.17 Lakhs p.a.</td>
                                                            <td>5.87 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Amazon</li>
                                                                <li>Boston Consulting Group</li>
                                                                <li>Deloitte</li>
                                                                <li>EY</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>JPMorgan Chase &amp; Co.</li>
                                                                <li>PwC</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Indore]</td>
                                                            <td>20.79 Lakhs p.a.</td>
                                                            <td>8.37 Lakhs p.a.</td>
                                                            <td>5.17 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Aditya Birla Capital</li>
                                                                <li>Avalon Consulting</li>
                                                                <li>Barclays</li>
                                                                <li>Cognizant Business Consulting</li>
                                                                <li>Deloitte USI</li>
                                                                <li>Deutsche Bank</li>
                                                                <li>Edelweiss</li>
                                                                <li>Ernst&nbsp;&amp;&nbsp;Young</li>
                                                                <li>Fidelity Investments</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>IndusInd Bank</li>
                                                                <li>JPMorgan Chase &amp;&nbsp;Co</li>
                                                                <li>Kotak Mahindra</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Tiruchirappalli]</td>
                                                            <td>14.91 Lakhs p.a.</td>
                                                            <td>6.17 Lakhs p.a.</td>
                                                            <td>4.87 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Amazon</li>
                                                                <li>Avalon Consulting</li>
                                                                <li>Cognizant</li>
                                                                <li>Deloitte</li>
                                                                <li>Ernst &amp;&nbsp;Young</li>
                                                                <li>JP Morgan Chase</li>
                                                                <li>ICICI Bank</li>
                                                                <li>KPMG</li>
                                                                <li>Larson &amp;&nbsp;Taubro</li>
                                                                <li>Nilkamal</li>
                                                                <li>Shell</li>
                                                                <li>UltraTech Cement</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Shillong]</td>
                                                            <td>16.79 Lakhs p.a.</td>
                                                            <td>7.71 Lakhs p.a.</td>
                                                            <td>4.21 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Amazon</li>
                                                                <li>Cognizant Business Consulting</li>
                                                                <li>Deloitte USI</li>
                                                                <li>ICICI Bank</li>
                                                                <li>Infosys</li>
                                                                <li>JP Morgan &amp;&nbsp;Chase</li>
                                                                <li>Stellium Consulting</li>
                                                                <li>TATA Motors</li>
                                                                <li>Ujjivan Small Finance Bank</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Udaipur]</td>
                                                            <td>13.2 Lakhs p.a.</td>
                                                            <td>7.89 Lakhs p.a.</td>
                                                            <td>3.69 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Aditya Birla Capital</li>
                                                                <li>Accenture</li>
                                                                <li>Ambuja Cement</li>
                                                                <li>Amul</li>
                                                                <li>Axis Bank</li>
                                                                <li>Bajaj Auto</li>
                                                                <li>Cipla Health</li>
                                                                <li>Cognizant</li>
                                                                <li>Deloitte</li>
                                                                <li>HDFC Bank</li>
                                                                <li>ICICI Bank</li>
                                                                <li>Infosys BPM</li>
                                                                <li>ITC Infotech</li>
                                                                <li>KPMG</li>
                                                                <li>Landmark Group</li>
                                                                <li>Marico</li>
                                                                <li>Maruti Suzuki</li>
                                                                <li>OYO</li>
                                                                <li>Quikr</li>
                                                                <li>United Breweries</li>
                                                                <li>Yes Bank</li>
                                                                <li>Zydus Cadila</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Ranchi]</td>
                                                            <td>26.21 Lakhs p.a.</td>
                                                            <td>15.41 Lakhs p.a.</td>
                                                            <td>6.61 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Berger Paints</li>
                                                                <li>Bosch</li>
                                                                <li>Capgemini</li>
                                                                <li>Cognizant</li>
                                                                <li>Emami</li>
                                                                <li>Ernst &amp;&nbsp;Young</li>
                                                                <li>Flipkart</li>
                                                                <li>Google</li>
                                                                <li>HP</li>
                                                                <li>JP Morgan Chase</li>
                                                                <li>Quikr</li>
                                                                <li>Raymond</li>
                                                                <li>Samsung</li>
                                                                <li>R&amp;D</li>
                                                                <li>Sony Pictures</li>
                                                                <li>Tata Steel</li>
                                                                <li>Yatra</li>
                                                                <li>Yes Bank</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Nagpur]</td>
                                                            <td>20.21 Lakhs p.a.</td>
                                                            <td>7.41 Lakhs p.a.</td>
                                                            <td>4.71 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Decimal Point Analytics</li>
                                                                <li>Deloitte Consulting</li>
                                                                <li>JK Technosoft</li>
                                                                <li>MuSigma</li>
                                                                <li>Schindler V Guard</li>
                                                                <li>Sutherland Global</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Kashipur]</td>
                                                            <td>26.21 Lakhs p.a.</td>
                                                            <td>15.71 Lakhs p.a.</td>
                                                            <td>6.91 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Aditya Birla</li>
                                                                <li>Deloitte</li>
                                                                <li>Ernst &amp; Young</li>
                                                                <li>Godrej</li>
                                                                <li>ICICI Bank</li>
                                                                <li>KPMG</li>
                                                                <li>OYO</li>
                                                                <li>Reliance Jio</li>
                                                                <li>TATA Capital</li>
                                                                <li>Yes Bank</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM [Rohtak]</td>
                                                            <td>22.21 Lakhs p.a.</td>
                                                            <td>12.71 Lakhs p.a.</td>
                                                            <td>7.71 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Aditya Birla Group</li>
                                                                <li>Amul</li>
                                                                <li>Ernst &amp;&nbsp;Young</li>
                                                                <li>Cognizant</li>
                                                                <li>Dabur</li>
                                                                <li>Deloitte</li>
                                                                <li>HDFC</li>
                                                                <li>ICICI</li>
                                                                <li>KPMG</li>
                                                                <li>Kotak Mahindra</li>
                                                                <li>Mu Sigma</li>
                                                                <li>Tata Steel</li>
                                                                <li>Yes Bank</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM&nbsp;[Raipur]</td>
                                                            <td>27.21 Lakhs p.a.</td>
                                                            <td>16.61 Lakhs p.a.</td>
                                                            <td>9.31 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Cognizant</li>
                                                                <li>Deloitte</li>
                                                                <li>IndiGo</li>
                                                                <li>Mahindra Logistics</li>
                                                                <li>OYO</li>
                                                                <li>Tata Advanced Systems Ltd</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM&nbsp;[Amritsar]</td>
                                                            <td>21.21 Lakhs p.a.</td>
                                                            <td>13.51 Lakhs p.a.</td>
                                                            <td>5.41 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Apollo Munich</li>
                                                                <li>Deloitte</li>
                                                                <li>HDFC Bank</li>
                                                                <li>ICICI Lombard</li>
                                                                <li>KPMG</li>
                                                                <li>Tata Teleservices</li>
                                                                <li>Thomas Cook</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIM&nbsp;[Visakhapatnam]</td>
                                                            <td>22.21 Lakhs p.a.</td>
                                                            <td>11.91 Lakhs p.a.</td>
                                                            <td>4.71 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Aditya Birla Payments Bank</li>
                                                                <li>Amul</li>
                                                                <li>Berger Paints</li>
                                                                <li>Bosch</li>
                                                                <li>Franklin Templeto</li>
                                                                <li>HDFC Bank</li>
                                                                <li>HSBC</li>
                                                                <li>ICICI Lombard</li>
                                                                <li>KPMG</li>
                                                                <li>Mu Sigma</li>
                                                                <li>TVS Motors</li>
                                                                <li>Yes Bank</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>XLRI [Jamshedpur]</td>
                                                            <td>19.21 Lakhs p.a.</td>
                                                            <td>7.36 Lakhs p.a.</td>
                                                            <td>4.36 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Aditya Birla Group</li>
                                                                <li>Amazon</li>
                                                                <li>Coca Cola</li>
                                                                <li>Colgate Palmolive</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>ITC</li>
                                                                <li>Mahindra &amp; Mahindra</li>
                                                                <li>McKinsey &amp;&nbsp;Co.</li>
                                                                <li>Nestle</li>
                                                                <li>PayTM</li>
                                                                <li>Procter &amp;&nbsp;Gamble</li>
                                                                <li>Reckitt Benckiser</li>
                                                                <li>Reliance Industries</li>
                                                                <li>Tata Sons Group (TAS)</li>
                                                                <li>Uber</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPJIMR [Mumbai]</td>
                                                            <td>22.90 Lakhs p.a.</td>
                                                            <td>10.21&nbsp;Lakhs p.a.</td>
                                                            <td>5.21 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Amazon</li>
                                                                <li>American Express</li>
                                                                <li>Bain &amp;&nbsp;Company</li>
                                                                <li>Boston Consulting Group</li>
                                                                <li>Deloitte</li>
                                                                <li>EY</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>Hindustan Unilever</li>
                                                                <li>McKinsey &amp;&nbsp;Company</li>
                                                                <li>Procter &amp;&nbsp;Gamble</li>
                                                                <li>PwC</li>
                                                                <li>Tata Administrative Services</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>MDI [Gurgaon]</td>
                                                            <td>19.17 Lakhs p.a.</td>
                                                            <td>7.90&nbsp;Lakhs p.a.</td>
                                                            <td>4.90 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Abbott</li>
                                                                <li>Aditya Birla Group</li>
                                                                <li>Axis Bank</li>
                                                                <li>Bennett Coleman &amp; Co</li>
                                                                <li>BMW</li>
                                                                <li>Cisco</li>
                                                                <li>Coca-Cola</li>
                                                                <li>Crompton Greaves</li>
                                                                <li>Dabur</li>
                                                                <li>EY</li>
                                                                <li>Glaxo</li>
                                                                <li>Godrej</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>HCL Technologies</li>
                                                                <li>ITC</li>
                                                                <li>JPMorgan Chase</li>
                                                                <li>KPMG</li>
                                                                <li>Larson &amp; Toubro</li>
                                                                <li>Ola Cabs</li>
                                                                <li>OYO Rooms</li>
                                                                <li>PwC</li>
                                                                <li>Philips India</li>
                                                                <li>Pidilite</li>
                                                                <li>PUMA</li>
                                                                <li>Tata Motors</li>
                                                                <li>Walmart</li>
                                                                <li>Wipro</li>
                                                                </ul>
                                                                <p><strong>Foreign Recruiters:</strong></p>
                                                                <ul>
                                                                <li>Johnson &amp; Johnson, Dubai</li>
                                                                <li>Philips, Madrid</li>
                                                                <li>Tolaram Group, Singapore</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IMI [New Delhi]</td>
                                                            <td>12.79 Lakhs p.a.</td>
                                                            <td>6.99&nbsp;Lakhs p.a.</td>
                                                            <td>4.19 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>ANZ Bank</li>
                                                                <li>Capgemini</li>
                                                                <li>CitiBank</li>
                                                                <li>Deloitte</li>
                                                                <li>EY India</li>
                                                                <li>ICICI Bank</li>
                                                                <li>Infosys</li>
                                                                <li>KPMG</li>
                                                                <li>Larson &amp;&nbsp;Taubro Financial Services</li>
                                                                <li>Piramal Group</li>
                                                                <li>Reckitt Benckiser</li>
                                                                <li>Tata Capital</li>
                                                                <li>Wipro</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>NMIMS [Mumbai]</td>
                                                            <td>17.60 Lakhs p.a.</td>
                                                            <td>9.59&nbsp;Lakhs p.a.</td>
                                                            <td>4.39 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Abbott</li>
                                                                <li>Accenture</li>
                                                                <li>Amway</li>
                                                                <li>Bank of America</li>
                                                                <li>Cognizant</li>
                                                                <li>Crompton Greaves</li>
                                                                <li>Dabur</li>
                                                                <li>Flipkart</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>ICICI</li>
                                                                <li>ITC</li>
                                                                <li>JP Morgan</li>
                                                                <li>Johnson &amp;&nbsp;Johnson</li>
                                                                <li>Max Life</li>
                                                                <li>Raymond</li>
                                                                <li>Siemens</li>
                                                                <li>Whirlpool</li>
                                                                <li>Wipro</li>
                                                                <li>UB Group</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>FMS [Delhi]</td>
                                                            <td>23.10 Lakhs p.a.</td>
                                                            <td>7.30&nbsp;Lakhs p.a.</td>
                                                            <td>4.70 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Amazon</li>
                                                                <li>BMW</li>
                                                                <li>Capgemini</li>
                                                                <li>Flipkart</li>
                                                                <li>Google</li>
                                                                <li>ITC</li>
                                                                <li>Microsoft</li>
                                                                <li>Nestle</li>
                                                                <li>OYO</li>
                                                                <li>Reckitt Benckiser</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IMT [Ghaziabad]</td>
                                                            <td>12.50 Lakhs p.a.</td>
                                                            <td>5.40&nbsp;Lakhs p.a.</td>
                                                            <td>3.20 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Bosch</li>
                                                                <li>Citi Bank</li>
                                                                <li>Cognizant</li>
                                                                <li>Deloitte</li>
                                                                <li>Ernst &amp; Young</li>
                                                                <li>Google</li>
                                                                <li>HCL Technologies</li>
                                                                <li>Infosys</li>
                                                                <li>Larson &amp; Taubro</li>
                                                                <li>Mahindra &amp;&nbsp;Mahindra</li>
                                                                <li>Whirlpool</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>SIBM [Pune]</td>
                                                            <td>15.02 Lakhs p.a.</td>
                                                            <td>7.60&nbsp;Lakhs p.a.</td>
                                                            <td>2.40 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Asian Paints</li>
                                                                <li>Airtel</li>
                                                                <li>Bajaj Auto</li>
                                                                <li>Capgemini</li>
                                                                <li>Cognizant</li>
                                                                <li>Dell</li>
                                                                <li>Deloitte</li>
                                                                <li>Dr Reddy&rsquo;s</li>
                                                                <li>Genpact</li>
                                                                <li>Hewlett Packard</li>
                                                                <li>Infosys</li>
                                                                <li>Wipro</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>SIBM [Pune]</td>
                                                            <td>15.02 Lakhs p.a.</td>
                                                            <td>7.60&nbsp;Lakhs p.a.</td>
                                                            <td>2.40 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Asian Paints</li>
                                                                <li>Airtel</li>
                                                                <li>Bajaj Auto</li>
                                                                <li>Capgemini</li>
                                                                <li>Cognizant</li>
                                                                <li>Dell</li>
                                                                <li>Deloitte</li>
                                                                <li>Dr Reddy&rsquo;s</li>
                                                                <li>Genpact</li>
                                                                <li>Hewlett Packard</li>
                                                                <li>Infosys</li>
                                                                <li>Wipro</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IIFT [Delhi]</td>
                                                            <td>20.07 Lakhs p.a.</td>
                                                            <td>6.12&nbsp;Lakhs p.a.</td>
                                                            <td>4.92 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Airtel</li>
                                                                <li>Amazon</li>
                                                                <li>Axis Bank</li>
                                                                <li>Bajaj Auto</li>
                                                                <li>Britannia</li>
                                                                <li>Citibank</li>
                                                                <li>GE</li>
                                                                <li>Godrej</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>HP</li>
                                                                <li>ITC</li>
                                                                <li>KPMG</li>
                                                                <li>Marico</li>
                                                                <li>PPG Asian Paints</li>
                                                                <li>Royal Enfield</li>
                                                                <li>Shell</li>
                                                                <li>Tata Motors</li>
                                                                <li>Wipro Technologies</li>
                                                                </ul>
                                                                <p><strong>Foreign Recruiters:</strong></p>
                                                                <ul>
                                                                <li>Avantor, Malaysia</li>
                                                                <li>Dabur, Dubai</li>
                                                                <li>Godrej Consumer&nbsp;Products Limited, Chile</li>
                                                                <li>Mount Meru, Zambia</li>
                                                                <li>Trafigura, Netherlands</li>
                                                                <li>Olam International, Singapore</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>TAPMI [Manipal]</td>
                                                            <td>10.05 Lakhs p.a.</td>
                                                            <td>6.01&nbsp;Lakhs p.a.</td>
                                                            <td>3.31 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Capgemini</li>
                                                                <li>Citibank</li>
                                                                <li>Cognizant</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>JP Morgan</li>
                                                                <li>Microsoft</li>
                                                                <li>Wipro</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>XIM [Bhubaneswar]</td>
                                                            <td>18.05 Lakhs p.a.</td>
                                                            <td>8.15&nbsp;Lakhs p.a.</td>
                                                            <td>5.25 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Aditya Birla Group</li>
                                                                <li>Axis Bank</li>
                                                                <li>Bajaj Auto</li>
                                                                <li>Capgemini</li>
                                                                <li>Cognizant</li>
                                                                <li>Deloitte</li>
                                                                <li>Ernst &amp;&nbsp;Young</li>
                                                                <li>Godrej</li>
                                                                <li>HCL Technologies</li>
                                                                <li>HP Enterprise</li>
                                                                <li>IBM</li>
                                                                <li>Infosys</li>
                                                                <li>L&amp;#39;Or&eacute;al</li>
                                                                <li>Mindtree</li>
                                                                <li>Nestle</li>
                                                                <li>Sun Pharma</li>
                                                                <li>Tata Consultancy Services</li>
                                                                <li>Tata Steel</li>
                                                                <li>Times Group</li>
                                                                <li>Wipro</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>JBIMS [Mumbai]</td>
                                                            <td>32.05 Lakhs p.a.</td>
                                                            <td>12.05&nbsp;Lakhs p.a.</td>
                                                            <td>5.05 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Airtel</li>
                                                                <li>Bajaj</li>
                                                                <li>Bisleri</li>
                                                                <li>Citibank</li>
                                                                <li>Essar</li>
                                                                <li>Godrej</li>
                                                                <li>Goldman Sachs</li>
                                                                <li>Hindustan Unilever</li>
                                                                <li>ICICI Bank</li>
                                                                <li>Infosys</li>
                                                                <li>JPMorgan Chase</li>
                                                                <li>KPMG</li>
                                                                <li>Mahindra</li>
                                                                <li>Raymond</li>
                                                                <li>Vedanta</li>
                                                                <li>Wipro</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>GIM [Goa]</td>
                                                            <td>20.05 Lakhs p.a.</td>
                                                            <td>9.15&nbsp;Lakhs p.a.</td>
                                                            <td>4.35 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Barclays</li>
                                                                <li>Deloitte</li>
                                                                <li>Ernst &amp; Young</li>
                                                                <li>Godrej</li>
                                                                <li>IBM</li>
                                                                <li>ITC</li>
                                                                <li>Neilsen</li>
                                                                <li>Piramal</li>
                                                                <li>Reckitt Benckiser</li>
                                                                <li>Wipro</li>
                                                                <li>Zee Media</li>
                                                                </ul>
                                                                <p><strong>Foreign Recruiters:</strong></p>
                                                                <ul>
                                                                <li>Diageo, Oman</li>
                                                                <li>Enhance Group, Oman</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>SIMSR [Mumbai]</td>
                                                            <td>22.05 Lakhs p.a.</td>
                                                            <td>8.75&nbsp;Lakhs p.a.</td>
                                                            <td>5.45 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Bajaj</li>
                                                                <li>Deloitte</li>
                                                                <li>Google</li>
                                                                <li>HCL</li>
                                                                <li>HDFC</li>
                                                                <li>ICICI</li>
                                                                <li>Maruti Suzuki</li>
                                                                <li>Nestle</li>
                                                                <li>Oracle</li>
                                                                <li>Reliance</li>
                                                                <li>Zomato</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IRMA [Anand]</td>
                                                            <td>26.05 Lakhs p.a.</td>
                                                            <td>11.55&nbsp;Lakhs p.a.</td>
                                                            <td>6.95 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Bharati AXA</li>
                                                                <li>Cargill</li>
                                                                <li>Fullerton</li>
                                                                <li>Godrej Agro</li>
                                                                <li>Gujarat Co-operative Milk Marketing Federation (AMUL)</li>
                                                                <li>ICICI Bank</li>
                                                                <li>ITC</li>
                                                                <li>Reliance</li>
                                                                <li>Tata AIG</li>
                                                                <li>Tolaram</li>
                                                                <li>Zuari</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>FSM [New Delhi]</td>
                                                            <td>31.05 Lakhs p.a.</td>
                                                            <td>12.65&nbsp;Lakhs p.a.</td>
                                                            <td>5.65 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Asian Paints</li>
                                                                <li>Coffee Day Beverages</li>
                                                                <li>Deloitte</li>
                                                                <li>Genpact</li>
                                                                <li>ICICI Bank</li>
                                                                <li>ITC</li>
                                                                <li>JK Tyre</li>
                                                                <li>KPMG GS</li>
                                                                <li>Mahindra &amp; Mahindra</li>
                                                                <li>Nestle</li>
                                                                <li>Nivea</li>
                                                                <li>Orient Electric</li>
                                                                <li>Philips</li>
                                                                <li>Schneider-Electric</li>
                                                                <li>Tata Motors</li>
                                                                <li>Zomato</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>BIMTECH [Greater Noida]</td>
                                                            <td>12.05 Lakhs p.a.</td>
                                                            <td>7.35&nbsp;Lakhs p.a.</td>
                                                            <td>4.85 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>Birla Soft</li>
                                                                <li>Cognizant</li>
                                                                <li>HCL</li>
                                                                <li>ICICI Prudential</li>
                                                                <li>Infosys</li>
                                                                <li>Kotak Mahindra Bank</li>
                                                                <li>Swiss Re</li>
                                                                <li>TCS</li>
                                                                <li>Wipro</li>
                                                                <li>ZEE Entertainment</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>IFMR [Chennai]</td>
                                                            <td>14.05 Lakhs p.a.</td>
                                                            <td>9.45&nbsp;Lakhs p.a.</td>
                                                            <td>4.25 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Accenture</li>
                                                                <li>ANZ</li>
                                                                <li>Cognizant</li>
                                                                <li>Deloitte</li>
                                                                <li>Genpact</li>
                                                                <li>HDFC</li>
                                                                <li>HSBC</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>BIM [Tiruchirappalli]</td>
                                                            <td>23.05 Lakhs p.a.</td>
                                                            <td>11.95&nbsp;Lakhs p.a.</td>
                                                            <td>5.85 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>3M</li>
                                                                <li>Asian Paints</li>
                                                                <li>Godrej</li>
                                                                <li>TVS Motors</li>
                                                                <li>Vedanta</li>
                                                                <li>Wipro</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>SIMSREE [Mumbai]</td>
                                                            <td>16.05 Lakhs p.a.</td>
                                                            <td>10.55&nbsp;Lakhs p.a.</td>
                                                            <td>4.35 Lakhs p.a.</td>
                                                            <td>
                                                                <ul>
                                                                <li>Barclays</li>
                                                                <li>Citi Group</li>
                                                                <li>Dell</li>
                                                                <li>Godrej</li>
                                                                <li>J P Morgan Chase</li>
                                                                <li>Reliance Industries</li>
                                                                <li>Pidilite</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse30" className="collapsed">
                                                <b>30.</b> What is the scope of MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse30" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>MBA has an extensive and wide scope. In the modern world, various industries and sectors require qualified MBA graduates for better business, consistent management, increased profit, and global success of the business.&nbsp;</p>
                                                <p>There is a high demand for MBA graduates in India and around the world. Candidates with very good leadership, management, and communication skills have an advanced and fast-growing career in the field of management.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse31" className="collapsed">
                                                <b>31.</b> Is MBA better than PGDM?</a>
                                            </p>
                                        </div>
                                        <div id="collapse31" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Both MBA and PGDM degrees are important for a successful career in business administration and management.</p>
                                                <p>An MBA degree course is important for candidates who want a government job or for candidates who wish to pursue a postgraduate degree in Management.</p>
                                                <p>A PGDM course is important for candidates who want more industrial exposure and practice in the current specializations and latest technology.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse32" className="collapsed">
                                                <b>32.</b> Is MBA better than MCA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse32" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Both MBA and MCA courses are very popular postgraduate degree courses. However, they have very different academic scope, industrial applications, and job requirements. Candidates can choose their preferred course according to their aptitude and interest.</p>
                                                <p>An MBA degree course is a Management course that is important for candidates who want to pursue a postgraduate degree in Management Studies. Candidates with an aptitude for business, have very good management and communication skills, and want managerial roles in a company can choose an MBA course.&nbsp;</p>
                                                <p>An MCA degree course is a Computer Application course that is important for candidates who want to pursue a postgraduate degree in Computer Applications. Candidates with an aptitude for programming and interest in the software industry can choose an MCA course.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse33" className="collapsed">
                                                <b>33.</b> Can I get a job in MNCs after MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse33" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Yes, successful and popular MNCs offer many job opportunities for graduates after MBA course.&nbsp;</p>
                                                <p>These companies require multiple MBA graduates for various diverse specializations and job roles. Different departments like Marketing, Finance, Human Resources, Operations, etc. require skilled and qualified MBA graduates for roles such as Marketing Executive, Finance Manager, HR Consultant, etc. There is plenty of scope for high potential, competent, and hard working MBA graduates in MNCs.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse34" className="collapsed">
                                                <b>34.</b> What is the salary of MBA freshers?</a>
                                            </p>
                                        </div>
                                        <div id="collapse34" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>The salary for MBA freshers starts with Rs. 20,000 and depends on the candidate&rsquo;s:</p>
                                                <ul>
                                                    <li>Aptitude</li>
                                                    <li>Capability</li>
                                                    <li>Department or Specialization in the company</li>
                                                    <li>Potential</li>
                                                    <li>Performance during assignments and tasks</li>
                                                </ul>
                                                <p>It is a fast-growing career. With increase in experience and performance, candidates can get a very high salary within a short period of time.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse35" className="collapsed">
                                                <b>35.</b> Is New Horizon College Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse35" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Yes, New Horizon College Bangalore is good for MBA. The college is an autonomous college affiliated to Visvesvaraya Technical University. It provides three programs for MBA:</p>
                                                <ul>
                                                    <li>Executive MBA</li>
                                                    <li>Professional MBA</li>
                                                    <li>Global MBA</li>
                                                </ul>
                                                <p>The college provides regular management courses as well certification courses, seminars, professional training, internships, industrial visits, and foreign collaborative programs with very good international exposure</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse36" className="collapsed">
                                                <b>36.</b> Is East Point College Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse36" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Yes, East Point College Bangalore is good for MBA. The college is a private college affiliated to Bangalore University.</p>
                                                <p>The course fees are reasonable and provide high value for the fees invested. The curriculum provides a strong foundation in Management. There are also many additional certifications and add-on courses included in the curriculum which help to prepare students for a successful career in business management.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse37" className="collapsed">
                                                <b>37.</b> Is T John College Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse37" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Yes, T John College Bangalore is good for MBA. The college is a private college with MBA programs affiliated to both Bangalore University and Visvesvaraya Technical University.</p>
                                                <p>The course fee structure is less and is very reasonable. The curriculum provides a strong foundation in Management through additional certification courses, internships, and training programs. The Placement Department conducts exclusive training programs for students so that they are prepared for various career opportunities.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse38" className="collapsed">
                                                <b>38.</b> Is IFIM College Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse38" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>IFIM College Bangalore is a popular B-School in Bangalore and is very good for MBA. The college is a private college affiliated to Bangalore University.</p>
                                                <p>The college provides multiple internships, projects, and training opportunities in popular corporate companies. The academic curriculum includes both conceptual topics and applications in the industry. Candidates are provided extensive professional exposure, industrial training, and work experience that lead to better placement opportunities and a successful career in corporate companies.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse39" className="collapsed">
                                                <b>39.</b> Is PES University Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse39" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>PES University Bangalore is a very popular and reputed university in Bangalore under the &lsquo;A&rsquo; Grade category. It is very good for an MBA.</p>
                                                <p>The college provides two MBA programs:</p>
                                                <ul>
                                                    <li>Main - PES University</li>
                                                    <li>Collaboration - with Indiana University of Pennsylvania, USA (during the second year)</li>
                                                </ul>
                                                <p>The university organizes multiple internships, projects, and training opportunities in popular corporate companies. Candidates are provided very good placement opportunities for a successful career in the top corporate companies.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse40" className="collapsed">
                                                <b>40.</b> Is Jain University Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse40" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Yes, Jain University Bangalore is a very famous and reputed university for MBA courses. The college is a private Deemed University and is considered among the top B-Schools in Bangalore.</p>
                                                <p>The Placement Department of Jain University organizes multiple internships and training opportunities for students to gain professional exposure, practical understanding, and work experience. Multiple recruiters from the top private companies have recruited graduates for their advanced business programs, high-paying job roles, and business collaborations.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse41" className="collapsed">
                                                <b>41.</b> Is Presidency College Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse41" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Yes, Presidency College Bangalore is a very famous and reputed college for MBA courses. The college is a private college among the top B-Schools in Bangalore.</p>
                                                <p>Placements at Presidency College are very good. Graduates are selected by top national and global recruiters from the best private companies for various job roles and specializations. Candidates are awarded attractive salary packages and provide advanced skill-development training to excel in their career.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse42" className="collapsed">
                                                <b>42.</b> Is Dayananda Sagar College Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse42" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Dayananda Sagar College Bangalore is a very popular and reputed college for MBA courses.The college is a private college affiliated to Bangalore University and is situated in a lush campus in the heart of Bangalore.</p>
                                                <p>The academic curriculum consists of topics that provide plenty of practical and industrial exposure. The Placement Department of Dayananda Sagar College organizes multiple assessment, internships, and training opportunities for students to gain professional exposure experience. The Campus Recruitment Program is very good with the participation of top national and international recruiters.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse43" className="collapsed">
                                                <b>43.</b> How can I get admission for an MBA in Bangalore?</a>
                                            </p>
                                        </div>
                                        <div id="collapse43" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Candidates can get admission for an MBA in Bangalore through the below modes of admission:</p>
                                                <ul>
                                                    <li>Institutional Merit Admission - where the specific college or institution has its own entrance examination, selection, and interview process</li>
                                                    <li>Government Merit Admission - where the candidate has to qualify in state level entrance examinations like PGCET to be eligible for Merit admission</li>
                                                    <li>Direct Admission - through Management Quota where candidates can directly approach the college administration for available seats.</li>
                                                </ul>
                                                <p>Merit Quota admission can be done by candidates with very good academic performance and who have qualified in entrance examinations. The fee structure is quite less compared to Management Quota admission.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse44" className="collapsed">
                                                <b>44.</b> Why should I choose Bangalore for my MBA studies?</a>
                                            </p>
                                        </div>
                                        <div id="collapse44" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Bangalore is the IT capital of Karnataka and is well-known for its lush green spaces, academic development, and professional opportunities. There are more than 300 B-Schools and more than 500 reputed MNCs in Bangalore. It is also known as the Silicon Valley of India because most of the well-known IT company headquarters are located in Bangalore.</p>
                                                <p>The academic institutions in Bangalore are known for promoting excellence in education, sports, and extracurricular activities. The quality of education is very good compared to other cities, hence, students prefer to come to Bangalore to pursue their education. Management courses have a very good curriculum that provides a strong conceptual foundation together with industrial exposure. Fresh management graduates are in very high demand for recruitment in popular and reputed companies because of their academic interest, potential, and hard working nature. Thus, the top companies prefer graduates who have studied in management colleges in Bangalore.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse45" className="collapsed">
                                                <b>45.</b> Which is the best MBA program?</a>
                                            </p>
                                        </div>
                                        <div id="collapse45" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Colleges in Bangalore offer multiple MBA programs. In addition to traditional MBA courses offered by universities, colleges and institutions in Bangalore offer their own additional courses, certification programs, and industrial training to personalize and mould courses according to the current industrial demand.</p>
                                                <p>Students can choose their MBA course based on their academic interest, choice of certification course, and training program offered by the college or university.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse46" className="collapsed">
                                                <b>46.</b> Is M S Ramaiah College Bangalore good for MBA?</a>
                                            </p>
                                        </div>
                                        <div id="collapse46" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Yes, M S Ramaiah College Bangalore is a very popular and reputed college for MBA courses.The college is an autonomous college and offers three main MBA Programs affiliated to:</p>
                                                <ul>
                                                    <li>Bangalore University</li>
                                                    <li>Visvesvaraya Technical University</li>
                                                    <li>Ramaiah University</li>
                                                </ul>
                                                <p>The academic curriculum consists of topics that provide plenty of practical and industrial exposure and ensures total return of investment. The faculty is highly qualified. The college has very good infrastructure and the best facilities. The Placement Department creates multiple opportunities for students to gain professional exposure and experience. The Campus Recruitment Program is also very good and involves the participation of top national and international companies and recruiters.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse47" className="collapsed">
                                                <b>47.</b> What is the duration of an MBA program in Bangalore?</a>
                                            </p>
                                        </div>
                                        <div id="collapse47" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>The MBA Regular program in Bangalore is approved by AICTE and affiliated to either Bangalore University or Visvesvaraya Technical University. The duration of this MBA program is two years.</p>
                                                <p>The MBA Executive program is affiliated to private deemed Universities and have a duration of one to two years.</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <p className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse48" className="collapsed">
                                                <b>48.</b> Is it advisable to attend the Spot Admission process?</a>
                                            </p>
                                        </div>
                                        <div id="collapse48" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>No, it is not advisable to go for the Spot Admission process with colleges.&nbsp;</p>
                                                <p>Before beginning the MBA admission process, it is advisable for students to:</p>
                                                <ol>
                                                    <li>Get detailed information about the course or college</li>
                                                    <li>Check the available options provided for MBA courses, specializations, and colleges</li>
                                                    <li>Compare the courses, faculty, infrastructure, and placement records of different colleges and institutions</li>
                                                    <li>Check for additional certification and value-added courses</li>
                                                    <li>Check the ratings and reviews of the college</li>
                                                    <li>Check about placement statistics and records</li>
                                                </ol>
                                                <p>Some less known MBA colleges highlight certain courses to get the attention of students but do not deliver what they promise. They may also charge additional fees for the admission process. Thus, it is important to do prior research about the eligibility, admission procedure, courses, specializations offered, and placement records of different colleges before beginning the admission process.</p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            </div>
                        </div>
                        <div className="col-sm-4 single-courses_v">
                            <div className="sidebar-content single-courses-area" style={{width:'100%',padding:'20px 0 20px 0'}}>
                            <div className="single-curses-contert">
                                <div className="leave_a_comment">
                                    <div className="row">
                                        <div className="col-sm-12">
                                        <h3>Enquire for more</h3>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <form onSubmit={enquirySubmit} className="page-enquiry-form">
                                                    <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="input-label">name</label>
                                                            <input type="text" placeholder="Enter your name" id="name" name="name" required/>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label className="input-label">E-mail</label>
                                                            <input type="email" placeholder="Enter your E-mail" name="email" required/>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label className="input-label">Phone</label>
                                                            <input type="text" placeholder="Enter your Phone number"  value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" name="phone" required/>
                                                        </div>
                                                        <div className="col-xs-12">
                                                            <div className="form-group">
                                                            <label className="input-label">Country</label>
                                                              <select className="form-control SpecialSelect" name="country" required onChange={selectCountry}>
                                                                  <option value="">Select Country</option>
                                                                  {countries.map((country, index) => (
                                                                    <option key={index} value={country.label}>{country.label}</option>
                                                                  ))}
                                                              </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-12">
                                                            <div className="form-group">
                                                            <label className="input-label">State</label>
                                                              <select className="form-control SpecialSelect" name="state" value={stateValue?.value || ''} required onChange={selectRegion}>
                                                                  <option value="">Select State</option>
                                                                  {states && states.map((state, index) => (
                                                                    <option key={index} value={state.value}>{state.label}</option>
                                                                  ))}
                                                              </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="text-center">
                                                                <button type="submit">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="textBox" style={{background:'#54b145',marginBottom: '20px'}}>
                            <p><i className="fa fa-phone" aria-hidden="true"></i> Helpline: <a href="tel:+918095797575">+918095797575</a></p>
                            </div>
                            <div className="textBox" style={{background:'#fdb60e'}}>
                            <HashLink smooth to="/#scholarships-for-mba">
                                <p>SCHOLARSHIPS <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></p>
                            </HashLink>
                            </div>
                            <div className="single-courses_v">
                            <div className="single-courses-area" style={{width:'100%',padding:'0 0 20px 0'}}>
                                <div className="single-curses-contert">
                                    <div className="leave_a_comment" style={{marginTop:'0'}}>
                                        <div className="notifications">
                                        <div className="row">
                                            <div className="col-sm-12 section-header-box">
                                                <div className="section-header section-header-l">
                                                    <h2>NOTIFICATIONS</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blog_1">
                                            <div className="blog-area">
                                                <div className="blog_side-right">
                                                    <div className="recent-post-01">
                                                        <div className="row">
                                                        {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                        			                        return (
                                                            <div className="col-sm-12 recent-single" key={`n${notification.id}`}>
                                                                <div className="recent-content-item">
                                                                    <div className="img-box">
                                                                        <Link to={`/notifications/${notification.slug}`}>
                                                                            <img className="lozad" src={`/storage/notifications/${notification.image2}`} alt="Registration started for XAT - 2023" data-loaded="true"/>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="recent-text pull-right">
                                                                        <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                                                        <p>{blogDate(new Date(notification.updated_at))}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            );
                                                        })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="event-01">
                            <div className="events-list-03">
                                <div className="events-single-box examBox">
                                    <div className="row">
                                        <div className="col-sm-12 section-header-box" style={{marginBottom: '0'}}>
                                        <div className="section-header section-header-l">
                                            <h3>MBA PROGRAMS IN BANGALORE</h3>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="event-info">
                                        <ul className="exam-icon-list list-unstyled" style={{maxHeight: '600px'}}>
                                        {programs && programs.length > 0 && programs.map((program, index) => {
                        			        return (
                                                <li><Link to={`/programs/${program.slug}`}>{program.program}</Link></li>
                                            );
                                        })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </div>
    :
    <h1> Can't connect to server ! </h1>
    :
    <div className="row">
      <div className="col-md-12 text-center">
        <ThreeDots
        visible={true}
        height="50"
        width="50"
        color="#black"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{textAlign:'center', display:'block'}}
        wrapperclassName="loader"
        />
      </div>
    </div>
  )
};

// export default NotificationDetails;