import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const goToNextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
        <nav>
            <div className="pagination">
                {/* <a className="prev page-numbers" onClick={goToPrevPage} href='#'><i className="tm-acadevo-icon-arrow-left"></i> 
                  <span className="tm-hide tm-pagination-text tm-pagination-text-prev">Previous page</span>
                </a>
                {pageNumbers.map(pgNumber => (
                    <a key={pgNumber} onClick={() => setCurrentPage(pgNumber)} className={`page-numbers ${currentPage == pgNumber ? 'current' : ''} `} href="#">{pgNumber}</a>
                ))}
                <a className="next page-numbers" onClick={goToNextPage} href="#">
                  <span className="tm-hide tm-pagination-text tm-pagination-text-next">Next page</span> <i className="tm-acadevo-icon-arrow-right"></i>
                </a> */}


                <li className="page-item">
                    <a className="page-link" onClick={goToPrevPage} href='#'>
                        <span><i className="fa fa-angle-left" aria-hidden="true"></i></span>
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                        <a onClick={() => setCurrentPage(pgNumber)} className='page-link' href='#'>{pgNumber}</a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link" onClick={goToNextPage} href='#'>
                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                    </a>
                </li>
            </div>
        </nav>
    )
}

export default Pagination