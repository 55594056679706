import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import { ThreeDots } from 'react-loader-spinner'

export default class Colleges extends Component {
    
    constructor(){
        super();
        this.state={
          colleges:[],
          isLoading:false
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      truncate (str) {
		return str.length > 40 ? str.substring(0, 40) + "..." : str;
	  }

      async getUser(){
        this.setState({isLoading:true})
        let usersData=await axios.get('/colleges-page-data')
                    .then(res=>{
                        this.setState({
                            colleges:res.data.colleges
                        },()=>{
                        })
                        this.setState({isLoading:false})
                    })
                    .catch(err=>{
                        this.setState({isLoading:false})
                        console.log(err);
                    })
      }

    render(){
        const {colleges, isLoading}=this.state;
        return (
            !isLoading ? 
	        colleges!==null?
            <div>
                <Helmet>
                    <title>List of Top MBA Colleges in Bangalore | MBA Admission Open 2024-25 Bangalore</title>
                    <meta name="description" content="Discover the top MBA colleges in Bangalore for 2024-25. Explore admission opportunities in prestigious institutions for pursuing your MBA degree."/>
                    <meta name="keywords" content="mba colleges, mba colleges in Bangalore, mba admission in top colleges, top mba colleges & specialisations, best management colleges in bangalore, Best business schools in Bangalore, list of mba colleges in Bangalore, ranking of business schools in bangalore, bangalore top mba colleges,"/>
                    <link rel="stylesheet" href="/assets/css/colleges.css"></link>
                </Helmet>
                <section className="courses-02">
                    <div className="container">
                        <div className="row courses-r-margin-bottom">
                            <div className="col-sm-12">
                                <h3 style={{textTransform: 'uppercase',color: '#1b5167',paddingBottom: '15px'}}>MBA Admission in Top Management Colleges in Bangalore</h3>
                                <p>
                                Bangalore is considered as one of the modern educational hub for having the best colleges in every stream. The <a href="https://www.mbaadmissionsinbangalore.com/" title="Top Management Colleges in Bangalore"><b>Top Management Colleges in Bangalore</b></a> for <b>Direct MBA Admission</b> based on awards, recognition, infrastructure facility, placement, fees, quality of education and other ranking factors can be found here. 
                                </p>
                                <p style={{color:'#54b245',fontWeight:'600'}}>Get the details of best business schools in Bangalore, placement, salary package, specializations offered and much more.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 style={{textTransform: 'uppercase',color: '#1b5167',paddingBottom: '25px'}}>LIST OF TOP MBA COLLEGES IN BANGALORE</h2>
                            </div>
                        </div>
                        
                        {colleges && colleges.length > 0 && colleges.map((college, index) => {
                            const oneStar = (college.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const twoStar = (college.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const threeStar = (college.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const fourStar = (college.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const fiveSar = (college.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const images =  JSON.parse(college.image);
                            return (
                            <div className="col-xs-12 col-sm-6 col-md-4 single-courses-box" key={`k${college.id}`}>
                                <div className="single-courses">
                                    <figure>
                                        <Link to={`/colleges/${college.slug}`}>
                                            <div className="figure-img">
                                                {images && images.length > 0 && images.map((img, index) => {
                                                    if(index === 0){
                                                        return (
                                                            <img src={`/storage/${img.image}`} alt={`MBA in ${college.college}`} className="lozad img-responsive" data-loaded="true"/>                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </Link>
                                    </figure>
                                    <div className="courses-content-box">
                                        <div className="courses-content">
                                            <h3><Link to={`/colleges/${college.slug}`}>{this.truncate(college.college)}</Link></h3>
                                            <p className="data-comment">{college.location}</p>
                                            <ul className="clg-rating list-unstyled">
                                                <li className="rating">
                                                    <span><i className={oneStar}></i></span>
                                                    <span><i className={twoStar}></i></span>
                                                    <span><i className={threeStar}></i></span>
                                                    <span><i className={fourStar}></i></span>
                                                    <span><i className={fiveSar}></i></span>
                                                </li>
                                                <span> (<b>{college.count}/5</b> based on {college.total}  reviews) </span>
                                            </ul> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            );
                        })}
                        <hr/>
                        <div className="clearfix"></div>
                        <div className="single-courses_v">
                            <div className="row single-courses-area" style={{padding: '0'}}>
                                <div className="col-sm-12 single-curses-contert">
                                <h3 style={{textTransform: 'uppercase',color: '#1b5167',paddingBottom: '25px'}}>Frequently Asked Questions</h3>
                                <div className="curriculum-text-box text-left">
                                    <div className="curriculum-section">
                                        <div className="panel-group" id="accordion">
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                                    <b>1.</b> How can I get Direct admission for MBA in Bangalore without any entrance exam?</a>
                                                </p>
                                            </div>
                                            <div id="collapse1" className="panel-collapse collapse in">
                                                <div className="panel-body">
                                                    <p>Direct admission for MBA without any entrance exam depends on the college which you are selecting for MBA admission as some of the colleges in Bangalore provide MBA admission without common entrance exam; instead, these colleges have their own selection process as the compulsory criteria. Candidates can select the Management Quota for the Direct admission process.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse2" className="collapsed">
                                                    <b>2.</b> Which MBA colleges in Bangalore accept the CAT Entrance Exam score for the Selection Process?</a>
                                                </p>
                                            </div>
                                            <div id="collapse2" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>All the management colleges in Bangalore accept the CAT Entrance Exam score for the Selection Process since it is the most common and highly preferred entrance exam which the students usually attempt while applying for the MBA course.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse3" className="collapsed">
                                                    <b>3.</b> Which are the most preferred entrance exams for MBA admission in the top colleges in Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse3" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>The CAT, MAT, CMAT, and KMAT entrance exams are the most preferred entrance examinations by candidates applying for MBA since they are recognized and accepted by all the top colleges in Bangalore.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse4" className="collapsed">
                                                    <b>4.</b> Which MBA specializations have more demand in top management colleges Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse4" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>The demand for different MBA specializations is continuously changing based on the constantly changing industry needs and requirements. There are a few common and evergreen MBA specializations that will never decrease in demand such as Finance, Marketing, HR, and Operations.</p>
                                                    <p>Currently, there are many new MBA specializations that have increased the scope and applications of the MBA course. These include Business Analytics, Healthcare Management, Hospitality Management, Logistics and Supply Chain Management, and Pharma Management etc.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse5" className="collapsed">
                                                    <b>5.</b> Which mode of MBA is the best option for a candidate with work experience and without work experience?</a>
                                                </p>
                                            </div>
                                            <div id="collapse5" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>The mode of MBA that a candidate chooses depends on whether they are currently studying or working:</p>
                                                    <ul>
                                                        <li>A regular Full-time MBA is for candidates who have just passed their degree and have no work experience. It enables continuity of studies without any distractions and interruptions.</li>
                                                        <li>A Part-time MBA is for the candidates who are currently working. It can be studied through distance or online mode so that candidates can simultaneously work while pursuing their MBA degree. It enables candidates to successfully earn their degree while also trying for career growth and a salary hike in their company.</li>
                                                    </ul>
                                                    <p>Both the Full-time and Part-time MBA options are beneficial for candidates.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse6" className="collapsed">
                                                    <b>6.</b> Which MBA colleges in Bangalore provide placement opportunities abroad?</a>
                                                </p>
                                            </div>
                                            <div id="collapse6" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Most of the ‘A’ Grade B-Schools in Bangalore provide placement opportunities in the top MNCs in India. During campus recruitment, very few smart candidates get direct placement in companies that are located abroad.</p>
                                                    <p>Other candidates get placed in the MNCs which have multiple locations in India. Candidates can get additional opportunities to travel abroad through internal job postings and promotions after one year of work experience in India.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse7" className="collapsed">
                                                    <b>7.</b> Which MBA colleges in Bangalore accept the MAT Entrance Exam score for the Selection Process?</a>
                                                </p>
                                            </div>
                                            <div id="collapse7" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>All the management colleges in Bangalore accept the MAT Entrance Exam score for the Selection Process since it is the most common and highly preferred entrance exam by all the top B-Schools and Management colleges in India.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse8" className="collapsed">
                                                    <b>8.</b> Which university is the best for doing an MBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse8" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>The candidates who prefer Government jobs and want to join the Government Sector can get admission in Government universities like Bangalore University and Visvesvaraya Technological University in Karnataka.</p>
                                                    <p>The candidates who want additional certification, training, and exposure in the Private Sector can get admission in private Universities and deemed universities in Bangalore.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse9" className="collapsed">
                                                    <b>9.</b> Is it a good option to do an MBA in Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse9" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, it is the best option to choose an MBA in Bangalore as Bangalore is considered as the IT Hub of India. Bangalore is also popularly called the ‘Silicon Valley’ of India because most of the big and popular IT companies and MNCs have their headquarters in Bangalore. There are plenty of job opportunities in the field of Business Management that promise a very good salary. Graduates from management colleges in Bangalore are preferred by the companies in Bangalore because of the high-quality education system which ensures that candidates have very strong communication and professional skills.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse10" className="collapsed">
                                                    <b>10.</b> Can a candidate without work experience apply for a Part-time MBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse10" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Candidates with or without work experience can apply for a Part-time MBA. Some B-Schools accept candidates without work experience for their Part-time MBA courses, but most of the reputed B-Schools in Bangalore prefer candidates with 6 months to 1 year of work experience for their Part-time MBA courses.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse11" className="collapsed">
                                                    <b>11.</b> Will my campus placement be affected if I go through direct admission for MBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse11" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>No, direct admission for MBA courses is a method of admission and does not affect the academic department in any way. After MBA admission procedure, Merit and Management Quota candidates are treated the same with equal opportunities. The academic, placement, and recruitment processes are the same for the candidates and depends mainly on the student’s potential through direct admission and admission based on Merit.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse12" className="collapsed">
                                                    <b>12.</b> Does getting Merit admission in an MBA college have an added advantage during placements?</a>
                                                </p>
                                            </div>
                                            <div id="collapse12" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>No, Merit admission for MBA courses is a method of admission and does not influence placements in any way. After MBA admission, Merit and Management Quota candidates are treated the same with equal opportunities. During campus recruitment, recruiters from renowned companies review the capabilities, academic performance, and skills of the candidates and provides placement opportunities based on these criteria, the personal interview, and the candidate’s performance during the Selection Process.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse13" className="collapsed">
                                                    <b>13.</b> How do I decide which MBA specialization is good for me?</a>
                                                </p>
                                            </div>
                                            <div id="collapse13" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>There are many common and novel MBA specializations offered by the management colleges in Bangalore. The candidate should always decide their specialization based on:</p>
                                                    <ul>
                                                        <li>Passion and skills</li>
                                                        <li>Aptitude</li>
                                                        <li>Field or subject of interest</li>
                                                    </ul>
                                                    <p>The specialization that a candidate chooses will determine their career and occupation field for their entire life. It is important for the candidate to research, understand, and decide about their subject of interest before choosing and finalizing an MBA specialization.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse14" className="collapsed">
                                                    <b>14.</b> What criteria should I consider before deciding the best MBA college for admission?</a>
                                                </p>
                                            </div>
                                            <div id="collapse14" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Candidates should be very careful before they decide and select an MBA college for admission. A checklist should be followed before choosing any college for their studies and includes:</p>
                                                    <ul>
                                                        <li>Reputation of the institution</li>
                                                        <li>Budget for the MBA course</li>
                                                        <li>Scope  of the course and specialization</li>
                                                        <li>Accreditations and Recognitions of the college</li>
                                                        <li>The previous year’s Academic Record and Passing Percentage</li>
                                                        <li>The previous year’s Placement Statistics</li>
                                                        <li>Guidance from Educational Advisors</li>
                                                    </ul>
                                                    <p>Candidates can contact Galaxy Educational Services regarding the top MBA colleges in Bangalore and can get detailed information about the colleges based on research-oriented surveys and studies conducted by Academic Experts.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse15" className="collapsed">
                                                    <b>15.</b> What are the common entrance exams which are accepted by all the colleges in Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse15" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>The CAT, MAT, CMAT, KMAT, and PGCET entrance exams are the most common entrance examinations that are recognized and accepted by all the top management colleges in Bangalore.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse16" className="collapsed">
                                                    <b>16.</b> Is it better to do an MBA abroad or in India?</a>
                                                </p>
                                            </div>
                                            <div id="collapse16" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>The choice to do an MBA in India or abroad depends on the individual choice and preference of the candidate. The career opportunities are provided equally in both cases but there are a few differences.</p>
                                                    <p>The candidates who prefer government jobs and want to join the Government Sector can do an MBA in India. Candidates with a lower budget can get admission in the government management colleges in India. However, there is limited global exposure, career growth, and fewer opportunities when compared to opportunities available when MBA is done abroad.</p>
                                                    <p>The candidates who want jobs in foreign companies and MNCs in the Private Sector can do an MBA abroad. This also provides international work exposure, global engagement and interactions, exposure to multiple cultures, higher salary, additional job training, exponential career growth, and an opportunity to settle down in a foreign country.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse17" className="collapsed">
                                                    <b>17.</b> Is it mandatory to do an MBA course after my degree?</a>
                                                </p>
                                            </div>
                                            <div id="collapse17" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>No, it is not mandatory to do an MBA course after a degree. However, it helps to enhance a candidate’s career.
                                                        Getting a job soon after graduation provides many job opportunities but results                                      in limited career expansion and job growth.
                                                    </p>
                                                    <p>An MBA degree after graduation also ensures many job opportunities but it ALSO results in increased career growth, a higher position in the company hierarchy, increased possibility to get managerial promotions, and a higher salary. </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse18" className="collapsed">
                                                    <b>18.</b> Does an MBA degree add an advantage to speed up my career?</a>
                                                </p>
                                            </div>
                                            <div id="collapse18" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, an MBA degree after graduation is an additional advantage to speed up the candidate’s management career. An MBA degree is important because:
                                                    </p>
                                                    <ul>
                                                        <li>It promises world exposure and recognition</li>
                                                        <li>It showcases the candidate’s qualifications, performance, and skills</li>
                                                        <li>It ensures many management-level job opportunities</li>
                                                        <li>It results in increased career growth</li>
                                                        <li>It ensures a higher position in the company</li>
                                                        <li>It promises more promotions and recognitions</li>
                                                        <li>It ensures a higher salary</li>
                                                    </ul>
                                                    <p>Most of the popular and reputed companies prefer candidates with an MBA degree for their higher managerial positions.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse19" className="collapsed">
                                                    <b>19.</b> Is it good to do an MBA with additional certifications?</a>
                                                </p>
                                            </div>
                                            <div id="collapse19" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, an MBA with additional certifications is very good because it ensures career growth and better opportunities. Value-added courses and certifications add an additional benefit to the candidate as long as:
                                                    </p>
                                                    <ul>
                                                        <li>They are related to the field, industry, or specialization of the candidate</li>
                                                        <li>They help to enhance the aptitude of the candidate</li>
                                                        <li>The candidate has an interest and passion in the subject</li>
                                                        <li>The subject is industry-oriented</li>
                                                        <li>The subject aligns with the career objectives of the candidate</li>
                                                        <li>The subject is related to the current skills and advances in management</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse20" className="collapsed">
                                                    <b>20.</b> Does an MBA degree guarantee a successful career?</a>
                                                </p>
                                            </div>
                                            <div id="collapse20" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>An MBA degree adds more value to a candidate’s professional profile. It ensures international exposure, increased work experience, and global recognition. 
                                                        A successful career depends on other important factors like capability, work performance, skills, reasoning ability, time management skills, and decision-making abilities.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse21" className="collapsed">
                                                    <b>21.</b> Are there dual specializations for MBA courses in Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse21" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, almost all the universities in Bangalore provide dual specialization options for MBA courses including Bangalore University, Visvesvaraya Technological University, and other private or deemed universities. The main benefit of dual specialization MBA courses is that candidates can specialize in two subjects together during the course.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse22" className="collapsed">
                                                    <b>22.</b> Do all MBA colleges provide placement opportunities?</a>
                                                </p>
                                            </div>
                                            <div id="collapse22" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, nowadays all the reputed MBA colleges provide placement opportunities by inviting companies and recruiters to the college for campus recruitment. However, candidate selection and placement are purely dependent on the candidate’s academic performance, potential, capability, and quality.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse23" className="collapsed">
                                                    <b>23.</b> Do all MBA colleges have a separate Placement Cell?</a>
                                                </p>
                                            </div>
                                            <div id="collapse23" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, all reputed MBA colleges have a separate Placement Cell that focuses on placing their students in good and reputed companies. The top colleges in Bangalore have a more efficient placement strategy that ensures a high number of placements with the top management companies. However, candidate selection and placement by the top companies are purely dependent on the candidate’s potential and quality.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse24" className="collapsed">
                                                    <b>24.</b> Do all MBA colleges provide additional certification programs?</a>
                                                </p>
                                            </div>
                                            <div id="collapse24" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Not all MBA colleges provide additional certification programs. A few colleges provide MBA with additional certification courses and programs which are included in the curriculum and are related to the chosen specialization. These include some of the colleges under Bangalore University, Visvesvaraya Technological University, private universities, and deemed universities that are introducing additional certification programs based on industry needs and which are beneficial for the students to get more exposure.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse25" className="collapsed">
                                                    <b>25.</b> Can I do an MBA after completing a 3-year Diploma course?</a>
                                                </p>
                                            </div>
                                            <div id="collapse25" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>No, it is not possible to do an MBA after a Diploma course because the eligibility for MBA is a Bachelor’s degree with a minimum of 50% aggregate or equivalent percentage. Some foreign universities accept students with less than 50% aggregate but graduation is compulsory.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse26" className="collapsed">
                                                    <b>26.</b> Do all MBA colleges provide a foreign trip for the students?</a>
                                                </p>
                                            </div>
                                            <div id="collapse26" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>No, only a few MBA colleges provide a foreign trip for the students. This varies with the MBA college or University and the mode of study. Some colleges in Bangalore provide dual programs which include a few months in India and remaining in a foreign university which ensures international exposure. This is through internship opportunities, short-term courses, and exchange programs.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse27" className="collapsed">
                                                    <b>27.</b> What is the minimum fee structure of an MBA course in Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse27" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>The MBA course in Bangalore starts with a minimum of 1 Lakh per year up to 5 Lakhs per year fees. This fee structure varies with the quality and reputation of the college - lower grade colleges have less fee structure while reputed colleges have a higher fee structure.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse28" className="collapsed">
                                                    <b>28.</b> Is an integrated MBA program a good option?</a>
                                                </p>
                                            </div>
                                            <div id="collapse28" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Integrated MBA programmes are very rare in India. Since the syllabus of the course is quite complex, they are not recommended for candidates in Bangalore.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse29" className="collapsed">
                                                    <b>29.</b> Does getting an MBA from the same university under which I have completed my Bachelor’s degree add an additional advantage?</a>
                                                </p>
                                            </div>
                                            <div id="collapse29" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>No, there is no additional advantage if a candidate has completed both Master’s and Bachelor’s degree from the same university. Undergraduate and postgraduate courses are treated differently and recruiters look for the candidate’s potential and quality for placements; hence, there is no relation between getting both degrees from the same university.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse30" className="collapsed">
                                                    <b>30.</b> What is the minimum salary package offered by MBA colleges in Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse30" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>The minimum salary package offered by MBA colleges in Bangalore depends on various factors:
                                                    </p>
                                                    <ul>
                                                        <li>The reputation of the MBA college</li>
                                                        <li>The academic performance of the candidate</li>
                                                        <li>The potential, skill and quality of the candidate</li>
                                                        <li>Performance in the interview</li>
                                                    </ul>
                                                    <p>Premium MBA colleges offer a very high salary package because of the quality and standard of education. The minimum salary package that a candidate can expect is around 3.5 LPA to 4 LPA while the highest is around 25 LPA.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse31" className="collapsed">
                                                    <b>31.</b> Which MBA specialization is good for a BCom student?</a>
                                                </p>
                                            </div>
                                            <div id="collapse31" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>For BCom students, an MBA specializationis an additional career enhancer.Students from a Commerce background can proceed with an MBA with specializations in Finance. Most colleges in Bangalore offer dual specializations so students have the benefit of selecting one compulsory specialization as Finance and second specialization in any area that they prefer. This is an add-on if the students aim for a career in Finance.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse32" className="collapsed">
                                                    <b>32.</b> Is it possible to do an MBA in Bangalore without attending classes?</a>
                                                </p>
                                            </div>
                                            <div id="collapse32" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, it is possible to do an MBA in Bangalore without attending classes. Many colleges in Bangalore have Part-time MBA programs with:</p>
                                                    <ul>
                                                        <li>Classes in the evening</li>
                                                        <li>Classes during the weekend</li>
                                                        <li>Online programs with offline classes.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse33" className="collapsed">
                                                    <b>33.</b> What is the difference between MBA and PGDM?</a>
                                                </p>
                                            </div>
                                            <div id="collapse33" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>MBA and PGDM courses have some important differences:</p>
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                        <tr>
                                                            <td>MBA</td>
                                                            <td>PGDM</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>Master of Business Administration</td>
                                                            <td>Post Graduate Diploma in Management</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Follow the syllabus of the respective University</td>
                                                            <td>Follow their own syllabus</td>
                                                        </tr>
                                                        <tr>
                                                            <td>MBA programs are recognized by the University Grants Commission (UGC)</td>
                                                            <td>PGDM programs are recognized by the AICTE</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Course duration is 2 years</td>
                                                            <td>Course duration is 1 or 2 years</td>
                                                        </tr>
                                                        <tr>
                                                            <td>More affordable fee structure</td>
                                                            <td>Fee structure is costly</td>
                                                        </tr>
                                                        <tr>
                                                            <td>The syllabus is updated in a longer span of time</td>
                                                            <td>The syllabus is updated regularly according to changing industry needs</td>
                                                        </tr>
                                                        <tr>
                                                            <td>More conceptual and theoretical</td>
                                                            <td>More industry-oriented and practical</td>
                                                        </tr>
                                                        <tr>
                                                            <td>There is more emphasis on academics</td>
                                                            <td>There is more emphasis on career</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Prepare the candidates for government and conventional managerial jobs</td>
                                                            <td>Prepare the candidates to handle high profile industry-oriented management posts</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse34" className="collapsed">
                                                    <b>34.</b> Is it better to do an MBA after BBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse34" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, it is highly recommended that BBA graduates should complete MBA after the undergraduate course. This is because both the courses are under the Management sector. Dual specializations in the management course ensures that students have an enhanced future career.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse35" className="collapsed">
                                                    <b>35.</b> How can I get a Merit seat for MBA in Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse35" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Qualification in national and state-level examinations guarantee a Merit seat for MBA in Bangalore. Apart from these, most of the top MBA universities, deemed universities, and private universities in Bangalore conduct their own entrance examinations for MBA Merit admission. Steps to get admission:</p>
                                                    <ul>
                                                        <li>Students can log in to the university website and apply for the Merit Counselling process. Based on the student&rsquo;s academic record, they are provided Merit seats</li>
                                                        <li>The Karnataka government conducts the Merit-based exam PGCET exclusively for Karnataka students. Students can apply for admission and get a Merit seat for less budget</li>
                                                        <li>&nbsp;Some colleges provide Merit admission based on the results of CAT, MAT, and CMAT. For these seats, candidates can apply directly to the college and get Merit admission</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse36" className="collapsed">
                                                    <b>36.</b> When does the application date open for admission to Christ University Bangalore?</a>
                                                </p>
                                            </div>
                                            <div id="collapse36" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>For the academic year 2020, applications for admission to Christ University are closed. For the academic year 2021, the applications will be available in the month of November and December.&nbsp;</p>
                                                    <p>Students are recommended to keep checking the updates available on the Christ University official website regarding opening of the applications.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse37" className="collapsed">
                                                    <b>37.</b> Is Christ University Bangalore good for MBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse37" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, Christ University is one of the best and top colleges for MBA admission. The college provides detailed exposure through the MBA curriculum, unique specializations, skill-based programs, and placement opportunities. MBA in Christ University is in very high demand and students prefer Christ University as their first option for management education.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse38" className="collapsed">
                                                    <b>38.</b> Does Christ University Bangalore provide placements for MBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse38" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, Christ University provides very good placement opportunities for MBA. Special skill-based training is provided by the Placement Department of Christ University. Because of the college&rsquo;s very good reputation, many of the best companies in Bangalore visit the college for campus recruitment. Special training is given for weaker students so that they also have equal opportunities during placements. Special seminars, international workshops, and guest lectures are provided to enhance the knowledge and personality of the students. Foreign companies are also eager to recruit the graduates of Christ University.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse39" className="collapsed">
                                                    <b>39.</b> Is AIMS Business School Bangalore good for MBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse39" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>AIMS Business School is a very familiar and popular name among B-schools in Bangalore. Students are interested in admission in AIMS Business School because of the management curriculum and placement opportunities, which are very good options for those who are looking for business schools in Bangalore.&nbsp;</p>
                                                    <p>The only minor drawback is the infrastructure of the college, which is overshadowed by the quality of the academic curriculum and academic excellence of the students.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse40" className="collapsed">
                                                    <b>40.</b> Is Acharya College Bangalore good for MBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse40" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, Acharya College Bangalore is good for MBA especially for students who aim for high-quality education and placement in reputed and well-known national and international companies.</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <p className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse41" className="collapsed">
                                                    <b>41.</b> Is Presidency University Bangalore good for MBA?</a>
                                                </p>
                                            </div>
                                            <div id="collapse41" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Yes, Presidency University, a private university in Bangalore, is good for MBA. The university provides a good number of specializations and specialities that make it a popular option for students who want to pursue management education in Bangalore.</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        :
        <h1> Can't connect to server ! </h1>
        :
        <div className="row">
            <div className="col-md-12 text-center">
                <ThreeDots
                visible={true}
                height="50"
                width="50"
                color="#black"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{textAlign:'center', display:'block'}}
                wrapperclassName="loader"
                />
            </div>
      </div>
        );
    }
}

// export default Home;