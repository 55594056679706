import React from "react";
import {Link } from "react-router-dom";
import { useState, useEffect, Component, useRef } from 'react';
 
export default function PageNotFound() {
    useEffect(()=>{
        document.body.classList.remove('single-courses_v');
        document.body.classList.remove('courses');
        document.body.classList.remove('blog_1');
        document.body.classList.remove('contact');
    },[])
    return (
        <div className="error-main">
            <div className="section">
                <h1 className="error">404</h1>
                <div className="page">Ooops!!! The page you are looking for is not found</div>
                <Link className="back-home" to="/">Back to home</Link>
            </div>
        </div>
    );
};
 
// export default PageNotFound;