import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';

export default class About extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          reviews:[]
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/about-page-data')
                      .then(res=>{
                          this.setState({
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates
                          },()=>{
                            // console.log(this.state)
                          })
                      })
                      .catch(err=>{
                          console.log(err);
                      })
      }

    render(){
        const {notifications}=this.state;
        const {reviews}=this.state;
        return (
          <div>
            <Helmet>
                <title>Galaxy Educational Services Reviews</title>
                <meta name="description" content="Galaxy Educational Services (GES) is a leading government-recognized educational advisory centre in Bangalore, Karnataka; with 15 years of excellence in the field of educational and career counseling and guidance. The company was established in 2004 under the umbrella of Galaxy Educational Trust."/>
                <link rel="stylesheet" href="/assets/css/galaxy-rating.css"></link>
            </Helmet>
            <div className="post_1_area">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 post_left-side">
                            <div className="row">
                            <div className="col-sm-12">
                                <div className="post-img-box">
                                    <img src="/assets/images/review4.jpg" alt="Galaxy Educational Services Reviews" className="img-responsive"/>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="description-content">
                                    <div className="description-heading">
                                        <h3>Galaxy Educational Services</h3>
                                    </div>
                                    <div className="description-text">
                                        <div className="row">
                                        <div className="col-sm-12">
                                            <div className="description-text-right">
                                                <h3>Reviews</h3>
                                                <div className="rates-box">
                                                    <div className="row no-gutters">
                                                    <div className="col-md-3 col-xs-6">
                                                        <div className="rate-box google">
                                                            <img className="img-responsive" src="/assets/images/google-g.png"/>
                                                            <h4>Google</h4>
                                                            <ul className="list-unstyled">
                                                                <li className="rating">
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star-half-o"></i></span>
                                                                </li>
                                                            </ul>
                                                            <p>Rated <b>4.6</b>/5</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-xs-6">
                                                        <div className="rate-box fb">
                                                            <img className="img-responsive" src="/assets/images/facebook.png"/>
                                                            <h4>Facebook</h4>
                                                            <ul className="list-unstyled">
                                                                <li className="rating">
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star-half-o"></i></span>
                                                                </li>
                                                            </ul>
                                                            <p>Rated <b>4.7</b>/5</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-xs-6">
                                                        <div className="rate-box jd">
                                                            <img className="img-responsive" src="/assets/images/jd.png"/>
                                                            <h4>Justdial</h4>
                                                            <ul className="list-unstyled">
                                                                <li className="rating">
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star-half-o"></i></span>
                                                                </li>
                                                            </ul>
                                                            <p>Rated <b>4.5</b>/5</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-xs-6">
                                                        <div className="rate-box glassdoor">
                                                            <img className="img-responsive" src="/assets/images/glassdoor.png"/>
                                                            <h4>Glassdoor</h4>
                                                            <ul className="list-unstyled">
                                                                <li className="rating">
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                </li>
                                                            </ul>
                                                            <p>Rated <b>5.0</b>/5</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-xs-12">
                                                        <div className="main">
                                                            <h5>Overall Rating</h5>
                                                            <ul className="list-unstyled">
                                                                <li className="rating">
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star"></i></span>
                                                                <span><i className="fa fa-star-half-o"></i></span>
                                                                </li>
                                                            </ul>
                                                            <h4>4.9<span>/5</span></h4>
                                                            <p>Based on reviews from Google, Facebook, Justdial, Glassdoor, etc.</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <p><b>So you’ve completed your high school education from a school affiliated to a renowned
                                                    education board:</b>
                                                </p>
                                                <ul className="list-unstyled">
                                                    <li>Indian Certificate of Secondary Education (ICSE)</li>
                                                    <li>Secondary School Leaving Certificate (SSLC)</li>
                                                    <li>Central Board of Secondary Education (CBSE)</li>
                                                    <li>International Baccalaureate (IB)</li>
                                                    <li>International General Certificate of Secondary Education (IGCSE)</li>
                                                    <li>National Institute of Open Schooling (NIOS)</li>
                                                    <li>Pre-university (PUC)</li>
                                                </ul>
                                                <br/>
                                                <p><b>You must have selected a specific stream of your choice for further specialization:</b></p>
                                                <ul className="list-unstyled">
                                                    <li>Science</li>
                                                    <li>Commerce</li>
                                                    <li>Arts and Humanities</li>
                                                </ul>
                                                <br/>
                                                <h3>The question is…what next?</h3>
                                                <p>You may have searched for multiple courses and specializations online to find out the next plan
                                                    of action. Though the suggestions provided are many and very useful, they tend to cause a lot of
                                                    worry, anxiety, and confusion. In addition, a lot of your valuable time and effort is spent in
                                                    searching, assembling, and gathering this important information. Extensive research is
                                                    unavoidable before short-listing a college/institution; however, the process can be streamlined
                                                    and simplified.
                                                </p>
                                                <p>When personal efforts turn out to become draining, exhausting, and tiresome, it is useful to take
                                                    the support and assistance of third party companies and organizations to make easy, smart and
                                                    useful decisions. Galaxy Educational Services (GES) is a leading government-recognized
                                                    educational advisory centre in Bangalore, Karnataka; with 15 years of excellence in the field of
                                                    educational and career counseling and guidance. The company was established in 2004 under the
                                                    umbrella of Galaxy Educational Trust. It is registered and recognized by the government of
                                                    Karnataka and is ISO 9001:2008 certified. <b>Galaxy Education Services Bangalore reviews</b>
                                                    validate that the company has been approved and authorized to represent more than 100 reputed
                                                    colleges in the country. Galaxy Educational Services was awarded the ‘Best Educational
                                                    Counseling Centre in Karnataka’ by Prime Time Global Education Excellence Awards in 2016,
                                                    ‘Best Educational Advisory Centre in Karnataka’ by Karnataka Educational Awards in 2018, and
                                                    ‘Best Promising Educational Services in South India’ by International Excellence Awards in
                                                    2019.
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 comments" id="comments">
                                <div className="row">
                                    <h3>Discussion forum</h3>
                                    <div>
                                        <div id="disqus_thread"></div>
                                        {/* <script>
                                        (function() {
                                        var d = document, s = d.createElement('script');
                                        s.src = 'https://galaxyeduworld-com.disqus.com/embed.js';
                                        s.setAttribute('data-timestamp', +new Date());
                                        (d.head || d.body).appendChild(s);
                                        })();
                                        </script> */}
                                        {/* <noscript>Please enable JavaScript to view the comments.</a></noscript> */}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="sidebar-text-post">
                            <div className="row">
                                <div className="col-sm-12 blog-padding-none">
                                    <h3>Popular Tags</h3>
                                    <div className="populer-tags">
                                        <div className="tagcloud">
                                        <a href="#">Galaxy Review</a>
                                        <a href="#">Galaxy Educational Services Review</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

// export default Home;