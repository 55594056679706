import React from "react";
import {Link, useParams, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import countryFile from './Country';
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'
import checkNumber from './CheckMobileNumber';
import striptags from 'striptags';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

export default function CollegeDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [college, setCollege] = useState([])
	const [review, setPageReview] = useState([])
    const [collegerate, setCollegerate] = useState([])
    const [collegerates, setCollegerates] = useState([])
	const [notifications, setNotifications] = useState([])
    const [meta, setMeta] = useState('')
	const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
	const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
	const [mobile, setMobileValue] = useState([]);
	const [courses, setCourse] = useState([]);
	const [avgRate, setavgRate] = useState('')
	const [specs, setSpecs] = useState([]);
	const [faqs, setFaqs] = useState([]);
	const [samecolleges, setSamecolleges] = useState([]);
	const [slides, setSlideImages] = useState([]);
	const [postavgRate, setPostavgRate] = useState('')
	const [ratingCount, setratingCount] = useState('')
	const [schema, setSchema] = useState('')

	const [isOpen, setOpen] = useState(false);
    const [videoId, setVideoId] = useState('');

	// const youtubePopup = () => {
	// 	MagnificPopup.trigger('.element-with-popup', 'open');
	// 	MagnificPopup.trigger('.element-with-popup', 'close');
	// 	MagnificPopup.trigger('.element-with-popup', 'goto', 3);
	// }

	const location = useLocation();
	//destructuring pathname from location
	const { pathname } = location;
	//Javascript split method to get the name of the path in array
	const splitLocation = pathname.split("/");  

	function selectCountry (event) {
		setStateValue([])
		const state_arr = countryFile.s_a[event.key].split("|"); 
		const stateArray = [];
		stateArray.push({
		  value: '', 
		  label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
		  stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
		  });
		}
		setStates(stateArray)
		var Country = event.value;
		let check = checkNumber.checkNumber(Country,mobile);
		if(check === 2){
			setMobileValue('')
		}
	  }

	  function updateMobile(value){
		setMobileValue(value)
	  }
	  
	  function selectRegion (val) {
		if(val.value !== '' && val.value !== null){
		  setStateValue(val)
		} else {
		  setStateValue([])
		}
	  }

    useEffect(()=>{
        fetchProduct(params.id);
		const countryArray = [];
        // console.log(countryFile);
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
		YoutubPlayer();
		// document.getElementsByClassName('.popup-youtube, .popup-vimeo').magnificPopup({
		// 	//disableOn: 700,
		// 	type: 'iframe',
		// 	mainClass: 'mfp-fade',
		// 	removalDelay: 100,
		// 	preloader: true,
		// 	fixedContentPos: false
		//   });
    },[params.id])
    const fetchProduct = async () => {
		setIsLoading(true)
        await axios.get(`/college-page-data/${params.id}`).then(({data})=>{
			setCollege(data.college)
			setavgRate(data.avgRate)
			setSpecs(data.specs)
			setFaqs(data.faqs)
			setPostavgRate(data.postAvgRate)
			setratingCount(data.ratingCount)
			setCollegerates(data.collegerates)
			setSamecolleges(data.samecolleges)
			setSlideImages(JSON.parse(data.college.image))
			setVideoId(data.college.video)
          if(data.college.seo){
            setMeta(data.college.seo)
          } else {
            setMeta('<meta/>')
          }
		  setIsLoading(false)
		  setSchema({
			"@context": "https://schema.org/",
			"@type": "Product",
			"name": data.college.college,
			"aggregateRating": {
			  "@type": "AggregateRating",
			  "ratingValue": data.postAvgRate,
			  "reviewCount": data.ratingCount
			}
		  })
        }).catch(({response:{data}})=>{
			setIsLoading(false)
          Swal.fire({
            text:'Error!.',
            icon:"error"
          })
        })
      }

	  function YoutubPlayer(){
		document.addEventListener("DOMContentLoaded",
			function() {
				var div, n,
					v = document.getElementsByClassName("youtube-player");
				for (n = 0; n < v.length; n++) {
					div = document.createElement("div");
					div.setAttribute("data-id", v[n].dataset.id);
					div.innerHTML = labnolThumb(v[n].dataset.id);
					v[n].appendChild(div);
				}
			});
	  }

	  function labnolThumb(id) {
		var thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg">',
		play = '<div className="play"></div>';
		return thumb.replace("ID", id) + play;
		}
     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      function reviewDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = today.getMonth();
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ '-' +month+'-' +year;
      }

      function enquirySubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
			jQuery('.EnquiryMobile').val('');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

      function submitReview(event){
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/post-college-review', formData)
        .then(({data})=>{
            event.target.reset()
			jQuery('#addReview').modal('hide');
            fetchProduct()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }
  return (
	!isLoading ? 
	college!==null?
    	<div>
			 <ModalVideo
				channel="youtube"
				youtube={{ mute: 0, autoplay: 1, rel:0 }}
				isOpen={isOpen}
				videoId={videoId}
				onClose={() => setOpen(false)} 
			/>
            <Helmet>
				<titlte>{college.title}</titlte>
				{parse(meta)}
				<script type="application/ld+json">
					{JSON.stringify(schema)}
				</script>
				<link rel="stylesheet" href="/assets/css/college-details.css"></link>
            </Helmet>
			<div className="modal fade" id="addReview" role="dialog">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
							<h4 className="modal-title">Submit your Rating & Review for fgsd</h4>
						</div>
						<div className="modal-body">
							<div className="apply-now">
							<div className="row">
								<form className="rat" onSubmit={submitReview} id="reviewForm">
									
									<div className="col-xs-12">
										<p >Click to rate:</p>
										<span className="Please-Rate"></span>
										<div className="dstar-rating">
										<input type="radio" id="5-stars" name="star" value="5"/>
										<label htmlFor="5-stars" className="star"><i className='fa fa-star fa-fw'></i></label>
										<input type="radio" id="4-stars" name="star" value="4"/>
										<label htmlFor="4-stars" className="star"><i className='fa fa-star fa-fw'></i></label>
										<input type="radio" id="3-stars" name="star" value="3" />
										<label htmlFor="3-stars" className="star"><i className='fa fa-star fa-fw'></i></label>
										<input type="radio" id="2-stars" name="star" value="2" />
										<label htmlFor="2-stars" className="star"><i className='fa fa-star fa-fw'></i></label>
										<input type="radio" id="1-star" name="star" value="1" />
										<label htmlFor="1-star" className="star"><i className='fa fa-star fa-fw'></i></label>
										</div>
									</div>
									<div className="col-xs-12">
										<div className="form-group">
										<label className="active">Write your review:</label>
										<textarea rows="4" name="review" className="form-control" required></textarea>
										</div>
									</div>
									<div className="col-xs-12">
										<div className="form-group">
										<label>Name</label>
										<input className="form-control" name="name" placeholder="Enter your name" required=""
											type="text"/>
										</div>
									</div>
									<div className="col-xs-12">
										<div className="form-group">
										<label>Email</label>
										<input className="form-control" name="email" placeholder="Enter your Email" required=""
											type="email"/>
										<input type="hidden" name="college" value={college.id}/>
										</div>
									</div>
									{/* <div className="col-xs-12">
										<div className="form-group" id="recaptcha">
										<label>CAPTCHA</label>
										
										<span className="captchaError"></span>
										</div>
									</div> */}
									<div className="col-xs-12 register-btn-box">
										<button className="register-btn Reviewsubmit" type="submit">Submit</button>
									</div>
								</form>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="single-courses-area">
				<div className="container">
					<div className="row">
						<div className="col-sm-8 sidebar-left">
							<div className="single-curses-contert">
								{college.meta_h2 !== "" ? <h2 className="h2-main">{college.meta_h2}</h2> : <h2 className="h2-main">{college.college}</h2>}
								<div className="rating-stars h2-rating">
									<ul style={{marginTop: '10px',marginBottom: '0'}}>
										<li className="star rate-this" title="1 star" data-value="1" style={{margin: '0'}}>
										<i className={`${avgRate >= 1 ? "fa fa-star" : "fa fa-star-o"}`}></i>
										</li>
										<li className="star rate-this" title="2 stars" data-value="2" style={{margin: '0'}}>
										<i className={`${avgRate >= 2 ? "fa fa-star" : "fa fa-star-o"}`}></i>
										</li>
										<li className="star rate-this" title="3 stars" data-value="3" style={{margin: '0'}}>
										<i className={`${avgRate >= 3 ? "fa fa-star" : "fa fa-star-o"}`}></i>
										</li>
										<li className="star rate-this " title="4 stars" data-value="4" style={{margin: '0'}}>
										<i className={`${avgRate >= 4 ? "fa fa-star" : "fa fa-star-o"}`}></i>
										</li>
										<li className="star rate-this " title="5 stars" data-value="5" style={{margin: '0'}}>
										<i className={`${avgRate >= 5 ? "fa fa-star" : "fa fa-star-o"}`}></i>
										</li>
									</ul>
									<p><span>(</span>5<span>/5)</span></p>
								</div>
								<div className="">
								<Swiper modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} spaceBetween={1} simulateTouch={false} slidesPerView={1} onSlideChange={() => console.log('slide change')} onSwiper={(swiper) => console.log(swiper)} loop={true}  cssMode={true} mousewheel={false} className="college-slider" autoplay={{delay: 3000, disableOnInteraction: false}} navigation={true}>
									{slides && slides.length > 0 && slides.map((slide, index) => {
										return (
										<SwiperSlide>
											<img style={{marginBottom:'10px', width:"100%"}} src={`/storage/${slide.image}`}/>
										</SwiperSlide>
										);
									})}
    							</Swiper>
										
								
								</div>
								<div className="description-content">
									<div dangerouslySetInnerHTML={{ __html: college.overview }} />
									
									<hr/>
									<div className="career-opp">
										<h4>Career opportunities</h4>
										<div id="viewMore" className="row">
										{specs && specs.length > 0 && specs.map((spec, index) => {
                                            return (
												<div className="col-xs-4 col-md-4 col-lg-2" key={`car${index}`}>
													<img className="career-logo img-responsive" src={`/storage/${spec.similar.logo}`}/>
												</div>
											);
										})}
										</div>
									</div>
								</div>
								<hr/>
								{faqs && faqs.length > 0 &&
								<div className="single-courses_v">
									<div className="single-courses-area" style={{padding: '0'}}>
										<div className="single-curses-contert">
											<div className="description-content">
												<h4>{college.college} FAQs</h4>
											</div>
											<div className="curriculum-text-box text-left">
												<div className="curriculum-section">
													<div className="panel-group" id="accordion">
													{faqs && faqs.length > 0 && faqs.map((faq, index) => {
														return (
															<div className="panel panel-default" key={`faq${faq.id}`}>
																<div className="panel-heading">
																	<p className="panel-title">
																		<a data-toggle="collapse" data-parent="#accordion" href={`#clg-faq${faq.id}`} className="collapsed">
																		<b>{index+1}.</b> {striptags(faq.question)}</a>
																	</p>
																</div>
																<div id={`clg-faq${faq.id}`} className="panel-collapse collapse">
																	<div className="panel-body">
																		{parse(faq.answer)}
																	</div>
																</div>
															</div>
														);
													})}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								}
							</div>
						</div>
						<div className="col-sm-4 sidebar-right">
							<div className="rating-box sidebar-content">
								<div className="row">
									<div className="col-sm-12">
										<div className="head-text">
										<h4>{college.college}  Rating & Reviews</h4>
										</div>
										<div className="rating content">
										<div className="row rate-box" style={{marginBottom: '0'}}>
											<div className="col-sm-12">
												<div className="left-rate" style={{background: '#eee'}}>
													<div className="overlay">
													<h5 className="">Rating:</h5>
													<h3>{postavgRate}<span>/5</span></h3>
													<div className="">
														<div className="rating-stars text-center">
															<ul id="stars">
																<li className="star rate-this" title="1 star" data-value="1" style={{margin: '0'}}>
																<i className={`${avgRate >= 1 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
																</li>
																<li className="star rate-this" title="2 stars" data-value="2" style={{margin: '0'}}>
																<i className={`${avgRate >= 2 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
																</li>
																<li className="star rate-this" title="3 stars" data-value="3" style={{margin: '0'}}>
																<i className={`${avgRate >= 3 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
																</li>
																<li className="star rate-this " title="4 stars" data-value="4" style={{margin: '0'}}>
																<i className={`${avgRate >= 4 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
																</li>
																<li className="star rate-this " title="5 stars" data-value="5" style={{margin: '0'}}>
																<i className={`${avgRate >= 5 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
																</li>
															</ul>
														</div>
														<p> Based on {ratingCount} reviews</p>
													</div>
													<hr style={{border:'1px solid rgba(255, 255, 255, 0.3)'}}/>
													<button className="btn-review btn btn-success" data-toggle="modal" data-target="#addReview"><i className="fa fa-pencil-square" aria-hidden="true"></i> Write your review</button>
													</div>
												</div>
											</div>
											{collegerates && collegerates.length > 0 &&
											<div className="col-sm-12">
												<div className="recent-box text-left">
													<h5 className="last text-center">Reviews</h5>
													{collegerates && collegerates.length > 0 && collegerates.map((collegerate, index) => {
                                                  		return (
															<div className="recent">
																<h6>
																	<span>
																		<div className="rating-stars">
																			<ul id="">
																			<li className="star rate-this" title="1 star" data-value="1" style={{margin: '0'}}>
																				<i className={`${collegerate.rating >= 1 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																			</li>
																			<li className="star rate-this" title="2 stars" data-value="2" style={{margin: '0'}}>
																				<i className={`${collegerate.rating >= 2 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																			</li>
																			<li className="star rate-this" title="3 stars" data-value="3" style={{margin: '0'}}>
																				<i className={`${collegerate.rating >= 3 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																			</li>
																			<li className="star rate-this " title="4 stars" data-value="4" style={{margin: '0'}}>
																				<i className={`${collegerate.rating >= 4 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																			</li>
																			<li className="star rate-this " title="5 stars" data-value="5" style={{margin: '0'}}>
																				<i className={`${collegerate.rating >= 5 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																			</li>
																			</ul>
																		</div>
																	</span>
																	<span>|</span> {collegerate.name} <span>|</span> <span>{reviewDate(new Date(collegerate.updated_at))}</span>
																</h6>
																<p>{collegerate.review}</p>
															</div>
														);
													})}
												</div>
											</div>
											}
										</div>
										</div>
									</div>
								</div>
							</div>
							<div className="clearfix"></div>
							<div className="sidebar-content blog_1">
								<div className="single-courses_v">
									<div className="sidebar-content single-courses-area" style={{width:'100%',padding:'20px 0'}}>
										<div className="single-curses-contert">
										<div className="leave_a_comment">
											<div className="row">
												<div className="col-sm-12">
													<h3 className="text-center">Apply now</h3>
													<div className="row">
													<div className="col-sm-12">
														<form onSubmit={enquirySubmit} className="page-enquiry-form">
															<div className="form-group">
																<div className="row">
																<div className="col-sm-12">
																	<label className="input-label">name</label>
																	<input type="text" placeholder="Enter your name" id="name" name="name" required/>
																</div>
																<div className="col-sm-12">
																	<label className="input-label">E-mail</label>
																	<input type="email" placeholder="Enter your E-mail" name="email" required/>
																</div>
																<div className="col-sm-12">
																	<label className="input-label">Phone</label>
																	<input type="text" value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" placeholder="Enter your Phone number" name="phone" required/>
																</div>
																<div className="clearfix"></div>
																<div className="col-xs-12">
																	<label className="input-label">Country</label>
																	<Select classNamePrefix="CountrySelect"  options={countries} name="country" className="select-country"  placeholder="Select Country" required onChange={selectCountry} />
																</div>
																<div className="col-xs-12">
																	<label className="input-label">State</label>
																	<Select classNamePrefix="StateSelect" options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={selectRegion} />
																</div>
																<div className="clearfix"></div>
																<div className="col-sm-12">
																	<div className="text-center">
																		<button type="submit">Submit</button>
																	</div>
																</div>
																</div>
															</div>
														</form>
													</div>
													</div>
												</div>
											</div>
										</div>
										</div>
									</div>
								</div>
								<div className="clearfix"></div>
								<div className="row">
									<div className="col-sm-12">
										<div className="textBox" style={{background:'#54b145',marginBottom:'10px'}}>
										<p><i className="fa fa-phone" aria-hidden="true"></i> Helpline: <a href="tel:+918095797575">+918095797575</a></p>
										</div>
									</div>
								</div>
								<div className="row blog-area">
									<div className="blog_side-right">
										{college.video &&
										<div className="col-sm-12 recent-post-01">
											<h4>College video</h4>
											<div className="row no-gutters">
												<div className="col-sm-12 vid-single">
													<a className="popup-youtube" href='#!'  onClick={() => setOpen(true)}>
														<div className="yt-box">
															<div className="youtube-player" data-id={college.video}>
																<div data-id={college.video}>
																	<img src={`https://i.ytimg.com/vi/${college.video}/hqdefault.jpg`}/>
																	<div class="play"></div>
																</div>
															</div>
														</div>
													</a>
												</div>
											</div>
										</div>
										}
										{splitLocation[1] === "colleges"  && splitLocation[2] && splitLocation[2] ==='christ-university' &&
										<div className="col-sm-12 recent-post-01">
											<div className="campusBox">
												<h4>Christ University Campuses in India</h4>
												<div className="row no-gutters">
													<div className="col-sm-12 recent-single">
														<div className="recent-content-item">
														<div className="img-box">
															<Link target="_blank" to="/colleges/christ-deemed-to-be-university-pune-lavasa-campus">
																<img src="/storage/colleges/1497853173phpVvc8Zp.jpeg" alt="Christ University, Pune Lavasa Campus" className="lozad"/>
															</Link>
														</div>
														<div className="recent-text pull-right mt-20">
															<Link target="_blank" href="/colleges/christ-deemed-to-be-university-pune-lavasa-campus">Christ University, Pune Lavasa Campus</Link>
														</div>
														</div>
													</div>
													<div className="col-sm-12 recent-single">
														<div className="recent-content-item">
														<div className="img-box">
															<Link target="_blank" to="/colleges/christ-university-delhi-ncr-campus">
																<img src="/storage/colleges/1600947032phpQVlSsd.jpeg" alt="Christ University, Delhi NCR Campus" className="lozad"/>
															</Link>
														</div>
														<div className="recent-text pull-right mt-20">
															<Link target="_blank" to="/colleges/christ-university-delhi-ncr-campus">Christ University, Delhi NCR Campus</Link>
														</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										}
										{samecolleges && samecolleges.length &&
										<div className="col-sm-12 recent-post-01">
											<h4>Similar Colleges</h4>
											<div className="row no-gutters">
												{samecolleges && samecolleges.length && samecolleges.map((samecollege, index) => {
													var images =  JSON.parse(samecollege.image);
													return (
														<div className="col-sm-12 recent-single">
															<div className="recent-content-item">
																<div className="img-box">
																	<Link to={`/colleges/${samecollege.slug}`}>
																	{images && images.length > 0 && images.map((img, index) => {
                                                    					if(index === 0){
                                                        					return (
																				<img src={`/storage/${img.image}`} alt=""/>	
																			);
                                                    					}
                                                					})}																																																																																																																																																										
																	</Link>
																</div>
																<div className="recent-text pull-right mt-20">
																	<Link to={`/colleges/${samecollege.slug}`}>{samecollege.college}</Link>
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
										}
										<div className="col-sm-12">
										<a href="https://www.pgdmcollegesinbangalore.com" target="_blank">
											<div className="textBox">
												<p>DIRECT ADMISSION FOR PGDM IN BANGALORE <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></p>
											</div>
										</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
        	</div>
		</div>
	:
	<h1> Can't connect to server ! </h1>
	:
	<div className="row">
		<div className="col-md-12 text-center">
			<ThreeDots
			visible={true}
			height="50"
			width="50"
			color="#black"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{textAlign:'center', display:'block'}}
			wrapperclassName="loader"/>
		</div>
  </div>
  )
};

// export default NotificationDetails;